import { blogActions } from './actions';
import { blog } from './blog';


export const blogReducer = (state = blog, { payload, type }) => {

    switch (type) {
        case blogActions.FETCH_BLOG_FAILED:
            return {
                ...state,
                isPending: false,
                error: payload?.error?.data
            };

        case blogActions.FETCH_BLOG_SUCCESS:
            return {
                ...state,
                isPending: false,
                posts_home: payload.data.posts_home ? payload.data.posts_home : state.posts_home,
                posts: !payload.data.posts_home && !payload.data.post_selected ? payload.data : state.posts,
                post_selected: payload.data.post_selected ? payload.data.post_selected : state.post_selected,
            };

        case blogActions.FETCH_BLOG_PENDING:
            return {
                ...state,
                isPending: true
            }

        default:
            return state;
    }
}
