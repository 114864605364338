import React from "react";
import { useSelector } from "react-redux";
import { Container, Col, Row } from 'reactstrap';

import { career_canvas_questionnaire } from "../../../../core/constants";
import { Intro } from "../../leadershipCanvasReport";
import { getLeadershipCanvasQuestionByCode } from "../../../../core/leadership-canvas-report";

//reports
import { ChallengeApproachReport } from "../index"

//icons
import iconWorkingStyle from "../../../../assets/img/icon-working-style.svg"
import iconApproaching from "../../../../assets/img/icon-approaching-report.svg"

export const WorkingStyleSection = ({ sectionCode }) => {
    const { leadership_canvas_report } = useSelector(state => state)
    const item_AC = useSelector(state => getLeadershipCanvasQuestionByCode(state, career_canvas_questionnaire.APPROACHING_CHALLENGES.questions.approaching_challenges.code))

    return (
        <>
            <Intro title={'Working Style'} className={`intro-1 ` + sectionCode} image={iconWorkingStyle} sectionCode={sectionCode} text={`This section highlights the interpersonal components of how ${leadership_canvas_report?.user?.first_name} operates.`} />
            <Intro title={'Approaching Challenges'} className={`intro-2 ` + sectionCode} image={iconApproaching} sectionCode={sectionCode} text={`Every professional approaches challenges in their own unique way. Here is ${leadership_canvas_report?.user?.first_name}’s unique mix that have been informed and shaped by a mix of past experiences and actions that energize them. `} />
            { leadership_canvas_report?.leadership_discovery_done &&
                <>
                <div className="bg-section py-4 shadow intro-2 working-style">
                <Container>
                    <div className="align-items-center px-3 row">
                    <Col>
                        <ChallengeApproachReport challengeApproach={item_AC} firstName={leadership_canvas_report?.user?.first_name} />
                    </Col>
                    </div>
                </Container>
                </div>
                </>
            }
        </>
    )
}