import React, { useState } from 'react';
import { Col, Row, Collapse, Fade, Button, Table, List } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faCaretDown, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import avatar from '../../../assets/img/company.svg'; //icono prueba

export const ResumeCompany = (props) => {
    const { id, name, industry, certifications,
        vision, stage, number_of_employees, open_roles, hq, value1, value2, value3,
        leaders, assessment_beginner, assessment_intermediate,
        assessment_advanced, female_non_leadership, female_leadership,
        female_senior_leadership, bipoc_non_leadership, bipoc_leadership,
        bipoc_senior_leadership, female_bipoc_non_leadership, female_bipoc_leadership,
        female_bipoc_senior_leadership, created_at, updated_at
    } = props

    const updatedAt = 'Updated ' + moment(updated_at).format('MMMM YYYY')
    const [collapsed, setCollapsed] = useState(true);
    const toggleCollapsed = () => { setCollapsed(!collapsed) }



    const bleeker_discovery_acknowledgement = (
        <>
            <h5>Acknowledgement</h5>
            
        </>
    )

    const bleeker_discovery_assessment_beginner = (
        <>
            {/* <h5 className="text-black font-weight-bold">True Advance Assessment: Beginner</h5> */}
            <blockquote className="ml-md-5 font-italic" dangerouslySetInnerHTML={{ __html: assessment_beginner }}></blockquote>
        </>
    )

    const bleeker_discovery_belonging = (
        <>
            <h5>Belonging</h5>
            
        </>
    )

    const bleeker_discovery_assessment_intermediate = (
        <>
            {/* <h5 className="text-black font-weight-bold">True Advance Assessment: Intermediate</h5> */}
            <blockquote className="ml-md-5 font-italic" dangerouslySetInnerHTML={{ __html: assessment_intermediate }}></blockquote>
        </>
    )

    const bleeker_discovery_career_advancement = (
        <>
            <h5>Career Advancement</h5>
            
        </>
    )

    const bleeker_discovery_assessment_advanced = (
        <>
            {/* <h5 className="text-black font-weight-bold">True Advance Assessment: Advanced</h5> */}
            <blockquote className="ml-md-5 font-italic" dangerouslySetInnerHTML={{ __html: assessment_advanced }}></blockquote>
        </>
    )

    const bleeker_discovery_development = (
        <>
            <h5>Development</h5>
            
        </>
    )

    const company_representation_table = (
        <Table striped>
            <thead>
                <tr>
                    <th className="border-0"></th>
                    <th className="border-0 text-center"><h5 className="mb-0">Non-Leadership</h5></th>
                    <th className="border-0 text-center"><h5 className="mb-0">Leadership</h5></th>
                    <th className="border-0 text-center"><h5 className="mb-0">Senior Leadership</h5></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><h5 className="mb-0 pl-3">Female</h5></td>
                    <td className="text-center">{female_non_leadership ?? ' - '} %</td>
                    <td className="text-center">{female_leadership ?? ' - '} %</td>
                    <td className="text-center">{female_senior_leadership ?? ' - '} %</td>
                </tr>
                <tr>
                    <td><h5 className="mb-0 pl-3">BIPOC</h5></td>
                    <td className="text-center">{bipoc_non_leadership ?? ' - '} %</td>
                    <td className="text-center">{bipoc_leadership ?? ' - '} %</td>
                    <td className="text-center">{bipoc_senior_leadership ?? ' - '} %</td>
                </tr>
                <tr>
                    <td><h5 className="mb-0 pl-3">Female x BIPOC</h5></td>
                    <td className="text-center">{female_bipoc_non_leadership ?? ' - '} %</td>
                    <td className="text-center">{female_bipoc_leadership ?? ' - '} %</td>
                    <td className="text-center">{female_bipoc_senior_leadership ?? ' - '} %</td>
                </tr>
            </tbody>
        </Table>
    )

    const open_roles_list = (
        <List className="open-roles-list mb-0" type="unstyled">
            {open_roles.map((rol) => {
                return <li key={rol.id}>
                    <a href={rol.link} className="text-reset" target={rol.open_new_tab ? "_blank" : "_self"}>
                        {rol.name}
                    </a>
                </li>
            })}
        </List>
    )

    return (
    <>
        <div className="position-relative py-3">
            <Row className="mx-md-0 align-items-center">
                <Col md="6" xs="8">
                    <div className="mr-md-3 ml-md-2 d-flex align-items-md-center align-items-start">
                        <img src={avatar} width="30" height="auto" />
                        <h5 className="text-naranja text-uppercase ml-3 font-weight-bold mb-0"> {name} <small className="text-naranja"> ({updatedAt}) </small></h5>
                    </div>
                </Col>
                <Col md="6" xs="4">
                    <div className="text-right text-nowrap" style={{ cursor: 'pointer' }} onClick={toggleCollapsed}>
                        <FontAwesomeIcon className="mr-2" size="lg" icon={collapsed ? faChevronDown : faChevronUp} color={`${collapsed ? '#49b4be' : '#ff7b00'}`} />
                        <span>{collapsed ? 'VIEW MORE' : 'VIEW LESS'}</span>
                    </div>
                </Col>
            </Row>
            <Row className="mx-0" style={{ cursor: "pointer" }} onClick={toggleCollapsed}>
                <Col md="11" className="offset-md-1 pl-0">
                    <Row>
                        <Col md="6">
                            {industry[0].name &&
                                <>
                                <Row>
                                    <Col xs="4" md="4" className="pl-md-3"><h5 className="mb-1">{industry.length > 0 && 'Industry:'}</h5></Col>
                                    <Col xs="8" md="8"><p className="mb-1">{industry[0].name}</p></Col>
                                </Row>
                                </>
                            }
                            { hq &&
                                <>
                                <Row>
                                    <Col xs="4" md="4" className="pl-md-3"><h5 className="mb-1">{industry.length > 0 && 'HQ:'}</h5></Col>
                                    <Col xs="8" md="8"><p className="mb-1">{hq}</p></Col>
                                </Row>
                                </>
                            }
                        </Col>
                        <Col md="6">
                            {stage && <Row>
                                <Col xs="4" className="pl-md-3"><h5 className="mb-1">{stage && 'Stage:'}</h5></Col>
                                <Col><p className="mb-1"><b> {stage} </b></p></Col>
                            </Row>
                            }
                            {number_of_employees &&
                                <Row>
                                    <Col xs="4" md="4" className="pr-0 pl-3"><h5 className="mb-2"># of employees: </h5></Col>
                                    <Col xs="8" md="6"><p className="mb-2">{number_of_employees}</p></Col>
                                </Row>
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Collapse isOpen={!collapsed} className="fade">
            <Row className="mx-md-0 pt-3">
                <Col md="11" className="offset-md-1 pl-md-0">
                {vision &&
                    <Row>
                       <Col md="2" className="pr-0 pl-3"><h5 className="mb-2">{industry.length > 0 && 'Vision:'}</h5></Col>
                       <Col md="10"><p className="mb-2"><div dangerouslySetInnerHTML={{ __html: vision }}></div></p></Col>
                    </Row>
                }
                {(value1 || value2 || value3) &&
                    <Row>
                       <Col md="2" className="pr-0 pl-3"><h5 className="mb-2">Values: </h5></Col>
                       <Col md="10" className="d-flex">
                            {value1 && <p className="mr-3 mb-2"><span className='rounded-circle bg-celeste text-center p-1 px-2 mr-1'>{1}</span>{value1}</p> }
                            {value2 && <p className="mr-3 mb-2"><span className='rounded-circle bg-celeste text-center p-1 px-2 mr-1'>{2}</span>{value2}</p> }
                            {value3 && <p className="mr-3 mb-2"><span className='rounded-circle bg-celeste text-center p-1 px-2 mr-1'>{3}</span>{value3}</p> }
                       </Col>
                    </Row>
                }
                {certifications.length > 0 &&
                    <Row>
                       <Col md="2" className="pr-0 pl-3"><h5 className="mb-2">Certifications: </h5></Col>
                       <Col md="10">
                        <p className="mb-2">
                            {certifications.map((certification, index) => {
                                return <span key={certification.id}>{certification.name} {(index < (certifications.length - 1)) ? '-' : ''} </span>
                            })}
                        </p>
                       </Col>
                    </Row>
                }
                <hr className="border-top mt-sm-2 mb-sm-3 d-block w-100"/>

                { (female_non_leadership || female_leadership || female_senior_leadership || bipoc_non_leadership || bipoc_leadership || bipoc_senior_leadership || female_bipoc_non_leadership || female_bipoc_leadership || female_bipoc_senior_leadership) &&
                    <>
                    <div className="company-representation">
                        <h5 className="text-naranja"><b className="text-uppercase">Company Representation</b> <span>({updatedAt})</span></h5>
                        {company_representation_table}
                    </div>
                    <hr className="border-top mt-sm-2 mb-sm-3 d-block w-100"/>
                    </>
                }

                {open_roles?.length > 0 &&
                    <>
                    <div className="open-roles">
                        <h5 className="text-naranja text-uppercase font-weight-bold">Open Roles</h5>
                        {open_roles_list}
                    </div>
                    <hr className="border-top mt-sm-2 mb-sm-3 d-block w-100"/>
                    </>
                }

                <div className="bleeker-discovery"><h5 className="text-naranja"><b className="text-uppercase">True Advance Discovery</b> <span>({updatedAt})</span></h5>
                    {bleeker_discovery_acknowledgement}
                    {assessment_beginner && bleeker_discovery_assessment_beginner}
                    {bleeker_discovery_belonging}
                    {assessment_intermediate && bleeker_discovery_assessment_intermediate}
                    {bleeker_discovery_career_advancement}
                    {bleeker_discovery_development}
                    {assessment_advanced && bleeker_discovery_assessment_advanced}
                </div>

                </Col>
              </Row>
            </Collapse>

            </div>

            <hr className="border-top mt-0 mb-md-0 mb-sm-2 d-block w-100" />

        </>
    )
}