import React, { Component, useEffect, useState } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { DEFAULT_SESSION_ID, SYSTEM_ID_SENIORITIES, SYSTEM_ID_PROFESSIONAL_ROLES, SYSTEM_ID_CAREER_LEVELS, SYSTEM_ID_REASONS_JOINING, SYSTEM_ID_HEAR_ABOUT, SYSTEM_ID_COUNTRIES, SYSTEM_ID_STATES } from '../../../../core/constants';
import { registerActions } from '../../../../core/register/actions';
import { getSystemById, systemActions } from '../../../../core/system';
import { FormCollectiveRegister } from './form-collective-register';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Link } from 'react-router-dom';
import LoadingIndicator from '../../../components/loading-indicator';
import { passwordStrengthChecker } from '../../../../core/helper';

const emailRegex = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

const animationClass = {
    appearActive: 'animated slideInRight',
    enter: 'animated',
    enterActive: 'animated slideInRight',
    exit: 'animated',
    exitActive: 'animated slideOutLeft',
}

export const CollectiveRegister = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const refferingToken = searchParams.get('reffering')
    const signStripe = searchParams.get('sign-stripe')
    const { register, systemCheck } = useSelector(state => state)
    const validPaymentToken = systemCheck?.data?.data?.collective_payment_token === signStripe
    const professionalRole = useSelector(state => getSystemById(state, SYSTEM_ID_PROFESSIONAL_ROLES));
    const careerLevels = useSelector(state => getSystemById(state, SYSTEM_ID_CAREER_LEVELS));
    const reasonsJoining = useSelector(state => getSystemById(state, SYSTEM_ID_REASONS_JOINING));
    const hearAbout = useSelector(state => getSystemById(state, SYSTEM_ID_HEAR_ABOUT));
    const countries = useSelector(state => getSystemById(state, SYSTEM_ID_COUNTRIES));

    const [formErrors, setformErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        confirmEmail: '',
        alternativeEmail: '',
        confirmAlternativeEmail: '',
        password: '',
        confirmPassword: '',
        careerStartYear: '',
        professionalRole: '',
        currentCareerLevel: '',
        reasonJoiningCollective: '',
        BIPOC: '',
        hearAboutUs: '',
        country: '',
        state: '',
    });
    const [inputValues, setInputValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        confirmEmail: '',
        alternativeEmail: '',
        confirmAlternativeEmail: '',
        password: '',
        confirmPassword: '',
        careerStartYear: '',
        professionalRole: '',
        currentCareerLevel: '',
        reasonJoiningCollective: '',
        BIPOC: '',
        hearAboutUs: '',
        country: '',
        state: '',
        termsAndPrivacy: false
    })
    const states = useSelector(state => inputValues.country ? getSystemById(state, SYSTEM_ID_COUNTRIES + '/' + inputValues.country + '/' + SYSTEM_ID_STATES) : null);
    const strength = passwordStrengthChecker(inputValues.password)

    useEffect(() => {
        if (validPaymentToken) {
            dispatch(systemActions.loadSystem(SYSTEM_ID_PROFESSIONAL_ROLES))
            dispatch(systemActions.loadSystem(SYSTEM_ID_CAREER_LEVELS))
            dispatch(systemActions.loadSystem(SYSTEM_ID_REASONS_JOINING))
            dispatch(systemActions.loadSystem(SYSTEM_ID_HEAR_ABOUT))
            dispatch(systemActions.loadSystem(SYSTEM_ID_COUNTRIES))
        }

        return () => {
            dispatch(registerActions.resetError())
        }
    }, [])

    useEffect(() => {
        if (inputValues.country) {
            dispatch(systemActions.loadSystem(SYSTEM_ID_COUNTRIES + '/' + inputValues.country + '/' + SYSTEM_ID_STATES))
        }
    }, [inputValues.country])

    const handleInputChange = async ({ target }) => {
        let { name, value } = target;

        switch (name) {
            case "firstName":
                formErrors.firstName =
                    value.length < 1 ? "Minimum 1 characters required" : "";
                break;

            case "lastName":
                formErrors.lastName =
                    value.length < 1 ? "Minimum 1 characters required" : "";
                break;

            case "email":
                formErrors.email = emailRegex.test(value)
                    ? ""
                    : "Invalid email address";
                formErrors.confirmEmail = (inputValues.confirmEmail && inputValues.confirmEmail !== value)
                    ? "Emails do not match"
                    : ""
                break;

            case "confirmEmail":
                let matchEmail = inputValues.email === value ? '' : 'Emails do not match';
                formErrors.confirmEmail = emailRegex.test(value)
                    ? matchEmail
                    : "Invalid email address";
                break;

            case "alternativeEmail":
                if (value) {
                    formErrors.alternativeEmail = emailRegex.test(value)
                        ? ""
                        : "Invalid email address";
                    formErrors.confirmAlternativeEmail = (inputValues.confirmAlternativeEmail && inputValues.confirmAlternativeEmail !== value)
                        ? "Emails do not match"
                        : ""
                } else {
                    formErrors.alternativeEmail = ''
                    formErrors.confirmAlternativeEmail = ''
                }
                break;

            case "confirmAlternativeEmail":
                if (inputValues.alternativeEmail) {
                    let matchAlternativeEmail = inputValues.alternativeEmail === value ? '' : 'Emails do not match';
                    formErrors.confirmAlternativeEmail = emailRegex.test(value)
                        ? matchAlternativeEmail
                        : "Invalid email address";
                }
                break;

            case "password":
                let passwordStrength = passwordStrengthChecker(value) === 'Too weak!' ? 'Too weak!' : ''
                formErrors.password = value.length < 8 ? "Minimum 8 characters required" : passwordStrength;

                if (inputValues.confirmPassword) {
                    formErrors.confirmPassword = inputValues.confirmPassword === value ? '' : 'Passwords do not match';
                }
                break;
            case "confirmPassword":
                formErrors.confirmPassword = inputValues.password === value ? '' : 'Passwords do not match';
                break;

            case "careerStartYear":
                formErrors.careerStartYear =
                    !value ? "Required" : "";
                break;

            case "professionalRole":
                formErrors.professionalRole =
                    !value ? "Required" : "";
                break;

            case "currentCareerLevel":
                formErrors.currentCareerLevel =
                    !value ? "Required" : "";
                break;

            case "reasonJoiningCollective":
                formErrors.reasonJoiningCollective =
                    !value ? "Required" : "";
                break;

            case "BIPOC":
                formErrors.BIPOC =
                    !value ? "Required" : "";
                break;

            case "hearAboutUs":
                console.log(value)
                formErrors.hearAboutUs =
                    !value ? "Required" : "";
                break;

            case "country":
                formErrors.country =
                    !value ? "Required" : "";
                inputValues.state = ""
                break;

            case "state":
                formErrors.state =
                    !value ? "Required" : "";
                break;

            case "termsAndPrivacy":
                value = target.checked
                break;

            default:
                break;
        }

        setInputValues({
            ...inputValues,
            [name]: value
        });
    }

    const onSubmitRegister = async (e) => {
        e.preventDefault();
        if (formValid()) {
            let newRegister = {
                email: inputValues.email,
                password: inputValues.password,
                first_name: inputValues.firstName,
                last_name: inputValues.lastName,
                career_start_year: inputValues.careerStartYear,
                professional_role_id: inputValues.professionalRole,
                career_level_id: inputValues.currentCareerLevel,
                reason_joining_id: inputValues.reasonJoiningCollective,
                identify_bipoc: inputValues.BIPOC,
                hear_about_id: inputValues.hearAboutUs,
                country_id: inputValues.country,
                state: inputValues.state,
                has_state_id: states?.items?.size > 0 ? true : false,
                referal_token: refferingToken
            }
            dispatch(registerActions.createCollectiveUser({ newRegister, history }))
        }
    }

    const formValid = () => {
        let valid = true;
        let keysNotRequired = ['alternativeEmail', 'confirmAlternativeEmail']

        // validate form errors being empty
        Object.values(formErrors).forEach(val => {
            val.length > 0 && (valid = false);
        });

        // validate the form was filled out
        // Object.values(inputValues).forEach(val => {
        //     val === '' && (valid = false);
        // });

        // validate the form was filled out
        Object.keys(inputValues).forEach((key) => {
            if (!keysNotRequired.includes(key)) {
                inputValues[key] == '' && (valid = false);
            }
        })

        if (inputValues.alternativeEmail && !inputValues.confirmAlternativeEmail) valid = false

        return valid;
    };

    return (
        <div className="view overflow-hidden bg-white">
            <TransitionGroup className="content">
                <CSSTransition classNames={animationClass} timeout={{ enter: 1000, exit: 1000 }} key='register' appear>
                    <div className="register-page">
                        <Container>
                            <div className="px-2 px-md-4 my-md-4">
                                <div className="content-view">
                                    <Row>
                                        <Col lg={{ size: 10, offset: 1 }} className="px-xl-5">
                                            <h2 className="pl-3 pl-md-0 rubik">Register</h2>
                                            <div className="bg-celeste p-2 px-3 rounded mb-3">
                                                {validPaymentToken &&
                                                    <p className="text-left mb-0" style={{ color: '#16474e' }}>The Collective is True Advance’s platform for professionals in pursuit of meaningful careers. We center BIPOC talent but welcome all.
                                                        Over the last decade, our Collective has become one of the most eclectic, electric professional communities in the world.
                                                    </p>
                                                }
                                                {!validPaymentToken &&
                                                    <p className="text-left mb-0" style={{ color: '#16474e' }}>
                                                        True Advance assessments are a paid Collective member benefit. Please <a href={'https://buy.stripe.com/28oaFP91o7XV7ni14d'} className="link">click here</a> to become a paid Collective member. If you are experiencing any issues, <Link to="/contact-us"> contact us here</Link>.
                                                    </p>
                                                }
                                            </div>

                                            {validPaymentToken &&
                                                <React.Fragment>
                                                    {professionalRole && careerLevels && reasonsJoining && hearAbout && countries &&
                                                        <FormCollectiveRegister
                                                            inputValues={inputValues}
                                                            handleInputChange={handleInputChange}
                                                            statesIsPending={states?.isPending}
                                                            lists={
                                                                {
                                                                    professionalRole: professionalRole.items,
                                                                    careerLevels: careerLevels.items,
                                                                    reasonsJoining: reasonsJoining.items,
                                                                    hearAbout: hearAbout.items,
                                                                    countries: countries.items,
                                                                    states: states?.items
                                                                }
                                                            }
                                                            onSubmitRegister={onSubmitRegister}
                                                            formErrors={formErrors}
                                                            formValid={formValid}
                                                            strength={strength}
                                                        />
                                                    }
                                                    {
                                                        register.error.email &&
                                                        <center style={{ marginTop: "40px" }}>
                                                            {register.error.email[0]}
                                                        </center>
                                                    }

                                                    {
                                                        register.isPending && <LoadingIndicator className="text-center" style={{ "marginTop": "15px", "display": "block" }} />
                                                    }

                                                    <hr className="d-none d-md-block mt-4 mb-5" />
                                                    <Link to={'/login'} className="btn btn-link text-reset text-uppercase rounded-pill px-md-5 btn-block d-md-none">LOGIN</Link>
                                                </React.Fragment>
                                            }
                                        </Col>
                                    </Row>

                                </div>
                            </div>
                        </Container>
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </div>
    )
}