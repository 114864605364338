import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Container } from 'reactstrap';
import { profileActions } from '../../../core/profile/actions';
import { EditForm } from './EditForm';
import { convertCamelCase, passwordStrengthChecker } from '../../../core/helper';
import { getSystemById, systemActions } from '../../../core/system';
import { SYSTEM_ID_SENIORITIES, SYSTEM_ID_PROFESSIONAL_ROLES, SYSTEM_ID_CAREER_LEVELS, SYSTEM_ID_REASONS_JOINING, SYSTEM_ID_HEAR_ABOUT, SYSTEM_ID_COUNTRIES, SYSTEM_ID_STATES } from '../../../core/constants';

const animationClass = {
    appearActive: 'animated slideInRight',
    enter: 'animated',
    enterActive: 'animated slideInRight',
    exit: 'animated',
    exitActive: 'animated slideOutLeft',
}

const emailRegex = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

export const UserEditInfo = () => {
    const dispatch = useDispatch();
    const { user, profile, systemCheck } = useSelector(state => state);
    const isCollective = systemCheck?.data?.data?.login_version === "collective"
    const state = useSelector(state => state);
    const seniorities = getSystemById(state, SYSTEM_ID_SENIORITIES);
    const professionalRole = getSystemById(state, SYSTEM_ID_PROFESSIONAL_ROLES);
    const careerLevels = getSystemById(state, SYSTEM_ID_CAREER_LEVELS);
    const reasonsJoining = getSystemById(state, SYSTEM_ID_REASONS_JOINING);
    const hearAbout = getSystemById(state, SYSTEM_ID_HEAR_ABOUT);
    const countries = getSystemById(state, SYSTEM_ID_COUNTRIES);

    const [formErrors, setformErrors] = useState({
        firstName: '',
        lastName: '',
        email: profile.error?.data?.email || '',
        confirmEmail: '',
        currentPassword: '',
        password: '',
        confirmPassword: '',
        seniority: '',
        careerStartYear: '',
        professionalRole: '',
        currentCareerLevel: '',
        reasonJoiningCollective: '',
        BIPOC: '',
        hearAboutUs: '',
        country: '',
        state: '',
    });
    const [inputValues, setInputValues] = useState({
        firstName: profile?.data?.first_name || user?.first_name,
        lastName: profile?.data?.last_name || user?.last_name,
        email: '',
        confirmEmail: '',
        currentPassword: '',
        password: '',
        confirmPassword: '',
        seniority: profile?.data?.seniority?.id || user?.seniority?.id,
        careerStartYear: profile?.data?.career_start_year || user?.career_start_year,
        professionalRole: profile?.data?.professional_role?.id || user?.professional_role?.id,
        currentCareerLevel: profile?.data?.career_level?.id || user?.career_level?.id,
        reasonJoiningCollective: profile?.data?.reason_joining?.id || user?.reason_joining?.id,
        BIPOC: profile?.data?.identify_bipoc?.identify_bipoc || user?.identify_bipoc?.identify_bipoc,
        hearAboutUs: profile?.data?.hear_about?.id || user?.hear_about?.id,
        country: profile?.data?.country?.id || user?.country?.id,
        state: profile?.data?.state || user?.state,
    });
    const states = useSelector(state => inputValues.country ? getSystemById(state, SYSTEM_ID_COUNTRIES + '/' + inputValues.country + '/' + SYSTEM_ID_STATES) : null);
    const strength = passwordStrengthChecker(inputValues.password)

    useEffect(() => {
        if(isCollective) {
            dispatch(systemActions.loadSystem(SYSTEM_ID_PROFESSIONAL_ROLES))
            dispatch(systemActions.loadSystem(SYSTEM_ID_CAREER_LEVELS))
            dispatch(systemActions.loadSystem(SYSTEM_ID_REASONS_JOINING))
            dispatch(systemActions.loadSystem(SYSTEM_ID_HEAR_ABOUT))
            dispatch(systemActions.loadSystem(SYSTEM_ID_COUNTRIES))
        } else {
            dispatch(systemActions.loadSystem(SYSTEM_ID_SENIORITIES))
        }

        return () => {
            
        }
    }, [])

    useEffect(() => {
        if(inputValues.country) {
            dispatch(systemActions.loadSystem(SYSTEM_ID_COUNTRIES + '/' + inputValues.country + '/' + SYSTEM_ID_STATES))
        }
    }, [inputValues.country])

    useEffect(() => {
        if (profile.error && profile.error.data) {
            const errors = profile.error.data;
            let updatedErrors = { ...formErrors };

            Object.keys(errors).forEach(key => {
                const camelCaseKey = convertCamelCase(key);

                updatedErrors = {
                    ...updatedErrors,
                    [camelCaseKey]: errors[key][0]
                };
            });
    
            setformErrors(updatedErrors);
        }
    }, [profile.error])

    const handleInputChange = async ({ target }) => {
        const { name, value } = target;

        switch (name) {
            case "firstName":
                formErrors.firstName =
                    value.length < 1 ? "Minimum 1 characters required" : "";
                break;
            case "lastName":
                formErrors.lastName =
                    value.length < 1 ? "Minimum 1 characters required" : "";
                break;
            case "email":
                !value ? formErrors.email = "" :
                    formErrors.email = emailRegex.test(value)
                        ? ""
                        : "Invalid email address";
                    formErrors.confirmEmail = (inputValues.confirmEmail && inputValues.confirmEmail !== value)
                        ? "Emails do not match"
                        : ""
                break;
            case "currentPassword":
                formErrors.currentPassword = "";
                break;
            case "confirmEmail":
                let matchEmail = inputValues.email === value ? '' : 'Emails do not match';
                !value ? formErrors.confirmEmail = "" :
                formErrors.confirmEmail = emailRegex.test(value)
                    ? matchEmail
                    : "Invalid email address";
                break;
            case "password":
                formErrors.password =
                    value && value.length < 8 ? "Minimum 8 characters required" : "";
                formErrors.confirmPassword = (inputValues.confirmPassword && inputValues.confirmPassword !== value)
                    ? "Passwords do not match"
                    : ""
                break;
            case "confirmPassword":
                let matchPass = inputValues.password === value ? "" : 'Passwords do not match';
                !value ? formErrors.confirmPassword = "" :
                formErrors.confirmPassword = value.length < 8 ? "Minimum 8 characters required" : matchPass;
                break;
            case "careerStartYear":
                formErrors.careerStartYear =
                    !value ? "Required" : "";
                break;

            case "professionalRole":
                formErrors.professionalRole =
                    !value ? "Required" : "";
                break;

            case "currentCareerLevel":
                formErrors.currentCareerLevel =
                    !value ? "Required" : "";
                break;

            case "reasonJoiningCollective":
                formErrors.reasonJoiningCollective =
                    !value ? "Required" : "";
                break;

            case "BIPOC":
                formErrors.BIPOC =
                    !value ? "Required" : "";
                break;

            case "hearAboutUs":
                console.log(value)
                formErrors.hearAboutUs =
                    !value ? "Required" : "";
                break;

            case "country":
                formErrors.country =
                    !value ? "Required" : "";
                inputValues.state = ""
                break;

            case "state":
                formErrors.state =
                    !value ? "Required" : "";
                break;
        }

        setInputValues({
            ...inputValues,
            [name]: value
        });
    }

    const onSubmitUpdateInfo = async (e) => {
        e && e.preventDefault();
        if (formValid()) {
            let info_profile = {
                first_name: inputValues.firstName,
                last_name: inputValues.lastName,
                current_password: inputValues.currentPassword,
                password: inputValues.password,
                confirm_password: inputValues.confirmPassword
            }
            if(isCollective) {
                info_profile = {
                    ...info_profile,
                    career_start_year: inputValues.careerStartYear,
                    professional_role_id: inputValues.professionalRole,
                    career_level_id: inputValues.currentCareerLevel,
                    reason_joining_id: inputValues.reasonJoiningCollective,
                    identify_bipoc: inputValues.BIPOC,
                    hear_about_id: inputValues.hearAboutUs,
                    country_id: inputValues.country,
                    state: inputValues.state?.id || inputValues.state,
                    has_state_id: states?.items?.size > 0 ? true : false,
                }
            } else {
                info_profile = {
                    ...info_profile,
                    email: inputValues.email,
                    seniority_id: inputValues.seniority
                }
            }

            dispatch(profileActions.updateProfile(info_profile));
        }
    }

    useEffect(() => {
        if (profile.success)
            window.location.reload();
    }, [profile.success]);

    const formValid = () => {
        let valid = true;
        let inputValuesRequired = ['firstName', 'lastName']
        
        if(isCollective) {
            inputValuesRequired = inputValuesRequired.concat(['careerStartYear', 'professionalRole', 'currentCareerLevel', 'reasonJoiningCollective', 'BIPOC ', 'hear_about_id', 'hearAboutUs ', 'country ', 'state'])
        } else {
            inputValuesRequired = inputValuesRequired.concat(['seniority'])
        }

        // validate form errors being empty
        Object.values(formErrors).forEach(val => {
            val.length > 0 && (valid = false);
        });

        // validate the form was filled out
        Object.keys(inputValues).forEach(key => {console.log(key, inputValues[key])
            if (inputValuesRequired.includes(key)) {
                (inputValues[key] == '' || inputValues[key] == undefined) && (valid = false);
            }
        });

        (inputValues.password !== "" && inputValues.confirmPassword === "") && (valid = false);

        (inputValues.email !== "" && inputValues.confirmEmail === "") && (valid = false);

        if (inputValues.password !== "" && strength === 'Too weak!') valid = false

        return valid;
    };

    return (
        <div className='view overflow-hidden'>
            <TransitionGroup className="content">
                <CSSTransition classNames={animationClass} timeout={{ enter: 1000, exit: 1000 }} key='editInfo' appear>
                    <div className="register-page bg-gray mt-5">
                        <Container>
                            <div className="px-2 px-md-0 my-md-4">
                                <div className="content-view">
                                    { ((professionalRole && careerLevels && reasonsJoining && hearAbout && countries) || !isCollective) &&
                                        <EditForm
                                            inputValues={inputValues}
                                            handleInputChange={handleInputChange}
                                            onSubmitUpdateInfo={onSubmitUpdateInfo}
                                            formErrors={formErrors}
                                            formValid={formValid}
                                            strength={strength}
                                            isCollective={isCollective}
                                            lists={
                                                {
                                                    seniorities: seniorities?.items,
                                                    professionalRole: professionalRole?.items,
                                                    careerLevels: careerLevels?.items,
                                                    reasonsJoining: reasonsJoining?.items,
                                                    hearAbout: hearAbout?.items,
                                                    countries: countries?.items,
                                                    states: states?.items
                                                }
                                            }
                                            statesIsPending={states?.isPending}
                                        />
                                    }

                                    {/* {
                                    register.isPending && <LoadingIndicator className="text-center" style={{ "marginTop": "15px", "display": "block" }} />
                                }  */}
                                </div>
                            </div>
                        </Container>
                    </div>
                </CSSTransition>
            </TransitionGroup>

        </div>
    )
}
