import { Record, List } from 'immutable';

export const CompanyCard = new Record({
    id: null,
    isNew: true,
    isPending: false,
    name: null,
    industry: null,
    certifications: null,
    vision: null,
    stage: null,
    number_of_employees: null,
    open_roles: new List(),
    hq: null,
    value1: null,
    value2: null,
    value3: null,
    leaders: null,
    assessment_beginner: null,
    assessment_intermediate: null,
    assessment_advanced: null,
    female_non_leadership: null,
    female_leadership: null,
    female_senior_leadership: null,
    bipoc_non_leadership: null,
    bipoc_leadership: null,
    bipoc_senior_leadership: null,
    female_bipoc_non_leadership: null,
    female_bipoc_leadership: null,
    female_bipoc_senior_leadership: null,
    created_at: null,
    updated_at: null
});

export function createCompanyCard(data) {
    return new CompanyCard({
        id: data.id,
        name: data.name,
        industry: data.industry,
        certifications: data.certifications,
        vision: data.vision,
        stage: data.stage,
        number_of_employees: data.number_of_employees,
        open_roles: data.open_roles,
        hq: data.hq,
        value1: data.value1,
        value2: data.value2,
        value3: data.value3,
        leaders: data.leaders,
        assessment_beginner: data.assessment_beginner,
        assessment_intermediate: data.assessment_intermediate,
        assessment_advanced: data.assessment_advanced,
        female_non_leadership: data.female_non_leadership,
        female_leadership: data.female_leadership,
        female_senior_leadership: data.female_senior_leadership,
        bipoc_non_leadership: data.bipoc_non_leadership,
        bipoc_leadership: data.bipoc_leadership,
        bipoc_senior_leadership: data.bipoc_senior_leadership,
        female_bipoc_non_leadership: data.female_bipoc_non_leadership,
        female_bipoc_leadership: data.female_bipoc_leadership,
        female_bipoc_senior_leadership: data.female_bipoc_senior_leadership,
        created_at: data.created_at,
        updated_at: data.updated_at
    });
}
