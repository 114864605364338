import React, { useState } from "react";
import { useSelector } from "react-redux";

import { career_canvas_questionnaire } from "../../../../core/constants";
import { DynamicSection } from "./DynamicSection";
import { getLeadershipCanvasQuestionByCode, getStringFromAnswers } from "../../../../core/leadership-canvas"

//icons
import iconLeadership from "../../../../assets/img/icon-leadership.svg";
import iconLeadershipStyle from "../../../../assets/img/icon-leadershipstyle.svg";
import iconImpact from "../../../../assets/img/icon-impact.svg";
import iconInfluence from "../../../../assets/img/icon-influence.svg";

//reports
import { GenericModalReport, LeadershipApproachReport } from "../index"

//questionnaireView
import { QuestionnairePage } from "../../../pages/home/questionnaires/QuestionnairePage";

export const LeadershipSection = ({ goBack, sectionCode, history }) => {
    const { user, leadershipCanvas } = useSelector(state => state)
    const item_LA = useSelector(state => getLeadershipCanvasQuestionByCode(state, career_canvas_questionnaire.LEADERSHIP_APPROACH.questions.leadership_approach.code))
    const item_INS = useSelector(state => getLeadershipCanvasQuestionByCode(state, career_canvas_questionnaire.STRENGTH.questions.influence_strengths.code))
    const item_IMS = useSelector(state => getLeadershipCanvasQuestionByCode(state, career_canvas_questionnaire.STRENGTH.questions.impact_strengths.code))
    const [ isOpenModal, setIsOpenModal ] = useState(true)
    const [ showQuestionnaireReview, setShowQuestionnaireReview ] = useState(false)
    const toggle = () => {
        setIsOpenModal(!isOpenModal)
    }
    const open = () => {
        setIsOpenModal(true)
    }
    const intro = {
        code: sectionCode,
        text: `Leadership skills are developed through practice, and every leader has different strengths and styles. This section illustrates the right role and situation that you should be in to drive the highest impact.`,
        image: iconLeadership
    }
    const items = [
        {
            type: 'modal',
            title: 'Influence Strengths',
            show_btn_view: leadershipCanvas.leadership_discovery_done,
            show_btn_unlock: !leadershipCanvas.leadership_discovery_done,
            unlock_url: `/front/questionnaire/${leadershipCanvas?.questionnaire_id}`,
            bottom_text: getStringFromAnswers(item_INS?.answers),
            image: iconInfluence,
            modal: <GenericModalReport open={true} toggle={toggle} sectionCode={intro.code} title={'Influence Strengths'} items={item_INS?.answers} text={ <span> Click <a onClick={ (e)=> {e.preventDefault(); setShowQuestionnaireReview(true)}} href={`/front/questionnaire-review/${leadershipCanvas?.questionnaire_id}`}> here </a> to update your answers </span>} />,
            modalIsOpen: isOpenModal,
            openModal: open,
            questionnaireView: <QuestionnairePage id={leadershipCanvas?.questionnaire_id} animation={false} />,
            questionnaireId: leadershipCanvas?.questionnaire_id,
            showQuestionnaireReview,
        },
        {
            type: 'modal',
            title: 'Impact Strengths',
            show_btn_view: leadershipCanvas.leadership_discovery_done,
            show_btn_unlock: !leadershipCanvas.leadership_discovery_done,
            unlock_url: `/front/questionnaire/${leadershipCanvas?.questionnaire_id}`,
            bottom_text: getStringFromAnswers(item_IMS?.answers),
            image: iconImpact,
            modal: <GenericModalReport open={true} toggle={toggle} sectionCode={intro.code} title={'Impact Strengths'} items={item_IMS?.answers} text={ <span> Click <a onClick={ (e)=> {e.preventDefault(); setShowQuestionnaireReview(true)}} href={`/front/questionnaire-review/${leadershipCanvas?.questionnaire_id}`}> here </a> to update your answers </span>} />,
            modalIsOpen: isOpenModal,
            openModal: open,
            questionnaireView: <QuestionnairePage id={leadershipCanvas?.questionnaire_id} animation={false} />,
            questionnaireId: leadershipCanvas?.questionnaire_id,
            showQuestionnaireReview,
        },
        {
            title: 'Leadership Style',
            text: `There are numerous styles of leadership that aren’t inherently good or bad—they’re just different. They all have their benefits and drawbacks, as well as their appropriate uses in certain scenarios. 
                <br>
                This is a visualization of the intersection of your unique Leadership Styles to illustrate how you can make the most meaningful impact.
            `,
            show_btn_view: leadershipCanvas.leadership_discovery_done,
            show_btn_unlock: !leadershipCanvas.leadership_discovery_done,
            unlock_url: `/front/questionnaire/${leadershipCanvas?.questionnaire_id}`,
            // questionnaire_name: 'Thinking Talents',
            bottom_text: 'The methods through which a leader drives results',
            image: iconLeadershipStyle,
            report: <LeadershipApproachReport leadershipApproach={item_LA} toggleShowReview={() => {setShowQuestionnaireReview(true)}} />,
            questionnaireView: <QuestionnairePage id={leadershipCanvas?.questionnaire_id} animation={false} />,
            questionnaireId: leadershipCanvas?.questionnaire_id,
            showQuestionnaireReview,
        }
    ]

    return (
        <DynamicSection intro={intro} items={items} goBack={goBack} />
    )
}