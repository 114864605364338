import React, { useState, useEffect } from 'react';
import { Form, FormGroup, FormText, Input, Label, Collapse, CustomInput, Button, NavbarToggler, Navbar } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faChevronDown, faChevronUp, faSearch } from '@fortawesome/free-solid-svg-icons';
import filter from '../../../assets/img/icon-filtro.svg';
import IcomoonReact, { iconList } from "icomoon-react";
import iconSet from "../../../assets/icons/bleeker";
import { useSelector } from 'react-redux';

export const BlogFilters = ({ filters, setFilters, search, setSearch, refresh }) => {
    const { account } = useSelector(state => state.user)

    const [showCategory, setShowCategory] = useState(true)
    const [showContentType, setShowContentType] = useState(true)
    const [collapsed, setCollapsed] = useState(true);
    const [keepfilters, setKeepfilters] = useState(null);

    const toggleCategory = () => setShowCategory(!showCategory)
    const toggleContentType = () => setShowContentType(!showContentType)
    const toggleNavbar = () => setCollapsed(!collapsed);

    const handleInputChange = (target, name, type, slug = null) => {
        let id = parseInt(target.value) ? parseInt(target.value) : target.value
        let filter = filters

        if (target.checked) {
            filter.push({ id: id, name: name, type: type, slug: slug })
        } else {
            filter.splice(filter.map(function (item) {
                if (item.type === type) return item.id
            }).indexOf(id), 1)
        }
        setFilters(filter)
        refresh()
    }

    const clearAllFilters = () => {
        if(!collapsed){
            //mantiene por si no se da a Apply en mobile
            setKeepfilters(filters)
            setFilters([])
        } else {
            setFilters([])
            refresh()
        }
    }

    const filtersApply = () => {
        setKeepfilters(null)
        refresh()
    }

    useEffect(() => {
      if(keepfilters && collapsed) {
          setFilters(keepfilters)
          refresh()
    }
    }, [collapsed]);
    //
    
    const searchPosts = () => {
        clearAllFilters()
        refresh()
    }

    const handleKeyPress = (event) => {
        if (event.charCode == 13) {
            clearAllFilters()
            refresh()
        }
    }

    return (
        <>
            <Form onSubmit={e => { e.preventDefault() }}>
                <FormGroup>
                    <div className="search-member-input">
                        <Input className="py-4 rounded-lg" type="text" id="search_info" name="search_info" defaultValue='' onKeyPress={(e) => handleKeyPress(e)} onChange={e => setSearch(e.target.value)} placeholder="Search info"></Input>
                        <Button onClick={() => { searchPosts() }} className="rounded-circle p-1 border-0" ><FontAwesomeIcon className="text-white" size="lg" icon={faSearch} /></Button>
                    </div>
                    <FormText></FormText>
                </FormGroup>
            </Form>

            <Form className="pb-md-4">
                <Button onClick={toggleNavbar} color="link" className="mb-2 d-md-none p-0 text-black text-decoration-none">
                    <img src={filter} width="20" height="auto" className="mr-2" /> FILTER
                </Button>

                <Collapse isOpen={!collapsed} className="d-md-flex flex-md-column navfilter">

                    <div className="d-md-none encabezado text-center position-relative">
                        <div className="mb-3 pb-1 d-inline-flex align-items-end">
                            <h5 className="pl-4 mb-0 title-underline" style={{ fontSize: '1.25rem', lineHeight: 'normal' }}>Filter by</h5>
                        </div>
                        <Button onClick={toggleNavbar} color="link" className="p-0 close icon-close mt-1"></Button>
                    </div>

                    <Button size="lg" color="secondary" outline block className="rounded-pill px-3 text-petrol text-left mb-2" onClick={() => toggleCategory()} >
                        Category
                        <FontAwesomeIcon className="ml-1 float-right text-naranja" size="lg" icon={showCategory ? faChevronUp : faChevronDown} />
                    </Button>
                    <Collapse isOpen={showCategory}>
                        <FormGroup className="mb-2">
                            {account && account.categories && account.categories.map((category) => {
                                return <CustomInput checked={filters && filters.find(filter => filter.type === 'category' && filter.id === category.id)} onChange={({ target }) => { handleInputChange(target, category.name, 'category', category.slug); }} bsSize="sm" className="styled-checkbox pl-3 py-1" type="checkbox" label={category.name} id={'category' + '_' + category.id} value={category.id} />
                            })
                            }
                        </FormGroup>
                    </Collapse>

                    <Button size="lg" color="secondary" outline block className="rounded-pill px-3 text-petrol text-left mb-2" onClick={() => toggleContentType()} >
                        Content Type
                        <FontAwesomeIcon className="ml-1 float-right text-naranja" size="lg" icon={showContentType ? faChevronUp : faChevronDown} />
                    </Button>
                    <Collapse isOpen={showContentType}>
                        <FormGroup className="mb-2">
                            {account && account.content_types && account.content_types.map((content_type) => {
                                return <CustomInput
                                    checked={filters && filters.find(filter => filter.type === 'content_type' && filter.id === content_type.id)}
                                    onChange={({ target }) => { handleInputChange(target, content_type.name, 'content_type'); }}
                                    bsSize="sm"
                                    className="styled-checkbox pl-3 py-1"
                                    type="checkbox"
                                    label={content_type.icon && content_type.icon !== '' ? [ <img src={content_type.icon} className="mr-2" width="18" height="auto" style={{ maxHeight: '20px' }} /> ,content_type.name ] : [<IcomoonReact className="mr-2" color="inherit" iconSet={iconSet} size={17} icon={'text'} style={{ marginBottom: '1px' }} />, content_type.name]}
                                    id={'content_type' + '_' + content_type.id}
                                    value={content_type.id}
                                />
                            })
                            }
                        </FormGroup>
                    </Collapse>

                    <Button onClick={() => clearAllFilters()} color="primary" outline block className="mt-3 rounded-pill text-uppercase">Clear all filters</Button>
                    <Button onClick={() => {toggleNavbar(); filtersApply()}} color="primary" block className="d-md-none mt-3 rounded-pill text-uppercase">Apply</Button>
                </Collapse>
            </Form>
        </>
    )
}