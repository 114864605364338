import React, { Fragment, useEffect } from 'react';
import { Card, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { JobItemHome } from './JobItemHome';
import { useDispatch, useSelector } from 'react-redux';
import { jobsOutstandingActions } from '../../../../core/jobsOutstanding/actions';
import jobs_icon from '../../../../assets/img/jobs-icon.svg'

export const JobsHome = ({ oneColumn, crelate }) => {
    const dispatch = useDispatch();
    const { jobs_outstanding, user } = useSelector(state => state);

    useEffect(() => {
        if(user.id && !user.isPending) {
            dispatch(jobsOutstandingActions.loadJobsOutstanding());
        }
    }, [user]);

    return (
        <> 
            { crelate &&
                <Col xl='12' className="mt-3">
                    <Card className="p-4 pt-md-1 mb-4 mb-xl-0">
                        <div className={`${oneColumn ? "px-md-5" : ''}`}>
                            <div className="encabezado encabezado-icono">
                                <div className="mb-0 pt-2 pb-1 d-flex align-items-end">
                                    <img src={jobs_icon} alt="Jobs" width="40" height="auto" />
                                    <h6 className="mb-0 ml-3 title-underline">Jobs</h6>
                                </div>
                                <span className="border border-info d-none d-md-block"></span>
                            </div>
                            <p className='mt-3'>
                                Are you looking for your next opportunity? True Advance's Enterprise Talent Partners are hiring!
                            </p>
                            <span className="border border-muted d-block mt-2"></span>
                            <div className="text-center pt-2"><Link to="/jobs" className="btn btn-outline-primary text-uppercase rounded-pill d-inline-flex px-md-4 mx-auto mt-2">VIEW ALL JOBS</Link></div>
                        </div>
                    </Card>
                </Col>
            }
            { !crelate && jobs_outstanding?.data?.length > 0 &&
                <Col xl='12' className="mt-3">
                    <Card className="p-4 pt-md-1 mb-4 mb-xl-0">
                        <div className={`${oneColumn ? "px-md-5" : ''}`}>
                            <div className="encabezado encabezado-icono">
                                <div className="mb-0 pt-2 pb-1 d-flex align-items-end">
                                    <img src={jobs_icon} alt="Jobs" width="40" height="auto" />
                                    <h6 className="mb-0 ml-3 title-underline">Jobs</h6>
                                </div>
                                <span className="border border-info d-none d-md-block"></span>
                            </div>
                            <Row className={`${oneColumn ? "row-cols-md-2" : 'row-cols-1'} justify-content-center jobs-list`}>
                                {
                                    jobs_outstanding.data && jobs_outstanding.data.map((job, index) => {
                                        return <JobItemHome item={job} key={job.id} />
                                    })
                                }
                            </Row>
                            <span className="border border-muted d-block mt-2"></span>
                            <div className="text-center pt-2"><Link to="/jobs" className="btn btn-outline-primary text-uppercase rounded-pill d-inline-flex px-md-4 mx-auto mt-2">VIEW ALL JOBS</Link></div>
                        </div>
                    </Card>
                </Col>
            }
        </>
    )
}