import { eventsActions } from './actions';
import { events } from './events';


export const eventsReducer = (state = events, { payload, type }) => {

    switch (type) {
        case eventsActions.FETCH_EVENTS_FAILED:
            return {
                ...state,
                isPending: false,
                error: payload?.error?.data || payload
            };

        case eventsActions.FETCH_EVENTS_SUCCESS:
            return {
                ...state,
                isPending: false,
                events: state.data.events && state.data.events.length > 0 && state.data.current_page !== payload.data.current_page ? state.events.concat(payload.data.events) : payload.data.events,
                data: payload.data
            };

        case eventsActions.FETCH_EVENTS_PENDING:
            // return state.set('isPending', true);
            return {
                ...state,
                isPending: true
            }

        default:
            return state;
    }
}
