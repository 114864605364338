import React, { useState, useEffect } from 'react';
import { InputGroup, Input, Label, FormText } from 'reactstrap';

export const ExtraQuestion = (props) => {
    const { question, countQuestionsErrors, setCountQuestionsErrors } = props
    const [valueLength, setValueLength] = useState(0)
    const [valid, setValid] = useState(question.mandatory ? false : true)
    const [ changedValue, setChangedValue ] = useState(false);

    useEffect(() => {
        if (question.mandatory && valueLength === 0 && valid) {
            setValid(false);
            setCountQuestionsErrors(countQuestionsErrors + 1)
        }
        if (question.mandatory && valueLength !== 0 && !valid) {
            setValid(true);
            setCountQuestionsErrors(countQuestionsErrors - 1)
        }
    }, [question.answer])

    return (
        <div className="mt-3">
            <Label className={`${question.title ? 'mb-2' : ''}`}>{question.title ? <small><b>{question.title}</b></small> : ''}</Label>
            <InputGroup>
                <Input
                    rows={question.multiline ? '3' : ''}
                    type={question.multiline ? 'textarea' : 'label'}
                    id={question.id}
                    placeholder={question.mandatory ? '*' : ''}
                    onChange={({ target }) => { question.answer = target.value; setValueLength(target.value.length); setChangedValue(true) }}
                    defaultValue={question.answer ? question.answer : ''}
                    maxLength={question.max_chars ? question.max_chars : '999'}
                />
            </InputGroup>
            {
                question.max_chars && <p align="right" style={{ color: "#66b1bc" }}><small>{`${valueLength} / ${question.max_chars} characters`}</small></p>
            }
            {(question.mandatory && valueLength === 0 && changedValue) &&
                <div className="text-danger mt-1">
                    <FormText color="danger" className="mb-0">{question && question.validation_text}</FormText>
                    <FormText color="danger" className="mb-0">{!question || !question.validation_text && "Required"}</FormText>
                </div>
            }
        </div>
    )
}
