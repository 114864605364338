import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Modal, ModalHeader, ModalBody, FormGroup, CustomInput, Form, Button } from 'reactstrap';

import { companyCardsFiltersActions, getCompanyCardFilterById } from '../../../core/company-cards-filters';

export const ModalIndustry = ({ open, toggle, handleInputChange, filters, getCompanyByFilters, showApply = true }) => {
    const industries = useSelector(state => getCompanyCardFilterById(state, 'industries'))

    return (
        <Modal isOpen={open} toggle={toggle} className="modal-dialog-summary float-right">
            <ModalHeader toggle={toggle}></ModalHeader>
            <ModalBody>
                <Row>
                    <Col xs="10"><h5 className="mb-0 mt-3 font-weight-semibold px-4">Industries</h5></Col>
                    <Col md="10"><span className="border border-info d-block mt-2"></span>
                        <div className="bg-celeste py-2 px-4 rounded my-2">
                            <p className="text-reset mb-0">Select industries and next press Apply botton</p>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md="10">
                        <Form onSubmit={e => { e.preventDefault() }}>
                            <FormGroup >
                                <div className="columns-md-2">
                                    {/* importante que los customInput tengan el id */}
                                    {industries && industries.map((industry) => {
                                        return <CustomInput key={'Modal-Industry-' + industry.id} checked={filters && filters.find(filter => filter.type === 'industries' && filter.id === industry.id)} onChange={({ target }) => { handleInputChange(target, industry.name, 'industries', true); }} bsSize="sm" className="styled-checkbox pl-4 py-1" type="checkbox" label={industry.name} value={industry.id} id={'Modal-Industry-' + industry.id} />
                                    })
                                    }
                                </div>
                            </FormGroup>
                            {showApply && <div className="text-center">
                                <Button className="mx-auto text-uppercase rounded-pill px-5" onClick={() => { toggle(); getCompanyByFilters() }} outline color="primary">Apply</Button>
                            </div>
                            }
                        </Form>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
}