import React, { useState, useEffect } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Container, Col, Row } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';

import { LeadershipWelcome } from '../../components/leadershipCanvas/Welcome';
import { StartYourProfile } from '../../components/leadershipCanvas/StartYourProfile';
import { AboutYou } from '../../components/leadershipCanvas';
import session from '../../../core/session';
import { monthDiff } from '../../../core/helper';
import { TalentDiscoveryPassport } from '../../components/leadershipCanvas/TalentDiscoveryPassport';

const animationClass = {
    appearActive: 'animated slideInRight',
    enter: 'animated',
    enterActive: 'animated slideInRight',
    exit: 'animated',
    exitActive: 'animated slideOutLeft',
}

export const LeadershipCanvas = (props) => {
    const { history } = props
    const dispatch = useDispatch()
    const { user } = useSelector(state => state)
    const [activeStep, setActiveStep] = useState(null)

    useEffect(() => {
        //session.resetLastAccessToLeadershipCanvas() //TODO comentar una vez todo listo para que valide con el localstorage
    }, [])


    useEffect(() => {
        updateActiveStep() //TODO descomentar una vez todo listo para que valide con el localstorage
    }, [])

    //const activeStep = "profile";
    //.welcome
    //.startProfile
    //.profile

    const nextStep = () => {
        if (activeStep) {
            switch (activeStep) {
                case 'welcome': session.updateLastAccessToLeadershipCanvasWelcome(user.id);
                    setActiveStep('startProfile');
                    break;
                case 'startProfile': session.updateLastAccessToLeadershipCanvasProfile(user.id);
                    setActiveStep('profile');
                    break;
                default:
                    setActiveStep('profile');
                    break;
            }
        }
    }

    const updateActiveStep = () => {
        let today = new Date();
        let lastAccessWelcome = session.getLastAccessToLeadershipCanvasWelcome(user.id)
        let lastAccessProfile = session.getLastAccessToLeadershipCanvasProfile(user.id)
        let step = null

        if (!lastAccessWelcome) {
            step = 'welcome'
        } else if (monthDiff(today, new Date(lastAccessWelcome)) >= 2) {
            step = 'welcome'
        } else if (!lastAccessProfile) {
            step = 'startProfile'
        }

        setActiveStep(step)
    }

    return (
        <>
            {/* {!user.isPending && */}
                <TransitionGroup className={`${activeStep || 'profile'} view overflow-hidden`}>
                    <CSSTransition classNames={animationClass} timeout={{ enter: 1000, exit: 1000 }} appear >

                        <div className="w-100 content">
                            {activeStep && activeStep === 'welcome' &&
                                <Container>
                                    <LeadershipWelcome nextStep={nextStep} />
                                </Container>
                            }
                            {activeStep && activeStep === 'startProfile' &&
                                <Container>
                                    <StartYourProfile nextStep={nextStep} />
                                </Container>
                            }
                            {(!activeStep || (activeStep === 'profile')) &&
                                <>
                                    <div className="talentDiscoveryPassport">
                                        <TalentDiscoveryPassport history={history} topComponent={<AboutYou user={user} changeStep={setActiveStep} showBtnsReport={true} />} />
                                    </div>
                                </>
                            }
                        </div>

                    </CSSTransition>
                </TransitionGroup>
            {/* } */}
        </>
    )
}