import React, { Fragment, useState, useEffect } from 'react';
import { Card, Col, CustomInput, Row } from 'reactstrap';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Form, FormGroup, Container, FormText } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { jobApplyActions } from '../../../core/job-apply';
import { ExtraQuestion } from './ExtraQuestion';
import jobs_icon from '../../../assets/img/jobs-icon.svg';
import LoadingIndicator from '../loading-indicator/loading-indicator';
import { trackingActions } from '../../../core/tracking';

const emailRegex = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

export const RecommendModal = ({ open, toggle, job_id, questions, toggleThankModal, title, subtitle }) => {
    const [ disabled, setDisabled ] = useState(true);
    const history = useHistory();
    const dispatch = useDispatch();
    const { job_apply } = useSelector(state => state);
    const { user } = useSelector(state => state);
    const [ sent, setSent ] = useState(false);
    const countQuestionsMandatory = () => {
        let count = 0
        questions.forEach(question => {
            if(question.mandatory) count++
        });
        return count
    }
    const [ countQuestionsErrors, setCountQuestionsErrors ] = useState(countQuestionsMandatory());

    const formValid = () => {
        let valid = true;

        Object.values(formErrors).forEach(val => {
            val.length > 0 && (valid = false);
        });
        if (!inputValues.email || !inputValues.collectiveMember) valid = false
        return valid;
    };

    const [formErrors] = useState({
        name: '',
        lastName: '',
        email: '',
        linkedinProfile: '',
        collectiveMember: ''

    });

    const [inputValues, setInputValues] = useState({
        name: '',
        lastName: '',
        email: '',
        linkedinProfile: '',
        collectiveMember: 'Not sure'
    })

    const handleInputChange = async ({ target }) => {
        const { name, value } = target;
        switch (name) {
            case "name":
                formErrors.name =
                    value.length < 3 && value.length > 0 ? "Minimum 3 characters required" : "";
                break;
            case "lastName":
                formErrors.lastName =
                    value.length < 3 && value.length > 0 ? "Minimum 3 characters required" : "";
                break;
            case "email":
                formErrors.email = emailRegex.test(value)
                    ? ""
                    : "Invalid email address";
                break;
            case "linkedinProfile":
                formErrors.linkedinProfile = (value.includes("linkedin.com/in/") || value === "")
                    ? "" : "Invalid link";
                break;
            default:
                break;
        }
        setInputValues({
            ...inputValues,
            [name]: value
        });
    }

    useEffect(() => {
        setDisabled(!formValid());
    }, [inputValues])

    const onSubmitRecommend = async (e) => {
        e.preventDefault();
        if (formValid()) {
            let infoRecommend = {
                type: 'Recommend',
                first_name: inputValues.name,
                last_name: inputValues.lastName,
                email: inputValues.email,
                linkedin: inputValues.linkedinProfile,
                collective_member: inputValues.collectiveMember,
                job_id: job_id,
                user_id: user.id,
                questions: questions
            }
            dispatch(jobApplyActions.storeApply(infoRecommend));
            dispatch(trackingActions.saveTrackingEvent({category: 'Jobs',action: 'Recomendation received'}))
            setSent(true)
        }
    }

    const resetInputs = () => {
        setInputValues({
            name: '',
            lastName: '',
            email: '',
            linkedinProfile: '',
            collectiveMember: 'Not sure'
        })
    }

    useEffect(() => {
        resetInputs()
    }, [toggle])

    useEffect(() => {
        if (!job_apply.isPending && sent) {
            toggle()
            resetInputs()
            setSent(false)
            toggleThankModal()
        }
    }, [job_apply])

    return (
        <Modal isOpen={open} toggle={toggle} className="modal-dialog-summary float-right">
            <ModalHeader toggle={toggle}></ModalHeader>
            <ModalBody>
                <Row className="mb-2">
                    <Col xs="2"><img src={jobs_icon} alt="Jobs" width="24" height="auto" className="mx-3" /></Col>
                    <Col xs="8"><h5 className="mb-0 mt-3 font-weight-semibold">{title}</h5></Col>
                    <Col md="10"><span className="border border-info d-block mt-2"></span></Col>
                </Row>

                <Row className="justify-content-md-center">
                    <Col md="8">
                        <h5 className="text-reset my-3">Recommend</h5>
                        <div className="bg-celeste p-2 rounded my-2">
                            <p className="text-reset mb-0">{subtitle}</p>
                        </div>
                        <Form onSubmit={onSubmitRecommend} className="mt-3">
                            <FormGroup>
                                <Input
                                    placeholder="First Name"
                                    name="name"
                                    id="inputName"
                                    value={inputValues.name}
                                    onChange={handleInputChange}
                                />
                                <FormText color="danger">{formErrors.name}</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    placeholder="Last Name"
                                    name="lastName"
                                    id="inputlastName"
                                    value={inputValues.lastName}
                                    onChange={handleInputChange}
                                />
                                <FormText color="danger">{formErrors.lastName}</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    type="email"
                                    placeholder="* E-mail"
                                    name="email"
                                    id="inputEmail"
                                    value={inputValues.email}
                                    onChange={handleInputChange}
                                />
                                <FormText color="danger">{formErrors.email}</FormText>
                            </FormGroup>
                            <FormGroup className="mb-4">
                                <Input
                                    placeholder="Linkedin Profile"
                                    name="linkedinProfile"
                                    id="inputLinkedinProfile"
                                    value={inputValues.linkedinProfile}
                                    onChange={handleInputChange}
                                />
                               <FormText color="danger">{formErrors.linkedinProfile}</FormText>
                            </FormGroup>
                            <FormGroup className="px-4">
                                <Label for="collectiveMemberOption" className="text-primary">Is this person a Collective Member?</Label>
                                <div className="d-flex justify-content-between">
                                    <CustomInput
                                        type="radio"
                                        name="collectiveMember"
                                        className="pl-0 styled-checkbox d-flex align-items-center text-primary"
                                        id="collectiveMemberOption-1"
                                        onChange={handleInputChange}
                                        value="Yes"
                                        label="Yes"
                                        cssModule={{ 'custom-control-label': 'custom-control-label pl-30' }}
                                    />
                                    <CustomInput
                                        type="radio"
                                        name="collectiveMember"
                                        className="pl-0 styled-checkbox d-flex align-items-center text-primary"
                                        id="collectiveMemberOption-2"
                                        onChange={handleInputChange}
                                        value="No"
                                        label="No"
                                        cssModule={{ 'custom-control-label': 'custom-control-label pl-30' }}
                                    />
                                    <CustomInput
                                        type="radio"
                                        name="collectiveMember"
                                        className="pl-0 styled-checkbox d-flex align-items-center text-primary"
                                        id="collectiveMemberOption-3"
                                        onChange={handleInputChange}
                                        value="Not sure"
                                        defaultChecked
                                        label="Not sure"
                                        cssModule={{ 'custom-control-label': 'custom-control-label pl-30' }}
                                    />
                                </div>
                                <div>
                                    <FormGroup>
                                        {
                                            questions && questions.map((question, index) =>
                                                <ExtraQuestion key={question.id} question={question} countQuestionsErrors={countQuestionsErrors} setCountQuestionsErrors={setCountQuestionsErrors} />
                                            )
                                        }
                                    </FormGroup>
                                </div>
                            </FormGroup>
                            <div className="text-center">
                                <button disabled={disabled || countQuestionsErrors > 0} type="submit" style={{pointerEvents: (disabled || countQuestionsErrors > 0) ? 'none' : ''}} className="btn text-uppercase rounded-pill btn-outline-primary px-md-5 mb-3">Send</button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </ModalBody>
            {
                job_apply.isPending && sent &&
                <LoadingIndicator/>
            }
        </Modal>
    )
}