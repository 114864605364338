import { Record } from 'immutable';

export const User = new Record({
  id: null,
  avatar: null,
  isPending: false,
  email: null,
  token_id: null,
  referal_link: null,
  first_name: null,
  last_name: null,
  name: null,
  account: null,
  is_manager: false,
  error: 0,
  checked: false,
  seniority_id: null,
  seniority: null,
  mind_pattern: null,
  talents: null,
  documents: null,
  account: {},
  access_pdf_tt: false,
  access_pdf_mp: false,
  accept_terms: 1,
  last_popup_index: 0,
  must_change_pwd: false,
  pwd_politics_errors: null,
  profile_story: null,
  country_id: null,
  country: null,
  career_start_year: null,
  professional_role: null,
  career_level: null,
  reason_joining: null,
  identify_bipoc: null,
  hear_about: null,
  state: null,
});
