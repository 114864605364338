import { call, fork, takeEvery } from 'redux-saga/effects';
import { saveJobApply } from '../api';
import { jobApplyActions } from './actions';

export function* storeApply({ payload }) {
const { type, first_name, last_name, phone, email, linkedin, resume, collective_member, job_id, questions} = payload
    yield call(saveJobApply, type, first_name, last_name, phone, email, linkedin, resume, collective_member, job_id, questions);
}

//Watchers
export function* watchStoreApply() {
    yield takeEvery(jobApplyActions.STORE_APPLY, storeApply)
}

//Root
export const jobApplySagas = [
    fork(watchStoreApply),
]
