import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Collapse } from 'reactstrap';

import { convertToSlug } from "../../../core/helper";
import { leadershipCanvasActions } from "../../../core/leadership-canvas";
import { BoxCareerCanvasItem } from "./BoxCareerCanvasItem";
import { BtnsReport } from "./index";

//icons
import iconThinkingLearning from "../../../assets/img/icon-thinking-learning.svg"
import iconProexp from "../../../assets/img/icon-proexp.svg"
import iconWorkpre from "../../../assets/img/icon-workpre.svg"
import iconWorkingStyle from "../../../assets/img/icon-working-style.svg"
import iconLeadership from "../../../assets/img/icon-leadership.svg"

//sections
import { ThinkingAndLearningSection, ProfessionalExperienceSection, WorkPreferencesSection, WorkingStyleSection, LeadershipSection } from "./index";

export const TalentDiscoveryPassport = (props) => {
    const { topComponent, history } = props
    const dispatch = useDispatch();
    const { user } = useSelector(state => state)
    const [currentSection, setcurrentSection] = useState(history.location.state?.currentSection || '')
    const userCareerCanvas = useSelector(state => state.leadershipCanvas)
    const allAssessmentsCompleted = userCareerCanvas?.thinking_talents_done && userCareerCanvas?.mind_pattern_done && userCareerCanvas?.leadership_discovery_done

    const togglecurrentSection = (name) => {
        currentSection === convertToSlug(name) ? resetcurrentSection() : setcurrentSection(convertToSlug(name));
    }
    const resetcurrentSection = () => setcurrentSection('')   

    useEffect(() => {
        dispatch(leadershipCanvasActions.loadLeadershipCanvas())
    }, [])
    
    useEffect(() => {
        let newState = { ...history?.location.state, currentIndexReport: null, currentSection: currentSection }

        if(history?.location.state?.currentSection !== newState.currentSection) {
            history.replace('/career-canvas', newState)
        }
    }, [currentSection])

    return (
        <>
            <div className="p-0 bg-white">

                <Collapse isOpen={!currentSection ? true : false} timeout={0} >
                    {topComponent}
                </Collapse>

                <div className={`${!topComponent && 'pt-4'} mx-n3 mx-md-auto TalentDiscoveryPassport`}>

                    <Container>
                        {currentSection && allAssessmentsCompleted &&
                            <BtnsReport user={user} allAssessmentsCompleted={allAssessmentsCompleted} className="mx-0 pt-3" />
                        }

                        <Collapse isOpen={!currentSection ? true : false}>
                            <div className="pt-5">
                                <h3 className="text-uppercase text-muted font-weight-bold rubik fs-22 mb-3">Your Canvas Elements</h3>
                                <p className="text-muted mb-0">
                                    Your Career Canvas is a reflection of how you think and works best. Each section has different components to better understand all you have to offer.
                                    <br />
                                    Once you have completed each section, click the “share” button above to get the link to access your full Career Report to send to hiring managers, collaborators or even your current manager!
                                </p>
                            </div>
                        </Collapse>

                        <div className={`${!topComponent && 'mb-4'} talent-passport-content`}>
                            <div className="assessments-passport-content pb-5 pt-3 d-flex justify-content-between flex-wrap mx-n2">
                                <BoxCareerCanvasItem active={currentSection === convertToSlug('THINKING & LEARNING')} title={'Thinking & Learning'} sectionCode={convertToSlug('Thinking & Learning')} show_btn_view={true} open={() => togglecurrentSection('THINKING & LEARNING')} bottom_text={'Thinking Talents, Mind Patterns and Mindset'} image={iconThinkingLearning} collapsed={currentSection ? true : false} />
                                <BoxCareerCanvasItem active={currentSection === convertToSlug('PROFESSIONAL EXPERIENCE')} title={'Professional Experience'} sectionCode={convertToSlug('Professional Experience')} show_btn_view={true} open={() => togglecurrentSection('PROFESSIONAL EXPERIENCE')} bottom_text={'Capabilities, Industries, Products, and Regions'} image={iconProexp} collapsed={currentSection ? true : false} />
                                <BoxCareerCanvasItem active={currentSection === convertToSlug('WORK PREFERENCES')} title={'Work Preferences'} sectionCode={convertToSlug('Work Preferences')} show_btn_view={true} open={() => togglecurrentSection('WORK PREFERENCES')} bottom_text={'Work Environment and Career Drivers'} image={iconWorkpre} collapsed={currentSection ? true : false} />
                                <BoxCareerCanvasItem active={currentSection === convertToSlug('WORKING STYLE')} title={'Working Style'} sectionCode={convertToSlug('Working Style')} show_btn_view={true} open={() => togglecurrentSection('WORKING STYLE')} bottom_text={'Aproaching Challenges, Collaborating, and more'} image={iconWorkingStyle} collapsed={currentSection ? true : false} />
                                <BoxCareerCanvasItem active={currentSection === convertToSlug('LEADERSHIP')} title={'Leadership'} sectionCode={convertToSlug('Leadership')} show_btn_view={true} open={() => togglecurrentSection('LEADERSHIP')} bottom_text={'Capabilities, Approach, and Management Style'} image={iconLeadership} collapsed={currentSection ? true : false} />
                            </div>
                        </div>
                    </Container>
                </div>
            </div>

            {
                <Collapse isOpen={currentSection ? true : false} className="bg-white">
                    <div className="leadership-reports">
                        {currentSection === convertToSlug('THINKING & LEARNING') &&
                            <ThinkingAndLearningSection goBack={resetcurrentSection} sectionCode={convertToSlug('Thinking & Learning')} history={history} />
                        }
                        {currentSection === convertToSlug('PROFESSIONAL EXPERIENCE') &&
                            <ProfessionalExperienceSection goBack={resetcurrentSection} sectionCode={convertToSlug('Professional Experience')} history={history} />
                        }
                        {currentSection === convertToSlug('WORK PREFERENCES') &&
                            <WorkPreferencesSection goBack={resetcurrentSection} sectionCode={convertToSlug('Work Preferences')} history={history} />
                        }
                        {currentSection === convertToSlug('WORKING STYLE') &&
                            <WorkingStyleSection goBack={resetcurrentSection} sectionCode={convertToSlug('Working Style')} history={history} />
                        }
                        {currentSection === convertToSlug('LEADERSHIP') &&
                            <LeadershipSection goBack={resetcurrentSection} sectionCode={convertToSlug('Leadership')} history={history} />
                        }
                    </div>
                </Collapse>
            }
        </>
    )
}