import React, { useState, useEffect } from "react";
import { Container, Col, Row, Form, Fade } from 'reactstrap';
import { faPencilAlt, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";

import { BtnsReport } from "./index"

export const AboutYou = ({ user, isCurrentUser = true, changeStep = null, showBtnsReport = false }) => {
    let history = useHistory();
    const userCareerCanvas = useSelector( state => state.leadershipCanvas)
    const allAssessmentsCompleted = userCareerCanvas?.thinking_talents_done && userCareerCanvas?.mind_pattern_done && userCareerCanvas?.leadership_discovery_done

    return (
        <>
            <div className="bg-rosa shadow">
                <Container>
                    <div className={`py-md-${showBtnsReport ? '3' : '5'} mx-n3 mx-md-auto`}>
                        <Form>
                            <Row className="ml-0 align-items-center">
                                {showBtnsReport && allAssessmentsCompleted &&
                                    <BtnsReport user={user} allAssessmentsCompleted={allAssessmentsCompleted} />
                                }
                                {isCurrentUser && <Col xs="12" className="d-flex align-items-center mb-4">
                                    <a href="" onClick={(e) => { e.preventDefault(); history.goBack() }} className="text-gray-dark d-inline-block mb-2 mr-3" style={{ fontSize: '17px' }}>
                                        <FontAwesomeIcon icon={faChevronLeft} size="lg" /></a>
                                </Col>}
                                <Col md="3" className="text-center">
                                    <div className="upload-avatar position-relative d-inline-block mx-auto">
                                        <div className="profile-avatar profile-avatar-big d-inline-block">
                                            <div><img src={user?.avatar} width="140" height="140" alt={user.first_name + ' ' + user.last_name} /></div>
                                        </div>
                                    </div>
                                    <div className="mt-3 text-center">
                                        <p className="h5 text-dark mb-0 rubik">{user.first_name + ' ' + user.last_name}</p>
                                        {user.seniority && <p className="text-secondary mb-0">{user.seniority.name}</p>}
                                    </div>
                                </Col>
                                <Col md="9">
                                    {!user?.profile_story && isCurrentUser &&
                                        <>
                                            <h2 className="mb-3">What's your story? Do you have superpower?</h2>
                                            <button className="btn btn-outline-primary text-uppercase rounded-pill px-3" onClick={() => { changeStep && changeStep('startProfile') }}>Enter your story</button>
                                        </>
                                    }
                                    {(user?.profile_story || !isCurrentUser) &&
                                        <>
                                            <div className="mt-n5">
                                                {isCurrentUser && <a onClick={() => { changeStep && changeStep('startProfile') }} className="pr-2 text-decoration-none text-muted float-right cursor-pointer">
                                                    <FontAwesomeIcon icon={faPencilAlt} className="mr-1" />
                                                    <small className="font-weight-semibold">EDIT</small>
                                                </a>}

                                                {/* <Input type="textarea" name="profile_story" rows="7" defaultValue={user.profile_story} className="form-control" disabled bsSize="lg" /> */}

                                            </div>
                                            <div className="form-control rounded-lg custom-form-textarea mt-4" style={{ height: '150px' }}>
                                                <div dangerouslySetInnerHTML={{ __html: user.profile_story }}></div>
                                            </div>
                                        </>
                                    }

                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Container>
            </div>

        </>
    )
}