import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { Container, Row, Col, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import session from '../../../core/session';

import LoadingIndicator from '../../components/loading-indicator';
import { userActions, getCurrentUser } from '../../../core/users';
import { systemCheckActions } from '../../../core/systemCheck/actions';

class LoginForm extends Component {
    constructor(props) {
        super(props);
        const { user } = this.props
        this.state = {
            email: user?.error?.data?.belongs_to_any_account && !user?.error?.data?.belong_to_current_account ? user.email : '',
            password: '',
            errorInputs: null,
            rememberMe: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.getErrorRequest = this.getErrorRequest.bind(this);
    }

    componentDidMount() {
        const user = session.getRememberUser();
        if (user) {
            this.setState({
                email: user.email,
                password: user.password,
                rememberMe: !this.state.rememberMe
            })
        }
    }


    handleSubmit(e) {
        e.preventDefault()
        const { actions, history } = this.props;
        const { email, password, rememberMe } = this.state;

        if (this.validateForm()) {
            actions.loadUser({
                email,
                password,
                history
            });
            rememberMe ?
                session.setRememberUser({ email, password }) :
                session.deleteRememberUser();
            }
    }

    handleInputChange({ target }) {
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;

        this.setState({
            [name]: value
        });
    }

    validateForm = () => {
        const { email, password } = this.state;

        if (email.length === 0 && password.length !== 0 ) {
            this.setState({
                errorInputs: {
                    emailError: 'Email is required',
                    passwordError: ''
                }
            })
            return false;
        }
        
        if (password.length === 0 && email.length !== 0 ) {
            this.setState({
                errorInputs: {
                    emailError: '',
                    passwordError: 'Password is required'
                }
            })
            return false;
        }
        
        if(email.length === 0  && password.length === 0) {
            this.setState({
                errorInputs: {
                    emailError: 'Email is required',
                    passwordError: 'Password is required'
                }
            })
        }
        else {
            this.setState({
                errorInputs: null
            })
            return true;
        }
    }

    getErrorRequest = () => {
        const { user } = this.props
        let error = ""

        if(user?.error?.message) {
            let {belong_to_current_account = null, belongs_to_any_account = null, invalid_password = null} = user.error?.data

            switch(user?.error?.message) {
                case "Unauthorized": 
                    if(!belongs_to_any_account) {
                        error = <FormText>
                            This email address does not exist in our system. <br/>
                            Please check your email or create a new account by clicking the button below. <br/>
                            If you experience any issues <Link to="/contact-us"> contact us here. </Link>
                        </FormText>
                    } else if(!belong_to_current_account && !invalid_password) {
                        error = <FormText>
                            This user does not belong to this Account. <br/>
                            <Link to="/account-information-sent"> Please click here </Link> to recieve an email with the correct account information. <br/>
                            If you experience any issues <Link to="/contact-us"> contact us here. </Link>
                        </FormText>
                    } else if(belong_to_current_account && invalid_password) {
                        error = <FormText>
                            The password isn't right. <br/>
                            <Link to="/forgot-password"> Please click here </Link> to recover your password. <br/>
                            If you experience any issues <Link to="/contact-us"> contact us here. </Link>
                        </FormText>
                    } else if(!belong_to_current_account && invalid_password) {
                        error = <FormText>
                            This user does not belong to this Account. <br/>
                            Please verify My True Advance account url or create a new user. <br/>
                            If you experience any issues <Link to="/contact-us"> contact us here. </Link>
                        </FormText>
                    }
                    break;

                case "validation":
                    if(user.error?.data)
                        error = Object.keys(user.error?.data).map((itemError, index) => {
                            return <FormText>{user.error?.data[itemError]}</FormText>
                        })
                    break;

                case "Request failed with status code 429":
                    error = <FormText> Too many attempts, please wait a minute and try again. </FormText>
                    break;

                default: 
                    error = <FormText> An error has occurred, please wait and try again later. </FormText>
                    break;
            }
        }

        return error
    }

    // componentWillMount(oldProps, oldState) {
    //     if(!this.props.user.isPending && this.props.user.error && this.props.user.error.description && this.props.user.error.description && this.props.user.error.description.password_politics) {
    //         this.props.history.push({
    //             pathname: '/forgot-password',
    //             state: this.props.user.error.description.password_politics
    //         })
    //     }
    // } 

    render() {
        const { email, password, errorInputs, rememberMe } = this.state;
        const { user, systemCheck } = this.props;

        return (
            <>
                <div className="content-login content-view mb-4">
                    <h3 className="rubik pl-3 pl-md-0">Get Started</h3>
                    <Form className="form-login p-3 p-md-0">
                        <FormGroup>
                            <Label htmlFor="inputEmail">Email</Label>
                            <Input
                                type="email"
                                onChange={this.handleInputChange}
                                value={email}
                                name="email"
                                id="inputEmail"
                                className="rounded-pill"
                            />
                            {
                                errorInputs &&
                                <FormText>{errorInputs.emailError}</FormText>
                            }
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="inputPassword">Password</Label>
                            <Input
                                type="password"
                                onChange={this.handleInputChange}
                                value={password}
                                name="password"
                                id="inputPassword"
                                className="rounded-pill"
                            />
                            {
                                errorInputs &&
                                <FormText>{errorInputs.passwordError}</FormText>
                            }
                            {
                                user?.error?.message && this.getErrorRequest()
                            }
                        </FormGroup>
                        <FormGroup check className="mb-3">
                            <Label check>
                                <Input type="checkbox" name="rememberMe" value={rememberMe} onChange={this.handleInputChange} />{' '}Remember me
                            </Label>
                        </FormGroup>
                        <button type="submit" onClick={this.handleSubmit} className="btn text-uppercase rounded-pill btn-outline-primary btn-block px-md-5 mb-3">Login</button>
                        <p className="text-center mb-0 mb-md-5">
                            <Link to={'/forgot-password'} className="text-reset">Forgot Password?</Link>
                        </p>
                    </Form>
                            
                    <hr className="d-none mt-4 mb-5" />

                    {systemCheck && systemCheck.allow_register &&
                        <div className="px-3 px-md-0">
                            { systemCheck.login_version !== 'collective' && <Link to={'/signup'} className="btn btn-primary text-uppercase rounded-pill px-md-5 btn-block">CREATE A NEW ACCOUNT</Link>}
                            { systemCheck.login_version === 'collective' && <a href={'https://buy.stripe.com/28oaFP91o7XV7ni14d'/*'/signup-collective'*/} target="_blank" className="btn btn-primary text-uppercase rounded-pill px-md-5 btn-block">ACCESS TRUE ADVANCE ASSESSMENTS</a>}
                        </div>
                    }
                </div>

                {
                    user.isPending && <LoadingIndicator className="text-center" style={{ "marginTop": "15px", "display": "block" }} />
                }
                {systemCheck && systemCheck.allow_register &&
                  <p className="text-center mx-auto" style={{ maxWidth: '322px'}}>By creating an account, you agree to<br/>the True Advance Code of Conduct</p>
                }
            </>
        )
    }
}

//=====================================
//  CONNECT
//-------------------------------------

const mapStateToProps = (state, props) => {

    return {
        checked: state.user.checked,
        user: state.user,
        systemCheck: state.systemCheck && state.systemCheck.data && state.systemCheck.data.data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(userActions, dispatch)
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginForm);
