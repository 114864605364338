import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Collapse, Card } from "reactstrap";
import iconoThinkingTalent from "../../../assets/img/icon-thinking-talent.svg";
import iconoPlus from "../../../assets/img/icon-plus.svg";
import iconoLock from "../../../assets/img/icon-lock.svg";
import closemodal from "../../../assets/img/close-modal.svg";

import { getLeadershipCanvas } from "../../../core/leadership-canvas";

export const BoxCareerCanvasItem = ({ type = 'section', active, sectionCode, title, show_btn_view, open, show_btn_unlock, unlock_url, bottom_text, image, collapsed, questionnaire_name = 'questionnaire', coming_soon = false }) => {
    const [showUnlock, setShowUnlock] = useState(false)
    const showUnlockToggle = () => {
        setShowUnlock(!showUnlock)
    }

    return (
        <>
            <div key={title} className={`d-block box-passport text-center px-2 position-relative ${coming_soon ? 'comming-soon' : ''}`}>
                {/* agregar la clase active al Card cuando esta collapsed*/}
                <Card className={`p-4 text-center rounded-lg box-passport-section-${sectionCode} ${active ? 'active' : ''}`} style={{ cursor: (!collapsed || !open) ? 'default' : 'pointer' }} onClick={() => { collapsed && open && open() }}>
                    <h6 className="d-block text-uppercase text-center mb-0">{title}</h6>
                    <Collapse isOpen={!collapsed} >
                        <div className="img-icons"><img src={image ? image : iconoThinkingTalent} className="icono mx-auto mt-1" /></div>
                    </Collapse>
                </Card>

                <Collapse isOpen={!collapsed} className="box-collapse position-relative text-left" >
                    <div className="text-right mb-4">
                        {show_btn_view && open &&
                            <>
                                <a href="#" className={`text-center btn btn-sm btn-view px-3 border-0 btn-${type} ${type === 'section' ? 'rounded-pill' : 'rounded-circle'}`} onClick={(e) => { e.preventDefault(); open() }} >
                                    {type == 'section' ? 'View' : <img src={iconoPlus} width="16" style={{ margin: '-3px -7px 0' }} />}
                                </a>
                            </>
                        }
                        {show_btn_unlock &&
                            <>
                                <a className="text-center btn btn-sm btn-view btn-report px-3 border-0 btn-lock rounded-circle" onClick={showUnlockToggle} >
                                    <img src={iconoLock} width="16" style={{ margin: '-3px -7px 0' }} />
                                </a>
                                {<ToUnlock name={questionnaire_name} url={unlock_url} show={showUnlock} toggle={showUnlockToggle} open={open} />}
                            </>
                        }
                    </div>

                    {bottom_text && <p className="text-muted pt-2">{bottom_text}</p>}
                </Collapse>


                {coming_soon &&
                    <div className="box-comingsoon">
                        <h6 className="mb-0 line-height-normal px-5">COMING SOON</h6>
                    </div>
                }

            </div>
        </>
    )
}

const ToUnlock = ({ name = '', url, show, toggle, open }) => {
    return (<>
        {show &&
            <div className="position-absolute passport-card shadow text-left rounded-0 bg-white">
                <button type="button" className="close pr-1" aria-label="Close" onClick={toggle}><img src={closemodal} width="15" height="15" /></button>
                <div className="p-3">
                    <div className="gray-badge mx-auto mb-3"><img src={iconoLock} width="16" className="mx-auto d-block" /></div>
                    {(name !== 'Thinking Talents' && name !== 'Mind Pattern') && <p className="mb-0 text-muted">To unlock, please complete this <a href={`${url}`} onClick={(e) => { e.preventDefault(); open() }} >{`${name}`}</a> </p>}
                    {(name === 'Thinking Talents' || name === 'Mind Pattern') && <p className="mb-0 text-muted">To unlock, please click <a href={`${url}`} onClick={(e) => { e.preventDefault(); open() }}> here </a> to complete the {`${name}`} <b> Discovery </b> </p>}
                </div>
            </div>
        }
    </>
    )
}