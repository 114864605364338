import React, { useEffect, useState, Fragment } from 'react';
import { Button, Col, Form, FormGroup, FormText, Input, Label, Row, FormFeedback, CustomInput, Modal, ModalBody, ModalHeader } from 'reactstrap';

import { generateArrayOfYears } from '../../../core/helper';
import { ConfirmCurrentPasswordModal } from './ConfirmCurrentPasswordModal';
import { useSelector } from 'react-redux';

export const EditForm = ({
    inputValues,
    handleInputChange,
    onSubmitUpdateInfo,
    formErrors,
    formValid,
    strength,
    isCollective,
    statesIsPending,
    lists = []
}) => {
    const [openConfirmCurrentPasswordModal, setOpenConfirmCurrentPasswordModal] = useState(false);
    const [disabled, setDisabled] = useState(true);

    const toggleConfirmCurrentPasswordModal = () => {
        setOpenConfirmCurrentPasswordModal(!openConfirmCurrentPasswordModal);
    }

    const onSubmit = (e) => {
        e.preventDefault();

        if (inputValues.email) {
            toggleConfirmCurrentPasswordModal();
            return;
        }

        onSubmitUpdateInfo();
    }

    const onConfirmCurrentPassword = () => {
        onSubmitUpdateInfo();
    }

    useEffect(() => {
        setDisabled(!formValid());
    }, [inputValues, formErrors])

    return (
        <>
            <Form onSubmit={onSubmit} className="form-login p-3 p-md-0">
                <Row>
                    <Col sm={6}>
                        <FormGroup>
                            <Label for="first_name">First Name</Label>
                            <Input
                                type="text"
                                name="firstName"
                                id="first_name"
                                value={inputValues.firstName}
                                onChange={handleInputChange}
                                valid={formErrors.firstName === ''}
                                // defaultValue={user.first_name}
                                className="rounded-pill"

                            />
                            <FormText>{formErrors.firstName}</FormText>
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup>
                            <Label for="last_name">Last Name</Label>
                            <Input
                                type="text"
                                name="lastName"
                                id="last_name"
                                value={inputValues.lastName}
                                onChange={handleInputChange}
                                valid={formErrors.lastName === ''}
                                // defaultValue={user.last_name}
                                className="rounded-pill"
                            />
                            <FormText>{formErrors.lastName}</FormText>
                        </FormGroup>
                    </Col>
                </Row>
                {!isCollective && <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label className='control-label' for="email">Personal Email</Label>
                            <Input
                                type="email"
                                name="email"
                                id="email"
                                value={inputValues.email}
                                onChange={handleInputChange}
                                className="rounded-lg"
                                valid={(formErrors.email !== '' || inputValues.email === '') ? null : true}
                            />
                            <FormText>{formErrors.email}</FormText>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label className='control-label' for="confirm-email">Confirm Personal Email</Label>
                            <Input
                                type="email"
                                name="confirmEmail"
                                id="confirm-email"
                                value={inputValues.confirmEmail}
                                onChange={handleInputChange}
                                valid={(formErrors.confirmEmail !== '' || inputValues.confirmEmail === '') ? null : true}
                                className="rounded-lg"
                            />
                            <FormText>{formErrors.confirmEmail}</FormText>
                        </FormGroup>
                    </Col>
                </Row>}
                <Row>
                    <Col sm={6}>
                        <FormGroup>
                            <Label for="password">Password</Label>
                            <Input
                                type="password"
                                name="password"
                                id="password"
                                value={inputValues.password}
                                onChange={handleInputChange}
                                className="rounded-pill"
                            />
                            <FormText>{formErrors.password}</FormText>
                            {inputValues.password && !formErrors.password &&
                                <FormText>{strength}</FormText>
                            }
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup>
                            <Label for="confirm-pass">Confirm Password</Label>
                            <Input
                                type="password"
                                name="confirmPassword"
                                id="confirm-pass"
                                value={inputValues.confirmPassword}
                                onChange={handleInputChange}
                                className="rounded-pill"
                            />
                            <FormText>{formErrors.confirmPassword}</FormText>
                        </FormGroup>
                    </Col>
                </Row>
                {!isCollective && <Row>
                    <Col md={6}>
                        {lists?.seniorities?.size > 0 &&
                            <FormGroup>
                                <Label for="select">Seniority</Label>
                                <Input
                                    type="select"
                                    className="form-control rounded-lg"
                                    onChange={handleInputChange}
                                    name="seniority"
                                    invalid={formErrors.seniority === '' ? null : true}
                                    value={inputValues.seniority}
                                >
                                    <option value="">Select your option</option>
                                    {
                                        lists?.seniorities &&
                                        lists?.seniorities.map(item =>
                                            <option
                                                key={item.id}
                                                value={item.id}>
                                                {item.name}
                                            </option>
                                        )
                                    }
                                </Input>
                                <FormFeedback>{formErrors.currentCareerLevel}</FormFeedback>
                            </FormGroup>
                        }
                    </Col>
                </Row>}
                {isCollective && <Fragment>
                    <Row>
                        <Col md={6}>
                            <Row>
                                <Col md={12}>
                                    <Label for="select">Which year did you start your professional career?</Label>
                                    <FormGroup>
                                        <Input
                                            type="select"
                                            className="form-control rounded-lg"
                                            onChange={handleInputChange}
                                            name="careerStartYear"
                                            invalid={formErrors.careerStartYear === '' ? null : true}
                                            value={inputValues.careerStartYear}
                                        >
                                            <option value="">Select your option</option>
                                            {generateArrayOfYears().map(year =>
                                                <option
                                                    key={year}
                                                    value={year}>
                                                    {year}
                                                </option>
                                            )
                                            }
                                        </Input>
                                        <FormFeedback>{formErrors.careerStartYear}</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>

                        <Col md={6}>
                            {lists?.careerLevels.size > 0 &&
                                <FormGroup>
                                    <Label for="select">What is your current career level?</Label>
                                    <Input
                                        type="select"
                                        className="form-control rounded-lg"
                                        onChange={handleInputChange}
                                        name="currentCareerLevel"
                                        invalid={formErrors.currentCareerLevel === '' ? null : true}
                                        value={inputValues.currentCareerLevel}
                                    >
                                        <option value="">Select your option</option>
                                        {
                                            lists?.careerLevels &&
                                            lists?.careerLevels.map(item =>
                                                <option
                                                    key={item.id}
                                                    value={item.id}>
                                                    {item.name}
                                                </option>
                                            )
                                        }
                                    </Input>
                                    <FormFeedback>{formErrors.currentCareerLevel}</FormFeedback>
                                </FormGroup>
                            }
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            {lists?.professionalRole.size > 0 &&
                                <FormGroup>
                                    <Label for="select">Professional Role</Label>
                                    <Input
                                        type="select"
                                        className="form-control rounded-lg"
                                        onChange={handleInputChange}
                                        name="professionalRole"
                                        invalid={formErrors.professionalRole === '' ? null : true}
                                        value={inputValues.professionalRole}
                                    >
                                        <option value="">Select your option</option>
                                        {
                                            lists?.professionalRole &&
                                            lists?.professionalRole.map(item =>
                                                <option
                                                    key={item.id}
                                                    value={item.id}>
                                                    {item.name}
                                                </option>
                                            )
                                        }
                                    </Input>
                                    <FormFeedback>{formErrors.professionalRole}</FormFeedback>
                                </FormGroup>
                            }
                        </Col>
                        <Col md={6}>
                            {lists?.reasonsJoining.size > 0 &&
                                <FormGroup>
                                    <Label for="select">Which best describes your reason for joining the collective? Are you looking for: </Label>
                                    <div className="d-flex justify-content-between flex-column">
                                        {
                                            lists?.reasonsJoining &&
                                            lists?.reasonsJoining.map(item =>
                                                <CustomInput
                                                    type="radio"
                                                    name="reasonJoiningCollective"
                                                    className="pl-0 styled-checkbox d-flex align-items-center text-primary line-height-normal"
                                                    id={item.id}
                                                    onChange={handleInputChange}
                                                    value={item.id}
                                                    label={item.name}
                                                    defaultChecked={inputValues.reasonJoiningCollective === item.id}
                                                    cssModule={{ 'custom-control-label': 'custom-control-label pl-30' }}
                                                />
                                            )
                                        }
                                    </div>
                                    <FormFeedback>{formErrors.reasonJoiningCollective}</FormFeedback>
                                </FormGroup>
                            }
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="select">Do you identify as BIPOC (Black, Indigenous, Person of Color)?</Label>
                                <Input
                                    type="select"
                                    className="form-control rounded-lg"
                                    onChange={handleInputChange}
                                    name="BIPOC"
                                    invalid={formErrors.BIPOC === '' ? null : true}
                                    defaultValue={inputValues.BIPOC}
                                >
                                    <option value="">Select your option</option>
                                    <option
                                        key={1}
                                        value={1}>
                                        Yes
                                    </option>
                                    <option
                                        key={0}
                                        value={0}>
                                        No
                                    </option>
                                    <option
                                        key={2}
                                        value={2}>
                                        Prefer not to disclose
                                    </option>
                                </Input>
                                <FormFeedback>{formErrors.BIPOC}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            {lists?.hearAbout.size > 0 &&
                                <FormGroup>
                                    <Label for="select">How did you hear about us?</Label>
                                    <Input
                                        type="select"
                                        className="form-control rounded-lg"
                                        onChange={handleInputChange}
                                        name="hearAboutUs"
                                        invalid={formErrors.hearAboutUs === '' ? null : true}
                                        defaultValue={inputValues.hearAboutUs}
                                    >
                                        <option value=''>Select your option</option>
                                        {
                                            lists?.hearAbout &&
                                            lists?.hearAbout.map(item =>
                                                <option
                                                    key={item.id}
                                                    value={item.id}>
                                                    {item.name}
                                                </option>
                                            )
                                        }
                                    </Input>
                                    <FormFeedback>{formErrors.hearAboutUs}</FormFeedback>
                                </FormGroup>
                            }
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            {lists?.countries.size > 0 &&
                                <FormGroup>
                                    <Label for="select">Country</Label>
                                    <Input
                                        type="select"
                                        className="form-control rounded-lg"
                                        onChange={handleInputChange}
                                        name="country"
                                        invalid={formErrors.country === '' ? null : true}
                                        defaultValue={inputValues.country}
                                    >
                                        <option value=''>Select your option</option>
                                        {
                                            lists?.countries &&
                                            lists?.countries.map(item =>
                                                <option
                                                    key={item.id}
                                                    value={item.id}>
                                                    {item.name}
                                                </option>
                                            )
                                        }
                                    </Input>
                                    <FormFeedback>{formErrors.country}</FormFeedback>
                                </FormGroup>
                            }
                        </Col>

                        {inputValues.country &&
                            <Fragment>
                                {lists?.states?.size > 0 &&
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="select">State</Label>
                                            <Input
                                                type="select"
                                                className="form-control rounded-lg"
                                                onChange={handleInputChange}
                                                name="state"
                                                invalid={formErrors.state === '' ? null : true}
                                                defaultValue={inputValues?.state?.id}
                                            >
                                                <option value=''>Select your option</option>
                                                {
                                                    lists?.states &&
                                                    lists?.states.map(item =>
                                                        <option
                                                            key={item.id}
                                                            value={item.id}>
                                                            {item.name}
                                                        </option>
                                                    )
                                                }
                                            </Input>
                                            <FormFeedback>{formErrors.state}</FormFeedback>
                                        </FormGroup>
                                    </Col>

                                }
                                {!lists?.states?.size > 0 && !statesIsPending &&
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="confirm-pass">State</Label>
                                            <Input
                                                type="text"
                                                name="state"
                                                id="state"
                                                value={inputValues.state}
                                                onChange={handleInputChange}
                                                valid={(formErrors.state !== '' || inputValues.state === '') ? null : true}
                                                invalid={formErrors.state === '' ? null : true}
                                                className="rounded-lg"
                                            />
                                            <FormFeedback>{formErrors.state}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                }
                            </Fragment>
                        }
                    </Row>
                </Fragment>}

                <div className="justify-content-center">
                    <center><Button color="primary" type="submit" disabled={disabled} className="text-uppercase rounded-pill px-md-5">CONFIRM</Button></center>
                </div>

                <ConfirmCurrentPasswordModal isOpen={openConfirmCurrentPasswordModal} toggle={toggleConfirmCurrentPasswordModal} setCurrentPassword={(value) => handleInputChange({ target: { name: 'currentPassword', value } })} error={inputValues.currentPassword ? formErrors.currentPassword : null} onAccept={onConfirmCurrentPassword} />
            </Form>
        </>
    )
}
