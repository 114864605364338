import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Card } from 'reactstrap';
import { EventsHome } from './home/EventsHome';
import MindPatternsHome from './home/mindPatterns/mindPatternsHome';
import { NewMemberDiscovery } from './home/questionnaires/NewMemberDiscovery';
import TeamsHome from './home/teams/teamsHome';
import ThinkingTalentsHome from './home/thinkingTalents/thinkingTalentsHome';
import { Videos } from './home/Videos';
import { JobsHome } from './home/jobs/JobsHome';
import { BlogHome } from './home/blogs/BlogHome';
import icon_search from '../../assets/img/search-community.svg'
import { Welcome } from './home/Welcome';
import { LeadershipCanvas } from './home/LeadershipCanvas';
import { SimpleSlider } from '../components/common/SimpleSlider';

export const HomeTwoColumnsPage = ({ layoutformat, oneColumn }) => {
    const { videos } = useSelector(state => state);
    const { user } = useSelector(state => state);
    const systemCheck = useSelector(state => state?.systemCheck?.data?.data)
    const sliders = useSelector(state => state?.user?.account?.home_slides)
    const leadership_canvas_on = systemCheck?.leadership_canvas_on
    const thinking_talents_on = systemCheck?.thinking_talents_on
    const mind_patterns_on = systemCheck?.mind_patterns_on
    const teams_on = systemCheck?.teams_on

    return (
        <Row className={`${layoutformat}`}>
            <Col lg={{ size: 8 }} className="pr-md-1">
                <Row>
                    { sliders?.length > 0 &&
                        <Col xl="12" className="mt-3">
                            <Card className="p-4 mb-4 mb-xl-0">
                                <SimpleSlider dots={true} arrows={false} dotsClass="dots-on-home d-flex align-items-center justify-content-center pt-2 mb-n3" oneColumn={oneColumn} items={sliders /* [<Welcome oneColumn={oneColumn} />, <LeadershipCanvas oneColumn={oneColumn} /> ] */} ></SimpleSlider>
                            </Card>
                        </Col>
                    }

                    <Col xl='12' className="mt-3">
                        <Card className="p-4 mb-4 mb-xl-0">
                            <div className="px-md-3">
                                {!user.isPending && user.id && user.account && user.account.events_count > 0 &&
                                    <div className="events-list-home"><EventsHome /></div>
                                }
                                {videos && videos.data && videos.data.length > 0 && false &&
                                    <div className="videos-list-home">
                                        <div className="encabezado">
                                            <div className="mb-0 pt-2 pb-1 d-flex align-items-end">
                                                <h6 className="mb-0 title-underline pr-5">Videos</h6>
                                            </div>
                                            <span className="border border-info d-none d-md-block"></span>
                                        </div>
                                        <Videos />
                                    </div>
                                }
                                {!user.isPending && user.id && user.account && user.account.posts_count > 0 &&
                                    <BlogHome />
                                }
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Col>

            <Col lg={{ size: 4 }} className="small-column">
                <Row>
                    {user.id && !user.isPending && user.account && user.account.community_enabled &&
                        <Col xl="12" className="mt-3">
                            <Card className="px-4 py-1 mb-4 mb-xl-0">
                                <a href="community" className="text-decoration-none">
                                    <div className="encabezado encabezado-icono">
                                        <div className="mb-0 pt-2 pb-1 d-flex align-items-center justify-content-center">
                                            <img src={icon_search} width="40" height="auto" alt="Thinking Talents" style={{ maxHeight: '40px' }} />
                                            <h5 className="mb-0 mx-3 font-weight-semibold flex-fill text-center text-black">Member Search</h5>
                                        </div>
                                    </div>
                                </a>
                            </Card>
                        </Col>
                    }
                    {!user.isPending && user?.account?.company_cards > 0 &&
                        <Col xl='12' className="mt-3">
                            <Card className="px-4 py-1 mb-4 mb-xl-0">
                                <a href="company-cards" className="text-decoration-none">
                                    <div className="encabezado encabezado-icono">
                                        <div className="mb-0 pt-2 pb-1 d-flex align-items-center justify-content-center">
                                            <img src={icon_search} width="40" height="auto" alt="Thinking Talents" style={{ maxHeight: '40px' }} />
                                            <h5 className="mb-0 mx-3 font-weight-semibold flex-fill text-center text-black">Company Search</h5>
                                        </div>
                                    </div>
                                </a>
                            </Card>
                        </Col>
                    }
                    <JobsHome oneColumn={oneColumn} crelate={systemCheck?.jobs_origin === 'Crelate'} />
                    { leadership_canvas_on && 
                        <Col xl="12" className="mt-3"><Card className="py-4 px-4 px-md-1 mb-4 mb-xl-0">
                            <LeadershipCanvas size="small" />
                        </Card></Col>
                    }
                    {user.id && !user.isPending && user.account.questionnaires_count > 0 && !leadership_canvas_on && <NewMemberDiscovery />}
                    {!user.isPending && thinking_talents_on && <ThinkingTalentsHome />}
                    {mind_patterns_on && <MindPatternsHome />}
                    {user.is_manager === 1 && teams_on && <TeamsHome />}
                </Row>
            </Col>
        </Row>


    )
}


