import React, { useEffect, useState } from 'react';
import { Media, Badge } from 'reactstrap';

import { ThumbnailVideo } from '../../videos/ThumbnailVideo';


export const PostBody = (props) => {
    const { categories, title, featured_video, videos, subtitle, text, image } = props
    const all_videos = [featured_video, ...videos]

    return (
        <>
            <div className='px-md-5'>
                {categories && <div className="d-flex align-items-center flex-wrap mb-2">
                    {categories.map((category, index) => {
                        return (
                            <>
                                <Badge onClick={() => {window.location.href = `/front/content-library/${category.slug}`}} style={{ cursor: 'pointer' }} color="primary" className="text-capitalize px-2 py-1 rounded-pill" key={index}>{category.name}</Badge>
                                {index < categories.length - 1 && <span className="p-1"> / </span>}
                            </>
                        )
                    })}
                </div>
                }
                <h3 className="mb-2 mb-lg-4">{title}</h3>
                {featured_video && featured_video.code &&
                    <ThumbnailVideo className="mb-2 mb-lg-4" video={featured_video} videos={all_videos} index={0} />
                }

                {subtitle && <div className="mb-2 mb-lg-4 font-weight-bold" dangerouslySetInnerHTML={{ __html: subtitle}}></div>}

                {((image && image !== '') || (text && text !== '')) && <div>
                    {image && image !== '' && <img src={image} alt={`image-${title}`} className="w-md-35 w-50 mr-3 mb-3 float-left rounded-lg mt-1 mt-md-0" />}
                	{text && text !== '' &&
                		<div dangerouslySetInnerHTML={{ __html: text}}></div>
                	}
                	</div>
                }

            </div>
        </>
    )
}