
export const jobsActions = {

    FETCH_JOBS_FAILED: 'FETCH_JOBS_FAILED',
    FETCH_JOBS_SUCCESS:'FETCH_JOBS_SUCCESS',
    FETCH_JOBS_PENDING:'FETCH_JOBS_PENDING',
    LOAD_JOBS: 'LOAD_JOBS',
  
    fetchJobsFailed : error => ({
      type: jobsActions.FETCH_JOBS_FAILED,
      payload: error
    }),
  
    fetchJobsSuccess: (id, data) => ({
      type: jobsActions.FETCH_JOBS_SUCCESS,
      payload: {
        data,
        id
      }
    }),
  
    fetchJobsPending: id => ({
      type: jobsActions.FETCH_JOBS_PENDING,
      payload: {
        id
      }
    }),
  
    loadJobs: () => ({
      type: jobsActions.LOAD_JOBS
    }),
  };
  
  export const jobsRequestActions = {
    failed: jobsActions.fetchJobsFailed,
    success: jobsActions.fetchJobsSuccess,
    pending: jobsActions.fetchJobsPending
  };