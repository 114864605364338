export function getLeadershipCanvas(state) {
    return state.leadershipCanvas;
}

export function getLeadershipCanvasQuestionnaire(state) {
    return getLeadershipCanvas(state)?.questionnaire;
}

export function getLeadershipCanvasQuestionnaireByName(state, questionnaireName) {
    const questionnaire = getLeadershipCanvas(state)?.questionnaire && getLeadershipCanvas(state)?.questionnaire[questionnaireName]
    return questionnaire ? questionnaire : []
}

export function getLeadershipCanvasQuestionByCode(state, questionCode) {
    const questionnaire = getLeadershipCanvasQuestionnaire(state)
    let question = null

    if(questionnaire) {
        Object.values(questionnaire).map(listQuestions => {
            let result = Object.values(listQuestions)?.filter(question => question.item_code === questionCode)
            if(result?.length) {
                question = result[0]
            }
        });
    }

    return question
}