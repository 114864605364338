import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Link as LinkRS } from "react-scroll";
import { Navbar, Nav, NavItem, NavbarToggler, Collapse } from 'reactstrap';

import session from "../../../core/session";

import logo from '../../../assets/img/true.logo.circle.png'
import trueLogoText from '../../../assets/img/true.logo.text.webp'

export const Header = () => {
    const [menuIsOpen, setIsOpen] = useState(true)
    const toggleMenu = () => {
        setIsOpen(!menuIsOpen)
    }

    return (
        <header className="fixed-top bg-white">
            <Navbar expand="md" className="px-4 px-md-3 py-0">
                <div className="container d-flex justify-content-between align-items-stretch">
                    <div className={`py-2 ${!session.getAuthenticated() ? 'mx-auto ml-md-0' : ''}`}>
                        <Link className="mr-0 navbar-brand" to={session.getAuthenticated() ? "/home" : "/welcome"}>
                            <img src={trueLogoText} alt="True Advance" className="ml-2 d-none d-md-inline" />
                        </Link>
                    </div>

                    <NavbarToggler onClick={toggleMenu} className="p-0" />

                    <Collapse isOpen={menuIsOpen} className="justify-content-between nav-menu canvasReportMenu text-center ml-md-5 mr-md-0" navbar>
                        <NavbarToggler onClick={toggleMenu} className="icon-toggler-open p-0 border-0" />
                        <Nav navbar className="h-100">

                            <NavItem className="box-passport-section-thinking--learning mr-2 border-0">
                                <LinkRS className="nav-link text-uppercase" activeClass="active" smooth={true} spy={true} to="thinking-learning">Thinking & Learning</LinkRS>
                            </NavItem>

                            <NavItem className="box-passport-section-professional-experience mx-2 border-0">
                                <LinkRS className="nav-link text-uppercase" activeClass="active" smooth={true} spy={true} to="professional-experience">Professional Experience</LinkRS>
                            </NavItem>

                            <NavItem className="box-passport-section-work-preferences mx-2 border-0">
                                <LinkRS className="nav-link text-uppercase" activeClass="active" smooth={true} spy={true} to="work-preferences">Work Preferences</LinkRS>
                            </NavItem>

                            <NavItem className="box-passport-section-working-style mx-2 border-0">
                                <LinkRS className="nav-link text-uppercase" activeClass="active" smooth={true} spy={true} to="working-style">Working Style</LinkRS>
                            </NavItem>

                            <NavItem className="box-passport-section-leadership ml-2 border-0">
                                <LinkRS className="nav-link text-uppercase" activeClass="active" smooth={true} spy={true} to="leadership">Leadership</LinkRS>
                            </NavItem>

                        </Nav>
                    </Collapse>
                </div>
            </Navbar>
        </header>
    )
}