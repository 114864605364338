import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import icono_tt from "../../../../assets/img/icon-thinking-talent.svg"

import icono_ms from "../../../../assets/img/icono-mindset.svg"

import history from "../../../../core/history";
import { DynamicSection } from "./DynamicSection";
import { getLeadershipCanvasQuestionByCode } from "../../../../core/leadership-canvas";

//icons
import iconThinkingLearning from "../../../../assets/img/icon-thinking-learning.svg"
import iconThinkingTalents from "../../../../assets/img/icon-thinking-talents.svg";
import iconMindPatterns from "../../../../assets/img/icon-mind.svg"

//TT&MP
import ThinkingTalentsPage from '../../../pages/thinking-talents-page';
import MindPatternPage from '../../../pages/mind-pattern-page' 

//reports
import { MindsetReport } from "../index"

export const ThinkingAndLearningSection = ({ goBack, sectionCode, history }) => {

    const { user, leadershipCanvas } = useSelector(state => state)

    const intro = {
        code: sectionCode,
        text: `Your Thinking Talents and Mind Patterns represent what work gives you energy and shows how you learn new information. Combined with Mindset, this is a comprehensive view of how you work best and adapts to changes.`,
        image: iconThinkingLearning
    }
    const items = [
        {
            title: 'Thinking Talents',
            text: `${user.first_name}'s Thinking Talents are their innate ways of thinking. Thinking Talents are the specific ways of approaching challenges that energize their brain and come naturally to them. These are different from the skills they've developed through education and training. There are thirty-five. Thinking Talents in all, and most adults naturally use 5-8. Not only does ${user.first_name} lean into these talents, but when ${user.first_name} thinks this way ${user.first_name} feels energized.`,
            show_btn_view: leadershipCanvas.thinking_talents_done,
            show_btn_unlock: !leadershipCanvas.thinking_talents_done,
            // unlock_url: '/front/thinking-talents',
            questionnaire_name: 'Thinking Talents',
            bottom_text: 'The types of activities that energize someone',
            image: iconThinkingTalents,
            report: <ThinkingTalentsPage history={history} animation={false} />
        },
        {
            title: 'Mind Patterns',
            text: `
                Your Mind Pattern is the unique way that you process and respond to information. Mind Patterns can also help you intentionally shift from focused to generative thinking, help you design a working environment and develop new habits that evoke your best thinking. 
                <br>
                When working with others, understanding Mind Patterns can help you dissolve some of the immediate ways you might misread and therefore misjudge or miscommunicate with others.
                `,
            show_btn_view: leadershipCanvas.mind_pattern_done,
            show_btn_unlock: !leadershipCanvas.mind_pattern_done,
            // unlock_url: '/front/mind-patterns',
            questionnaire_name: 'Mind Pattern',
            bottom_text: 'Method of learning processing, and ideating',
            image: iconMindPatterns,
            report: <MindPatternPage history={history} animation={false} />
        },
        {
            title: 'Mindset',
            text: 'Your Mindset Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati blanditiis nemo dicta hic earum molestias ipsa rem. Officia quisquam fugiat commodi illum odio, accusantium, beatae dolore necessitatibus natus consequuntur velit?',
            show_btn_view: leadershipCanvas.leadership_discovery_done,
            show_btn_unlock: !leadershipCanvas.leadership_discovery_done,
            unlock_url: `/front/questionnaire/${leadershipCanvas?.questionnaire_id}`,
            bottom_text: 'Aptitude to adapt to changes or challenges',
            image: icono_ms,
            // report: <MindsetReport mindset={item_MS} />
            coming_soon: true
        },
    ]

    return (
        <DynamicSection history={history} intro={intro} items={items} goBack={goBack} imagen={iconThinkingLearning} />
    )
}