import React, { Fragment, useState, useEffect, useRef  } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { fitTextMax100 } from '../../../core/helper';
import { RecommendModal } from './RecommendModal';
import { ApplyModal } from './ApplyModal';
import { ThankModal } from './ThankModal';
//import ReactTextMoreLess from 'react-text-more-less';

export const JobItem = ({ job, jobId, openModal }) => {
    const [ openRecommendModal, setOpenRecommendModal ] = useState(false);
    const toggleRecommendModal = () => setOpenRecommendModal(!openRecommendModal);

    const [ expandText, setExpandText ] = useState(false)
    const toggleExpandText = () => setExpandText(!expandText);

    const [ openApplyModal, setOpenApplyModal ] = useState(false);
    const toggleApplyModal = () => setOpenApplyModal(!openApplyModal);

    const [ openThankModal, setOpenThankModal ] = useState(false);
    const toggleThankModal = () => setOpenThankModal(!openThankModal);

    const itemRef = useRef(null);

    useEffect(() => {
        if(job) {
            job.questions.forEach(question => {
                question.answer = ''
            });
        }
    }, [openApplyModal, openRecommendModal]);

    useEffect(() => {
        if(job) {
            job.questions.forEach(question => {
                question.answer = ''
            });
        }
    }, [openThankModal]);

    useEffect(() => {
        let isMounted = true
        if(isMounted) {
            jobId = parseInt(jobId);
            if(job.id === jobId) {
                itemRef.current.focus()
                setExpandText(true)
                if(openModal && openModal == "recommend") toggleRecommendModal();
                if(openModal && openModal == "apply") toggleApplyModal();
            }
        }
        return () => {
            isMounted = false
        }
    }, [])

    return (
        <Fragment>
            <div className="mt-3 item-jobs">
                <div className="mb-0 pt-2 pb-1 d-flex align-items-end" >
                    <h5>{job.title}</h5>
                </div>

                <div className="bg-celeste px-3 py-2 rounded mb-2">
                    <div className="mb-n3 text-petrol"><p>{job.subtitle}</p></div>
                </div>

                <Row className="align-items-stretch" >
                <Col md='9' className="d-md-flex justify-content-between flex-md-column">
                <span tabIndex="1" ref={itemRef}></span>
                {/* <ReactTextMoreLess
                    collapsed={expandText}
                    text="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Molestias cumque ducimus neque possimus atque magnam dolore tenetur fugiat est, accusamus incidunt! Sed labore distinctio nihil blanditiis dolor expedita voluptatum enim, accusamus nesciunt eos mollitia laudantium consectetur tenetur alias error quaerat modi unde voluptas! A sed accusantium doloribus distinctio voluptate corrupti, ut laudantium id unde sequi deserunt error illo voluptatem modi tenetur praesentium et porro recusandae. Praesentium quos nisi natus ducimus vel libero perferendis illum veniam."
                    lessHeight={18}
                /> */}
                {!expandText &&
                    <>
                    <div className="my-2" dangerouslySetInnerHTML={{ __html: fitTextMax100(job.text)}}></div>
                    <a style={{cursor: 'pointer'}} className="text-uppercase d-inline-flex text-reset p-0 mt-auto" color="link" onClick={() => toggleExpandText()} ><i className="icon-view-more-down mr-2"></i>View More</a>
                    </>
                }
                {expandText &&
                    <>
                    <div className="my-2" dangerouslySetInnerHTML={{ __html: job.text }}></div>
                    <a style={{cursor: 'pointer'}} className="text-uppercase d-inline-flex align-items-center text-reset p-0 mt-auto" color="link" onClick={() => toggleExpandText()} ><i className="icon-view-more-down mr-2 rotate-180"></i>View Less</a>
                    </>
                }
                    
                </Col>

                <Col md='3' className="text-center d-flex flex-md-column-reverse align-items-center justify-content-between justify-content-md-center">
                    <a className="text-uppercase d-inline-flex text-reset" style={{cursor: 'pointer'}} onClick={() => toggleRecommendModal()}><i className="icon-share mr-2"></i>Recommend</a>

                    <Button className="btn btn-outline-primary text-uppercase rounded-pill d-inline-flex px-5 my-2 mb-md-3" color="" onClick={() => toggleApplyModal()}> Apply </Button >
                    
                </Col>
                </Row>
            
                <span className="border border-muted d-block mt-3"></span>
            </div>
            <RecommendModal open={openRecommendModal} toggle={toggleRecommendModal} toggleThankModal={toggleThankModal} job_id={job.id} questions={job.questions} title={job.title} subtitle={job.subtitle} />
            <ApplyModal open={openApplyModal} toggle={toggleApplyModal} toggleThankModal={toggleThankModal} job_id={job.id} questions={job.questions} title={job.title} subtitle={job.subtitle}/>
            <ThankModal open={openThankModal} toggle={toggleThankModal} />
        </Fragment>
    )
}