import React, { Fragment, useEffect, useRef } from 'react';
import { Container, Col, Row, Card } from 'reactstrap';
import { JobItem } from './JobItem';
import { jobsActions } from '../../../core/jobs/actions'
import { useDispatch, useSelector } from 'react-redux';
import LoadingIndicator from '../loading-indicator/index';

export const JobsList = ({ jobId, openModal }) => {
    const dispatch = useDispatch();
    const { jobs } = useSelector(state => state);

    return (
        <>
            {
                jobs.data && jobs.data.map((job, index) => {
                    return <JobItem job={job} key={job.id} openModal={openModal} jobId={jobId} />
                })
            }
        </>

    )
}