export const pdfsActions = {

    FETCH_PDF_FAILED: 'FETCH_PDF_FAILED',
    FETCH_PDF_SUCCESS: 'FETCH_PDF_SUCCESS',
    FETCH_PDF_PENDING: 'FETCH_PDF_PENDING',
    OPEN_FULL_REPORT_TT: 'OPEN_FULL_REPORT_TT',
    OPEN_FULL_REPORT_MP: 'OPEN_FULL_REPORT_MP',
    OPEN_CAREER_CANVAS_REPORT: 'OPEN_CAREER_CANVAS_REPORT',
    DOWNLOAD_POST_DOCUMENT: 'DOWNLOAD_POST_DOCUMENT',

    fetchPdfFailed: error => ({
        type: pdfsActions.FETCH_PDF_FAILED,
        payload: error
    }),

    fetchPdfSuccess: (id, data) => ({
        type: pdfsActions.FETCH_PDF_SUCCESS,
        payload: {
            data,
            id
        }
    }),

    fetchPdfPending: () => ({
        type: pdfsActions.FETCH_PDF_PENDING,
    }),

    loadFullReportTT: () => ({
        type: pdfsActions.OPEN_FULL_REPORT_TT,
    }),

    loadFullReportMP: () => ({
        type: pdfsActions.OPEN_FULL_REPORT_MP,
    }),

    loadCareerCanvasReportPDF: () => ({
        type: pdfsActions.OPEN_CAREER_CANVAS_REPORT,
    }),

    downloadPostDocument: (id, name) => ({
        type: pdfsActions.DOWNLOAD_POST_DOCUMENT,
        payload: {
            id: id,
            name: name
        }
    })


};

export const pdfsRequestActions = {
    failed: pdfsActions.fetchPdfFailed,
    success: pdfsActions.fetchPdfSuccess,
    pending: pdfsActions.fetchPdfPending
};
