import { jobsActions } from './actions';
import { Jobs } from './jobs';


export const jobsReducer = (state = new Jobs(), { payload, type }) => {

    switch (type) {
        case jobsActions.FETCH_JOBS_FAILED:
            return state.merge({
                isPending: false,
                error: payload?.error?.data
            });

        case jobsActions.FETCH_JOBS_SUCCESS:
            return state.merge({
                data: payload.data,
                isPending: false,
            });

        case jobsActions.FETCH_JOBS_PENDING:
            return state.set('isPending', true);
        default:
            return state;
    }
}
