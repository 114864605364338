import { eventOutstandingActions } from './actions';
import { EventOutstanding } from './eventOutstanding';


export const eventOutstandingReducer = (state = new EventOutstanding(), { payload, type }) => {

    switch (type) {
        case eventOutstandingActions.FETCH_EVENT_OUTSTANDING_FAILED:
            return state.merge({
                isPending: false,
                error: payload?.error?.data
            });

        case eventOutstandingActions. FETCH_EVENT_OUTSTANDING_SUCCESS:
            return state.merge({
                data: payload.data,
                isPending: false,
            });

        case eventOutstandingActions.FETCH_EVENT_OUTSTANDING_PENDING:
            return state.set('isPending', true);
        default:
            return state;
    }
}
