export const leadershipCanvasActions = {
    FETCH_LEADERSHIP_CANVAS_FAILED: 'FETCH_LEADERSHIP_CANVAS_FAILED',
    FETCH_LEADERSHIP_CANVAS_SUCCESS: 'FETCH_LEADERSHIP_CANVAS_SUCCESS',
    FETCH_LEADERSHIP_CANVAS_PENDING: 'FETCH_LEADERSHIP_CANVAS_PENDING',
    LOAD_LEADERSHIP_CANVAS: 'LOAD_LEADERSHIP_CANVAS',

    fetchleadershipCanvasFailed: error => ({
        type: leadershipCanvasActions.FETCH_LEADERSHIP_CANVAS_FAILED,
        payload: { error }
    }),

    fetchleadershipCanvasSuccess: (id, data) => ({
        type: leadershipCanvasActions.FETCH_LEADERSHIP_CANVAS_SUCCESS,
        payload: {
            data,
            id
        }
    }),

    fetchleadershipCanvasPending: id => ({
        type: leadershipCanvasActions.FETCH_LEADERSHIP_CANVAS_PENDING,
        payload: {
            id
        }
    }),

    loadLeadershipCanvas: () => ({
        type: leadershipCanvasActions.LOAD_LEADERSHIP_CANVAS
    })

};


export const leadershipCanvasRequestActions = {
    failed: leadershipCanvasActions.fetchleadershipCanvasFailed,
    success: leadershipCanvasActions.fetchleadershipCanvasSuccess,
    pending: leadershipCanvasActions.fetchleadershipCanvasPending
};
