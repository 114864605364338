import React, { useState, useEffect } from 'react';
import { Form, FormGroup, FormText, Input, Label, Collapse, CustomInput, Button, NavbarToggler, Navbar } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faSearch } from '@fortawesome/free-solid-svg-icons';

import filter from '../../../assets/img/icon-filtro.svg';
import { ModalIndustry } from '../companyCards';
import { useDispatch, useSelector } from 'react-redux';
import { companyCardsListActions } from '../../../core/company-cards-list';
import { companyCardsFiltersActions, getCompanyCardFilterById } from '../../../core/company-cards-filters';

export const CompanyCardsFilters = ({ filters, setFilters, refresh }) => {
    const dispatch = useDispatch()
    const industries = useSelector(state => getCompanyCardFilterById(state, 'industries'))
    const certifications = useSelector(state => getCompanyCardFilterById(state, 'certifications'))
    const stages = useSelector(state => getCompanyCardFilterById(state, 'stages'))

    const [search, setSearch] = useState('')
    const [showCertifications, setShowCertifications] = useState(true)
    const [showIndustry, setShowIndustry] = useState(true)
    const [showStages, setShowStages] = useState(true)
    const [showModalIndustry, setShowModalIndustry] = useState(false)
    const [collapsed, setCollapsed] = useState(true)
    const [keepFilters, setKeepfilters] = useState(null)

    const toggleCertifications = () => setShowCertifications(!showCertifications)
    const toggleIndustry = () => setShowIndustry(!showIndustry)
    const toggleStages = () => setShowStages(!showStages)
    const toggleModalIndustry = () => setShowModalIndustry(!showModalIndustry)
    const toggleNavbar = () => setCollapsed(!collapsed)

    useEffect(() => {
      dispatch(companyCardsFiltersActions.fetchCompanyCardsIndustries())
      dispatch(companyCardsFiltersActions.fetchCompanyCardsCertifications())
    }, [])

    const searchCompany = () => {
        if(search) dispatch(companyCardsListActions.searchCompanyCards({'name': search}, 1))
        else dispatch(companyCardsListActions.searchCompanyCards({}, 1))
        
        clearAllFilters(false)
    }

    const getCompanyByFilters = () => {
        let formatFilters = {}
        formatFilters['name'] = search
        filters.forEach(filter => {
            if(filter.type === 'roles') {
                formatFilters[filter.type] = true
            }
            else if (formatFilters[filter.type])
                formatFilters[filter.type].push({id: filter.id})
            else {
                formatFilters[filter.type] = []
                formatFilters[filter.type].push({id: filter.id})
            }
        });
        dispatch(companyCardsListActions.searchCompanyCards(formatFilters, 1))
    }

    const handleKeyPress = (event) => {
        if (event.charCode == 13) {
            searchCompany()
        }
    }

    const handleInputChange = (target, name, type, calledOfModal = false) => {
        let id = parseInt(target.value) ? parseInt(target.value) : target.value
        let filter = filters

        if (target.checked) {
            filter.push({ id: id, name: name, type: type })
        } else {
            filter.splice(filter.map(function (item) { 
                if (item.type === type) return item.id
            }).indexOf(id), 1)
        }

        setFilters(setFilters)
        refresh()
        
        if(!calledOfModal && collapsed) getCompanyByFilters()
    }

    const clearAllFilters = (reload = true) => {
        if(!collapsed) {
            setKeepfilters([...filters])
            setFilters([])
        } else {
            setFilters([])
            refresh()
            if(reload) dispatch(companyCardsListActions.searchCompanyCards({}, 1))
        }
    }

    useEffect(() => {
      if(!collapsed) {
        setKeepfilters([...filters])
      } else if(keepFilters) {
          setFilters(keepFilters)
          setKeepfilters(null)
      }
    }, [collapsed]);

    const filtersApply = () => {
        setKeepfilters(null)
        getCompanyByFilters()
    }    

    return (
        <>
            <Form onSubmit={e => { e.preventDefault() }}>
                <FormGroup>
                    <Label className="my-md-2 mb-1 font-weight-semibold" for="search_company">Search a company or use the filtrers below</Label>
                    <div className="search-company-input">
                        <Input className="py-4 rounded-lg" type="text" id="search_company" name="search_company" defaultValue='' onKeyPress={(e) => handleKeyPress(e)} onChange={e => setSearch(e.target.value)} placeholder="Name and Leaders"></Input>
                        <Button onClick={() => { searchCompany() }} className="rounded-circle p-1 border-0" ><FontAwesomeIcon className="text-white" size="lg" icon={faSearch} /></Button>
                    </div>
                    <FormText></FormText>
                </FormGroup>
            </Form>
            <Form className="pb-md-4" onSubmit={e => { e.preventDefault() }}>
                <Button onClick={toggleNavbar} color="link" className="mb-3 d-md-none p-0 text-black text-decoration-none">
                    <img src={filter} width="20" height="auto" className="mr-2" /> FILTER
                </Button>

                <Collapse isOpen={!collapsed} className="d-md-flex flex-md-column navfilter">
                    <div className="d-md-none encabezado text-center position-relative">
                        <div className="mb-3 pb-1 d-inline-flex align-items-end">
                            <h5 className="pl-4 mb-0 title-underline" style={{ fontSize: '1.25rem', lineHeight: 'normal' }}>Filter by</h5>
                        </div>
                        <Button onClick={toggleNavbar} color="link" className="p-0 close icon-close mt-1"></Button>
                    </div>

                    <Button size="lg" color="secondary" outline block className="rounded-pill px-3 text-petrol text-left mb-2" onClick={() => toggleIndustry()} >
                        Industry
                        <FontAwesomeIcon className="ml-1 float-right text-naranja" size="lg" icon={showIndustry ? faChevronUp : faChevronDown} />
                    </Button>
                    <Collapse isOpen={showIndustry}>
                        <FormGroup className="mb-2">
                            {industries && industries.map((industry, index) => {
                                return <>{index < 3 &&
                                    <CustomInput key={'Industry-' + industry.id} checked={filters && filters.find(filter => filter.type === 'industries' && filter.id === industry.id)} onChange={({ target }) => { handleInputChange(target, industry.name, 'industries'); }} bsSize="sm" className="styled-checkbox pl-3 py-1" type="checkbox" label={industry.name} value={industry.id} id={'Industry-' + industry.id} />
                                }</>
                            })
                            }
                        </FormGroup>
                        <Button className="text-uppercase text-reset d-inline-flex p-0 mt-auto ml-3 mb-2" color="link" onClick={setShowModalIndustry} >View All Industries</Button>
                    </Collapse>

                    <Button size="lg" color="secondary" outline block className="rounded-pill px-3 text-petrol text-left mb-2" onClick={() => toggleCertifications()} >
                        Certifications
                        <FontAwesomeIcon className="ml-1 float-right text-naranja" size="lg" icon={showCertifications ? faChevronUp : faChevronDown} />
                    </Button>
                    <Collapse isOpen={showCertifications}>
                        <FormGroup className="mb-2">
                            {
                                certifications && certifications.map((certification) => {
                                    return <CustomInput key={certification.id} checked={filters && filters.find(filter => filter.type === 'certifications' && filter.id === certification.id )} onChange={({ target }) => { handleInputChange(target, certification.name, 'certifications'); }} bsSize="sm" className="styled-checkbox pl-3 py-1" type="checkbox" label={certification.name} id={certification.id} value={certification.id} />
                                })
                            }
                        </FormGroup>
                    </Collapse>

                    <Button size="lg" color="secondary" outline block className="rounded-pill px-3 text-petrol text-left mb-2" onClick={() => toggleStages()} >
                        Stages
                        <FontAwesomeIcon className="ml-1 float-right text-naranja" size="lg" icon={showStages ? faChevronUp : faChevronDown} />
                    </Button>
                    <Collapse isOpen={showStages}>
                        <FormGroup className="mb-2">
                            {
                                stages && stages.map((stage) => {
                                    return <CustomInput key={stage.id} checked={filters && filters.find(filter => filter.type === 'stages' && filter.id === stage.id )} onChange={({ target }) => { handleInputChange(target, stage.name, 'stages'); }} bsSize="sm" className="styled-checkbox pl-3 py-1" type="checkbox" label={stage.name} id={stage.id} value={stage.id} />
                                })
                            }
                        </FormGroup>
                    </Collapse>

                    <div className="border-top mt-1 pl-3 pb-0 mb-0">
                        <FormGroup>
                            <CustomInput checked={filters && filters.find(filter => filter.type === 'roles' && filter.id === "open_roles")} onChange={({ target }) => { handleInputChange(target, "Open Roles", 'roles'); }} bsSize="sm" className="styled-checkbox pl-0 pt-3" type="checkbox" label="Open Roles" id="open_roles" value="open_roles" />
                        </FormGroup>
                    </div>

                    <Button onClick={() => clearAllFilters()} color="primary" outline block className="mt-3 rounded-pill text-uppercase">Clear all filters</Button>
                    <Button onClick={() => {filtersApply(); toggleNavbar();}} color="primary" block className="d-md-none mt-3 rounded-pill text-uppercase">Apply</Button>
                </Collapse>

            </Form>
            <ModalIndustry open={showModalIndustry} toggle={toggleModalIndustry} handleInputChange={handleInputChange} filters={filters} showApply={collapsed} getCompanyByFilters={getCompanyByFilters} />
        </>
    )
}