import React, { useState, useEffect } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Container, Col, Row } from 'reactstrap';
import { useSelector, useDispatch, connect } from 'react-redux';

import { CompanyCardsFilters, CompanyCardsResults, ResumeCompany, ModalIndustry } from '../../components/companyCards';
import { companyCardsListActions, getCurrentCompanyCardsList } from '../../../core/company-cards-list';

const animationClass = {
    appearActive: 'animated slideInRight',
    enter: 'animated',
    enterActive: 'animated slideInRight',
    exit: 'animated',
    exitActive: 'animated slideOutLeft',
}

export const CompanyCards = () => {
    const dispatch = useDispatch()
    const { user } = useSelector(state => state)
    const currentCompanyCardsLists = useSelector(state => getCurrentCompanyCardsList(state))

    const [refresh, setRefresh] = useState(false)
    const toggleRefresh = () => setRefresh(!refresh)
    const [filters, setFilters] = useState([])

    useEffect(() => {
        dispatch(companyCardsListActions.searchCompanyCards({}, 1))
    }, [])

    return (
        <>
            {!user.isPending &&
                <div className='view overflow-hidden'>
                    <TransitionGroup className="content">
                        <CSSTransition classNames={animationClass} timeout={{ enter: 1000, exit: 1000 }} appear >
                            <div className="w-100">
                                <Container>
                                    {/* p-4 pb-md-1 pt-md-2  */}
                                    <div className="bg-white my-md-4 rounded-lg content-view shadow mx-n3 mx-md-auto">
                                        <Row className="mx-0 align-items-stretch content-view">
                                            <Col md={{ size: 3 }} className="bg-md-light rounded-lg-left pb-md-1 pt-md-3 px-4">
                                                <CompanyCardsFilters filters={filters} setFilters={setFilters} refresh={toggleRefresh} />
                                            </Col>
                                            <Col md={{ size: 9 }} className="px-4 pb-4 pb-md-1 pt-md-3 px-md-0 d-flex flex-column">
                                                <CompanyCardsResults filters={filters} setFilters={setFilters} />
                                            </Col>
                                        </Row>
                                    </div>
                                </Container>
                            </div>
                        </CSSTransition>
                    </TransitionGroup>
                </div>
            }
        </>
    )
}