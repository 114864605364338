import { call, fork, takeEvery } from 'redux-saga/effects';
import { fetchLeadershipCanvasReport } from '../api';
import { leadershipCanvasReportActions } from './actions';

export function* loadLeadershipCanvasReport({ payload }) {
    const { token_id } = payload;
    yield call(fetchLeadershipCanvasReport, token_id);
}

//Watchers
export function* watchLoadLeadershipCanvasReport() {
    yield takeEvery(leadershipCanvasReportActions.LOAD_LEADERSHIP_CANVAS_REPORT, loadLeadershipCanvasReport)
}

//Root
export const leadershipCanvasReportSaga = [
    fork(watchLoadLeadershipCanvasReport),
]
