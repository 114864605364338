import { List, Record } from 'immutable';

export const CompanyCardsList = new Record({
  id: null,
  isNew: true,
  isPending: false,
  itemsId: new List(),
  current_page: null,
  total_pages: null,
  total_items: null,
  error:null
});
