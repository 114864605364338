import React, { Fragment, useEffect } from 'react';
import { Button , Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { fitTextMax100 } from '../../../../core/helper';
import arrow_icon from '../../../../assets/img/icon-arrow.svg';
import share_icon from '../../../../assets/img/icon-share.svg';

export const JobItemHome = ({ item }) => {

    return (
    <>
        <Col>
            <div className="item-jobs">
                <span className="border border-muted d-block mt-2"></span>
                <div className="mt-2 mb-0 pb-1 d-flex align-items-end">
                    <h5>{item.title}</h5>
                </div>

                <div className="bg-celeste px-2 py-1 rounded mb-2">
                    <div className="mb-n3 text-petrol"><p>{item.subtitle}</p></div>
                </div>

                <div className="my-2" dangerouslySetInnerHTML={{ __html: fitTextMax100(item.text) }}></div>

                <div className="d-flex justify-content-between">
                    <a className="text-uppercase d-inline-flex text-reset" href={`/front/jobs/${item.id}/recommend`}><i className="icon-share mr-2"></i>Recommend</a>
                    <a className="text-uppercase d-inline-flex text-reset" color="link" href={`/front/jobs/${item.id}`}><i className="icon-view-more mr-2"></i>View More</a>
                </div>
            </div>
        </Col>
    </>
    )
}