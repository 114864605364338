import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Card } from 'reactstrap';
import { fitTextMax100 } from '../../../core/helper';

export const LeadershipCanvas = ({ oneColumn, size }) => {
    const { user } = useSelector(state => state);

    const text = 'Designed for professionals in pursuit of career advancement, learn about what makes you unique as a leader and collaborator.'

    return (
        <div className={`${oneColumn ? "px-md-5" : 'px-md-3'}`}>
            {!size && <h3 className="mb-3 pl-4 rubik font-weight-normal">Career Canvas</h3>}
            <div className="d-md-flex align-items-start">
                { size && size == 'small' &&
                <div className="profile-avatar mb-2 mx-3 mt-2"><img src={user.avatar} width="60" alt={user.first_name + ' ' + user.last_name} /></div>
                }
                { (!size || size != 'small') &&
                <div className="profile-avatar mb-2 mx-4 mt-2"><img src={user.avatar} width="80" alt={user.first_name + ' ' + user.last_name} /></div>
                }
                <div className="mt-2">
                    {size && size == 'small' && <h5 className="mb-3 font-weight-semibold">Career Canvas</h5>}
                    <p>
                        { size && size == 'small' &&
                            fitTextMax100(text)
                        }
                        { (!size || size != 'small') &&
                            text
                        }
                    </p>
                    {(!size || size != "small") && <a className={`btn btn-outline-primary text-uppercase rounded-pill px-md-5`} href="/front/career-canvas">View Career Canvas</a>}
                </div>
            </div>
            {(size && size == "small") &&  <div className="text-center"><a className={`btn btn-outline-primary text-uppercase rounded-pill px-md-5 mx-auto`} href="/front/career-canvas">View Career Canvas</a></div>}
        </div>
    )
}
