import { Record } from 'immutable';

export const ForgotPassword = new Record({
    success: false,
    message: null,
    code: null,
    isPending: false,
    type: null,
    data: null,
    reset: false,
    error_description: null
});