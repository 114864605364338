import { Record, List } from 'immutable';

export const LeadershipCanvasReport = new Record({
    isPending: false,
    error: null,
    user: null,
    mind_pattern: null,
    thinking_talents: [],
    mind_pattern_done: false,
    thinking_talents_done: false,
    leadership_discovery_done: false,
    questionnaire: new List()
});