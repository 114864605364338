import React, { Fragment, useEffect } from 'react';
import { Container, Col, Row, Card } from 'reactstrap';
import { Link } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { JobsList } from '../../components/jobs/JobsList';
import { useParams } from 'react-router-dom';
import { jobsActions } from '../../../core/jobs/actions'
import { useDispatch, useSelector } from 'react-redux';
import LoadingIndicator from '../../components/loading-indicator/loading-indicator';

import { trackingActions } from '../../../core/tracking';

const animationClass = {
    appearActive: 'animated slideInRight',
    enter: 'animated',
    enterActive: 'animated slideInRight',
    exit: 'animated',
    exitActive: 'animated slideOutLeft',
}

export const Jobs = () => {
    const { id, open } = useParams();
    const dispatch = useDispatch();
    const { jobs, user } = useSelector(state => state);
    const crelate = useSelector( state => state?.systemCheck?.data?.data?.jobs_origin === 'Crelate' )
    
    useEffect(() => {
        if(!crelate && !jobs.isPending)
            dispatch(trackingActions.saveTrackingEvent({category: 'Jobs', action: 'View Jobs'}))

        dispatch(jobsActions.loadJobs())
    }, []);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://app.crelate.com/dist/candidateportal/libs-widget.all.min.js?v=1659585675";
        script.async = true;
        document.body.appendChild(script);
      
        return () => {
          document.body.removeChild(script);
        }
    }, []);

    return (
        <div className='view overflow-hidden'>
            { ((!crelate && !jobs.isPending && !user.isPending) || crelate) &&

                <TransitionGroup className="content">
                    <CSSTransition classNames={animationClass} timeout={{ enter: 1000, exit: 1000 }} appear >
                        <div className="w-100">
                            <Container>
                                <Card className="bg-white my-md-4 rounded-lg p-4 pb-md-5 px-md-5 pt-md-2 content-view">
                                    <div className="px-md-4">
                                        { crelate &&
                                            <div id="cr-main-widget" data-org_name="bleekerco" data-config="pw_ShowOnly=featured;pw_ShowJobsAs=list;pw_GroupJobsBy=none;pw_ShowLocationFilter=true;pw_ShowTagsFilter=true;pw_HyperlinksOpenAs=_blank;"></div>
                                        }

                                        { !crelate &&
                                            <>
                                                <div className="encabezado mb-md-3">
                                                    <div className="mb-3 mb-md-0 pt-md-2 pb-md-1 d-flex align-items-center">
                                                        <h6 className="mb-0 title-underline pr-5">Jobs</h6>
                                                    </div>
                                                    <span className="border border-info d-none d-md-block"></span>
                                                </div>
                                                <JobsList openModal={open} jobId={id} />
                                            </>
                                        }
                                    </div>
                                </Card>
                            </Container>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            }
            {/* {
            jobs.isPending || user.isPending &&
                <LoadingIndicator/>
            }  */}
        </div>
    )
}