import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Table, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import { PostPreview } from './PostPreview';

export const BlogResults = ({ filters, setFilters, refresh, posts, current_page, total_pages, goToPage }) => {

    const deleteFilter = (id, type) => {
        let filter = filters
        filter.splice(filter.map(function (item) {
            if (item.type === type && item.id === id) return item.id
        }).indexOf(id), 1)

        setFilters(filter)
        refresh()
    }

    const calculatePageNumber = (position) => {
        if (current_page) {
            let page = 1;
            switch (position) {
                case 1: current_page <= 3 ? page = position : current_page + 2 >= total_pages ? page = total_pages - 4 : page = current_page - 2; break;
                case 2: current_page <= 3 ? page = position : current_page + 2 >= total_pages ? page = total_pages - 3 : page = current_page - 1; break;
                case 3: current_page <= 3 ? page = position : current_page + 2 >= total_pages ? page = total_pages - 2 : page = current_page; break;
                case 4: current_page <= 3 ? page = position : current_page + 2 >= total_pages ? page = total_pages - 1 : page = current_page + 1; break;
                case 5: current_page <= 3 ? page = position : current_page + 2 >= total_pages ? page = total_pages - 0 : page = current_page + 2; break;
            }
            return page;
        }
    }

    return (
        <>
            {filters && filters.length > 0 &&
                <div className="border-bottom pl-lg-5 d-flex flex-wrap align-items-center">
                    <div className="d-flex flex-wrap results-items">
                        {filters && filters.map((filter, index) => {
                            return <Button key={index} onClick={() => deleteFilter(filter.id, filter.type)} size="sm" color="yellow" className="rounded-pill px-2 pr-4 text-left mr-3 my-2">{filter.name}</Button>
                        })
                        }
                    </div>
                </div>
            }

            { posts &&
            <div className="mt-1 mt-md-4 ml-md-4 mr-md-3 blog-list">
                {
                    posts && posts.map((post, index) => {
                        return <PostPreview id={post.id} openable={false} video={{ title: post.featured_video_title, text: post.featured_video_text, code: post.featured_video_code }} videos={[{ title: post.featured_video_title, text: post.featured_video_text, code: post.featured_video_code }, ...post.videos]} image={post.image} title={post.title} subtitle={post.subtitle} categories={post.categories} last={ post.length > 0 && post.length === index} />
                    })
                }
            </div>
            }

            {total_pages > 1 &&
                <Pagination className="mt-auto d-none d-md-block" cssModule={{ 'pagination': 'pagination justify-content-center align-items-center' }}>
                <PaginationItem disabled={(current_page === 1)} ><PaginationLink previous onClick={() => goToPage(current_page - 1)} children={[<FontAwesomeIcon icon={faChevronLeft} />]} /></PaginationItem>

                {current_page > 3 && <>
                    <PaginationItem> <PaginationLink onClick={() => goToPage(1)}> {calculatePageNumber(total_pages)} </PaginationLink></PaginationItem>
                    <PaginationItem> <PaginationLink > ... </PaginationLink></PaginationItem>
                </>
                }

                    {calculatePageNumber(1) <= total_pages && <PaginationItem disabled={calculatePageNumber(1) > total_pages} active={(current_page === calculatePageNumber(1))}>  <PaginationLink onClick={() => goToPage(calculatePageNumber(1))}>   {calculatePageNumber(1)}  </PaginationLink></PaginationItem>}
                    {calculatePageNumber(2) <= total_pages && <PaginationItem disabled={calculatePageNumber(2) > total_pages} active={(current_page === calculatePageNumber(2))}>  <PaginationLink onClick={() => goToPage(calculatePageNumber(2))}>   {calculatePageNumber(2)}  </PaginationLink></PaginationItem>}
                    {calculatePageNumber(3) <= total_pages && <PaginationItem disabled={calculatePageNumber(3) > total_pages} active={(current_page === calculatePageNumber(3))}>  <PaginationLink onClick={() => goToPage(calculatePageNumber(3))}>   {calculatePageNumber(3)}  </PaginationLink></PaginationItem>}
                    {calculatePageNumber(4) <= total_pages && <PaginationItem disabled={calculatePageNumber(4) > total_pages} active={(current_page === calculatePageNumber(4))}>  <PaginationLink onClick={() => goToPage(calculatePageNumber(4))}>   {calculatePageNumber(4)}  </PaginationLink></PaginationItem>}
                    {calculatePageNumber(5) <= total_pages && <PaginationItem disabled={calculatePageNumber(5) > total_pages} active={(current_page === calculatePageNumber(5))}>  <PaginationLink onClick={() => goToPage(calculatePageNumber(5))}>   {calculatePageNumber(5)}  </PaginationLink></PaginationItem>}

                {calculatePageNumber(5) + 1 <= total_pages && <>
                    <PaginationItem> <PaginationLink > ... </PaginationLink></PaginationItem>
                    <PaginationItem> <PaginationLink onClick={() => goToPage(total_pages)}> {total_pages} </PaginationLink></PaginationItem>
                </>
                }

                <PaginationItem disabled={(current_page === total_pages)} ><PaginationLink next onClick={() => goToPage(current_page + 1)} children={[<FontAwesomeIcon icon={faChevronRight} />]} /></PaginationItem>
            </Pagination>
            }
        </>
    )
}