import { call, fork, takeEvery } from 'redux-saga/effects';
import { fetchLeadershipCanvas } from '../api';
import { leadershipCanvasActions } from './actions';

export function* loadLeadershipCanvas() {console.log('1')
    yield call(fetchLeadershipCanvas);
}

//Watchers
export function* watchLoadLeadershipCanvas() {
    yield takeEvery(leadershipCanvasActions.LOAD_LEADERSHIP_CANVAS, loadLeadershipCanvas)
}

//Root
export const leadershipCanvasSaga = [
    fork(watchLoadLeadershipCanvas),
]
