import { jobApplyActions } from './actions';
import { JobApply } from './jobApply';


export const jobApplyReducer = (state = new JobApply(), { payload, type }) => {

    switch (type) {
        case jobApplyActions.FETCH_JOB_APPLY_FAILED:
            return state.merge({
                isPending: false,
                error: payload?.error?.data
            });

        case jobApplyActions.FETCH_JOB_APPLY_SUCCESS:
            return state.merge({
                data: payload.data,
                isPending: false,
            });

        case jobApplyActions.FETCH_JOB_APPLY_PENDING:
            return state.set('isPending', true);
        default:
            return state;
    }
}
