import { List , Map } from 'immutable';
import { companyCardsListActions } from './actions';
import { CompanyCardsList } from './company-cards-list';
import { DEFAULT_SESSION_ID } from '../constants';
import { companyCardsListIdForFind } from './utils';

export const initialState = new Map({
  currentMembersListId: companyCardsListIdForFind(DEFAULT_SESSION_ID),
  [companyCardsListIdForFind(DEFAULT_SESSION_ID)]: new CompanyCardsList({id: DEFAULT_SESSION_ID, isNew: false})
});


export function companyCardsListsReducer(state = initialState, action) {

  const { type, payload } = action;

  switch (type) {

    case companyCardsListActions.FETCH_COMPANY_CARDS_FAILED:
    case companyCardsListActions.FETCH_COMPANY_CARDS_SUCCESS:
    case companyCardsListActions.FETCH_COMPANY_CARDS_PENDING:
      return state.set(
        payload.companyCardsListId,
        companyCardsListReducer(state.get(payload.companyCardsListId), action)
      );
    case companyCardsListActions.SEARCH_COMPANY_CARDS:
      return state.merge({
        currentCompanyCardsListId: payload.companyCardsListId,
        [payload.companyCardsListId]: companyCardsListReducer(state.get(payload.companyCardsListId), action)
      });
    default:
      return state;
  }
}

export const companyCardsListReducer = (state = new CompanyCardsList(),  {payload, type} ) => {

  switch (type) {
    case companyCardsListActions.FETCH_COMPANY_CARDS_FAILED:
      return state.merge({
        isPending: false,
        error: payload.code
      });
    case companyCardsListActions.FETCH_COMPANY_CARDS_SUCCESS:    
      return state.withMutations( companyCardslist => {
        companyCardslist.merge({
          isNew: false,
          isPending: false,
          itemsId: mergeIds(companyCardslist.itemsId, payload.data ? payload.data.items : []),
          current_page: payload.data ? payload.data.current_page : null,
          total_pages: payload.data ? payload.data.total_pages : null,
          total_items: payload.data ? payload.data.total_items : null
        });
      });
    case companyCardsListActions.FETCH_COMPANY_CARDS_PENDING:
      return state.set('isPending', true);
    // case companyCardsListActions.FIND_MEMBERS:
    // case companyCardsListActions.SEARCH_MEMBERS:
    //   return state.merge({
    //     id: payload.membersListId,
    //   });
    // case companyCardsListActions.FIND_MEMBERS_BY_ID:
    //   return state.merge({
    //     id: payload.membersListId,
    //     isNew:false,
    //     itemsId:new List([payload.membersListId])
    //   });
    default:
      return state;
  }
}

function mergeIds(itemsId, items) {
  let ids = itemsId.toJS();

  //orden asc
  /*
  items = items.sort(function (a, b) {
    return (a.order - b.order)
  })
  */

  let newIds = items.reduce((list, itemData) => {
    if (ids.indexOf(itemData.id) === -1) list.push(itemData.id);
    return list;
  }, []);

  return newIds.length ? new List(ids.concat(newIds)) : itemsId;
}
