import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, Input, Form, FormGroup, Container, FormText, CustomInput } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { jobApplyActions } from '../../../core/job-apply';
import { ExtraQuestion } from './ExtraQuestion';
import jobs_icon from '../../../assets/img/jobs-icon.svg';
import LoadingIndicator from '../loading-indicator/loading-indicator';
import { trackingActions } from '../../../core/tracking';

const emailRegex = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

export const ApplyModal = ({ open, toggle, job_id, questions, toggleThankModal, title, subtitle }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [ disabled, setDisabled ] = useState(true);
    const { user } = useSelector(state => state);
    const { job_apply } = useSelector(state => state);
    const [ sent, setSent ] = useState(false);
    const countQuestionsMandatory = () => {
        let count = 0
        questions.forEach(question => {
            if(question.mandatory) count++
        });
        return count
    }
    const [ countQuestionsErrors, setCountQuestionsErrors ] = useState(countQuestionsMandatory());

    const formValid = () => {
        let valid = true;

        Object.values(formErrors).forEach(val => {
            val.length > 0 && (valid = false);
        });
        if(!inputValues.phone || !inputValues.email || (!inputValues.linkedinProfile && !inputValues.resume)) valid = false
        return valid;
    };

    const [formErrors] = useState({
        email: '',
        linkedinProfile: '',
        phone: '',
        resume: ''
    });

    const [inputValues, setInputValues] = useState({
        email: user.email ? user.email : '',
        linkedinProfile: '',
        phone: '',
        resume: '',
    })

    const [resumePdf, setResumePdf] = useState(null);

    const handleInputChange = async ({ target }) => {
        const { name, value, maxLength } = target;

        switch (name) {
            case "email":
                formErrors.email = emailRegex.test(value)
                    ? ""
                    : "Invalid email address";
                break;

            case "phone":
                formErrors.phone = !(value.length > 8)
                    ? "Invalid phone" : "";
                break;

            case "linkedinProfile":
                formErrors.linkedinProfile = (value.includes("linkedin.com/in/") || value === "")
                    ? "" : "Invalid link";
                break;

            case "resume":
                if(!value){
                } else {
                    const [{ size }] = target.files;

                    if(size/1024 > 2048){
                        formErrors.resume = "Max: 2MB"
                    } else {
                        formErrors.resume = ""
                        setResumePdf(target.files[0])
                    }
                }
                break;
            default:
                break;
        }
        setInputValues({
            ...inputValues,
            [name]: maxLength !== -1 ? value.slice(0,maxLength) : value
        });
    }

    useEffect(() => {
        setDisabled(!formValid());
    }, [inputValues])

    const onSubmitApply = async (e) => {
        e.preventDefault();
        
        if (formValid()) {
            let infoApply = {
                type: 'Apply',
                first_name: user.first_name,
                last_name: user.last_name,
                email: inputValues.email,
                phone: inputValues.phone,
                resume: resumePdf,
                linkedin: inputValues.linkedinProfile,
                job_id: job_id,
                user_id: user.id,
                questions: questions
            }
            dispatch(jobApplyActions.storeApply(infoApply));
            dispatch(trackingActions.saveTrackingEvent({category: 'Jobs',action: 'Application received'}))
            setSent(true)
        }
    }

    const resetInputs = () => {
        setInputValues({
            email: user.email ? user.email : '',
            linkedinProfile: '',
            phone: '',
            resume: '',
        })
    }

    useEffect(() => {
        resetInputs()
    }, [toggle])

    useEffect(() => {
        if(!job_apply.isPending && sent) {
            resetInputs()
            toggle();
            setSent(false)
            toggleThankModal()
        }
    }, [job_apply])

    useEffect(() => {
        console.log('countQuestionsErrors: ', countQuestionsErrors)
        console.log('disabled: ', disabled)
        console.log('formErrors: ', formErrors)
        console.log('inputValues: ', inputValues)
    }, [countQuestionsErrors, disabled, ])

    return (
        <Modal isOpen={open} toggle={toggle} className="modal-dialog-summary float-right">
            <ModalHeader toggle={toggle}></ModalHeader>
            <ModalBody>
                <Row className="mb-2 d-flex align-items-end">
                    <Col xs="2"><img src={jobs_icon} alt="Jobs" width="24" height="auto" className="mx-3" /></Col>
                    <Col xs="8"><h5 className="mb-0 mt-3 font-weight-semibold">{title}</h5></Col>
                    <Col md="10"><span className="border border-info d-block mt-2"></span></Col>
                </Row>
                
                <Row className="justify-content-md-center">
                    <Col md="8">
                        <div className="bg-celeste p-2 rounded my-2">
                            <p className="text-reset mb-0">{subtitle}</p>
                        </div>
                        <h5 className="text-reset my-3">Application Information</h5>

                        <Form onSubmit={onSubmitApply} className="mt-3">
                            <FormGroup>
                                <Input type="email" placeholder="* E-mail" name="email" id="inputEmail" value={inputValues.email} onChange={handleInputChange}/>
                                <FormText color="danger">{formErrors.email}</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Input type="number" maxLength="17" placeholder="* Phone" name="phone" id="inputPhone" value={inputValues.phone} onChange={handleInputChange}/>
                                <FormText color="danger">{formErrors.phone}</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Input placeholder="Linkedin Profile" name="linkedinProfile" id="inputLinkedinProfile" value={inputValues.linkedinProfile} onChange={handleInputChange}/>
                                <FormText color="danger">{formErrors.linkedinProfile}</FormText>
                            </FormGroup>
                            <FormGroup>
                                <CustomInput name="resume" id="InputResume" type="file" cssModule={{ 'custom-file-label': 'custom-file-label text-primary' }} label="Attach Resume…" onChange={handleInputChange} accept="application/pdf"/>
                                <FormText color="danger">{formErrors.resume}</FormText>
                            </FormGroup>
                            {
                                inputValues.linkedinProfile === '' && inputValues.resume === '' &&
                                <FormText color="danger">Please enter your LinkedIn Profile or attach a copy of your resume to apply.</FormText>
                            }
                            <FormGroup>
                                {
                                    questions && questions.map((question, index) => 
                                        <ExtraQuestion key={question.id} question={question} countQuestionsErrors={countQuestionsErrors} setCountQuestionsErrors={setCountQuestionsErrors} />
                                    )
                                }
                            </FormGroup>
                                
                            <div className="text-center">
                                <button type="submit" disabled={disabled || countQuestionsErrors > 0} style={{pointerEvents: (disabled || countQuestionsErrors > 0) ? 'none' : ''}} className="btn text-uppercase rounded-pill btn-outline-primary px-md-5 mb-3">Apply</button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </ModalBody>
            {
                job_apply.isPending && sent &&
                <LoadingIndicator/>
            }
        </Modal>
    )
}