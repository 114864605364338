import { leadershipCanvasActions } from './actions';
import { LeadershipCanvas } from './leadershipCanvas';

export const LeadershipCanvasReducer = (state = LeadershipCanvas, { payload, type }) => {

    switch (type) {
        case leadershipCanvasActions.FETCH_LEADERSHIP_CANVAS_FAILED:
            return {
                ...state,
                isPending: false,
                error: payload?.error?.data
            };

        case leadershipCanvasActions.FETCH_LEADERSHIP_CANVAS_SUCCESS:
            const { data } = payload;
            const { id, ...questionnaire } = data.questionnaire;

            return {
                ...state,
                isPending: false,
                mind_pattern: data.mind_pattern,
                thinking_talents: data.thinking_talents,
                mind_pattern_done: data.mind_pattern_done,
                thinking_talents_done: data.thinking_talents_done,
                leadership_discovery_done: data.leadership_discovery_done,
                questionnaire_id: id,
                questionnaire: questionnaire
            };

        case leadershipCanvasActions.FETCH_LEADERSHIP_CANVAS_PENDING:
            return {
                ...state,
                isPending: true
            }

        default:
            return state;
    }
}
