import { call, fork, takeEvery } from 'redux-saga/effects';

import { fetchFullReportTTPDF, fetchFullReportMPPDF, fetchCareerCanvasReportPDF, fetchPostDocument } from '../api';
import { pdfsActions } from './actions';


export function* getFullReportTT() {
    yield call(fetchFullReportTTPDF);
}

export function* getFullReportMP() {
    yield call(fetchFullReportMPPDF);
}

export function* getCareerCanvasReport() {
    yield call(fetchCareerCanvasReportPDF);
}

export function* downloadPostDocument({ payload }) {
    yield call(fetchPostDocument, payload);
}

//=====================================
//  WATCHERS
//-------------------------------------

export function* watchGetFullReportTT() {
    yield takeEvery(pdfsActions.OPEN_FULL_REPORT_TT, getFullReportTT);
}

export function* watchGetFullReportMP() {
    yield takeEvery(pdfsActions.OPEN_FULL_REPORT_MP, getFullReportMP);
}

export function* watchGetCareerCanvasReport() {
    yield takeEvery(pdfsActions.OPEN_CAREER_CANVAS_REPORT, getCareerCanvasReport);
}

export function* watchDownloadPostDocument() {
    yield takeEvery(pdfsActions.DOWNLOAD_POST_DOCUMENT, downloadPostDocument);
}

//=====================================
//  ROOT
//-------------------------------------

export const pdfsSagas = [
    fork(watchGetFullReportTT),
    fork(watchGetFullReportMP),
    fork(watchGetCareerCanvasReport),
    fork(watchDownloadPostDocument),
];
