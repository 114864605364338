export const leadershipCanvasReportActions = {
    FETCH_LEADERSHIP_CANVAS_REPORT_FAILED: 'FETCH_LEADERSHIP_CANVAS_REPORT_FAILED',
    FETCH_LEADERSHIP_CANVAS_REPORT_SUCCESS: 'FETCH_LEADERSHIP_CANVAS_REPORT_SUCCESS',
    FETCH_LEADERSHIP_CANVAS_REPORT_PENDING: 'FETCH_LEADERSHIP_CANVAS_REPORT_PENDING',
    LOAD_LEADERSHIP_CANVAS_REPORT: 'LOAD_LEADERSHIP_CANVAS_REPORT',

    fetchleadershipCanvasReportFailed: error => ({
        type: leadershipCanvasReportActions.FETCH_LEADERSHIP_CANVAS_REPORT_FAILED,
        payload: { error }
    }),

    fetchleadershipCanvasReportSuccess: (id, data) => ({
        type: leadershipCanvasReportActions.FETCH_LEADERSHIP_CANVAS_REPORT_SUCCESS,
        payload: {
            data,
            id
        }
    }),

    fetchleadershipCanvasReportPending: id => ({
        type: leadershipCanvasReportActions.FETCH_LEADERSHIP_CANVAS_REPORT_PENDING,
        payload: {
            id
        }
    }),

    loadLeadershipCanvasReport: token_id => ({
        type: leadershipCanvasReportActions.LOAD_LEADERSHIP_CANVAS_REPORT,
        payload: {
            token_id
        }
    })

};


export const leadershipCanvasReportRequestActions = {
    failed: leadershipCanvasReportActions.fetchleadershipCanvasReportFailed,
    success: leadershipCanvasReportActions.fetchleadershipCanvasReportSuccess,
    pending: leadershipCanvasReportActions.fetchleadershipCanvasReportPending
};
