import React from "react";
import { useSelector } from "react-redux";

import { career_canvas_questionnaire } from "../../../../core/constants";
import { Intro } from "../../leadershipCanvasReport";
import { getLeadershipCanvasQuestionByCode } from "../../../../core/leadership-canvas-report";

//icons
import iconProexp from "../../../../assets/img/icon-proexp.svg"
import iconProfessional from "../../../../assets/img/icon-professional-strenght-report.svg"
import iconCapability from "../../../../assets/img/icon-capability-report.svg";
import iconIndustry from "../../../../assets/img/icon-industry-report.svg"
import iconProduct from "../../../../assets/img/icon-product-report.svg"
import iconRegional from "../../../../assets/img/icon-regional-report.svg"

export const ProfessionalExperienceSection = ({ sectionCode }) => {
    const { leadership_canvas_report } = useSelector(state => state)
    const item_CS = useSelector(state => getLeadershipCanvasQuestionByCode(state, career_canvas_questionnaire.STRENGTH.questions.capability_strengths.code))
    const item_PS = useSelector(state => getLeadershipCanvasQuestionByCode(state, career_canvas_questionnaire.STRENGTH.questions.professional_strengths.code))
    const item_IS = useSelector(state => getLeadershipCanvasQuestionByCode(state, career_canvas_questionnaire.STRENGTH.questions.industry_strengths.code))
    const item_ProdS = useSelector(state => getLeadershipCanvasQuestionByCode(state, career_canvas_questionnaire.STRENGTH.questions.product_strengths.code))
    const item_RS = useSelector(state => getLeadershipCanvasQuestionByCode(state, career_canvas_questionnaire.STRENGTH.questions.regional_strengths.code))

    return (
        <>
            <Intro title={'Professional Experience'} className={`intro-1 ` + sectionCode} image={iconProexp} sectionCode={sectionCode} text={`${leadership_canvas_report?.user?.first_name} is more than just the jobs and companies they have worked in. This is a comprehensive look at the capabilities ${leadership_canvas_report?.user?.first_name} has accumulated: where their quantifiable experience is evident across industries, products, and geographic regions.`} />
            <Intro title={'Capability Strengths'} className={`intro-2 ` + sectionCode} image={iconCapability} sectionCode={sectionCode} items={item_CS?.answers} />
            <Intro title={'Professional Strengths'} className={`intro-2 ` + sectionCode} image={iconProfessional} sectionCode={sectionCode} items={item_PS?.answers} />
            <Intro title={'Industry Strengths'} className={`intro-2 ` + sectionCode} image={iconIndustry} sectionCode={sectionCode} items={item_IS?.answers} />
            <Intro title={'Product Strengths'} className={`intro-2 ` + sectionCode} image={iconProduct} sectionCode={sectionCode} items={item_ProdS?.answers} />
            <Intro title={'Regional Strengths'} className={`intro-2 ` + sectionCode} image={iconRegional} sectionCode={sectionCode} items={item_RS?.answers} />
        </>
    )
}