export function getLeadershipCanvasReport(state) {
    return state.leadership_canvas_report;
}

export function getLeadershipCanvasQuestionnaire(state) {
    return getLeadershipCanvasReport(state)?.questionnaire;
}

export function getLeadershipCanvasReportQuestionnaireByName(state, questionnaireName) {
    const questionnaire = getLeadershipCanvasReport(state)?.questionnaire && getLeadershipCanvasReport(state)?.questionnaire[questionnaireName]
    return questionnaire ? questionnaire : []
}

export function getLeadershipCanvasQuestionByCode(state, questionCode) {
    const questionnaire = getLeadershipCanvasQuestionnaire(state)
    let question = null

    if(questionnaire) {
        Object.values(questionnaire).map(listQuestions => {
            let result = Object.values(listQuestions).filter(question => question.item_code === questionCode)
            if(result?.length) {
                question = result[0]
            }
        });
    }

    return question
}