
export const jobApplyActions = {

    FETCH_JOB_APPLY_FAILED: 'FETCH_JOB_APPLY_FAILED',
    FETCH_JOB_APPLY_SUCCESS:'FETCH_JOB_APPLY_SUCCESS',
    FETCH_JOB_APPLY_PENDING:'FETCH_JOB_APPLY_PENDING',
    STORE_APPLY: 'STORE_APPLY',
  
    fetchJobApplyFailed : error => ({
      type: jobApplyActions.FETCH_JOB_APPLY_FAILED,
      payload: error
    }),
  
    fetchJobApplySuccess: (id, data) => ({
      type: jobApplyActions.FETCH_JOB_APPLY_SUCCESS,
      payload: {
        data,
        id
      }
    }),
  
    fetchJobApplyPending: id => ({
      type: jobApplyActions.FETCH_JOB_APPLY_PENDING,
      payload: {
        id
      }
    }),

    storeApply: (type, first_name, last_name, phone, email, linkedin, resume, collective_member, job_id, user_id, questions) => ({
        type: jobApplyActions.STORE_APPLY,
        payload: {
            type,
            first_name,
            last_name,
            phone,
            email,
            linkedin,
            resume,
            collective_member,
            job_id,
            user_id,
            questions
        }
      }),
  
  };
  
  export const jobApplyRequestActions = {
    failed: jobApplyActions.fetchJobApplyFailed,
    success: jobApplyActions.fetchJobApplySuccess,
    pending: jobApplyActions.fetchJobApplyPending
  };