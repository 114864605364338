import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Table, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { ResumeCompany } from  '../companyCards';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import { companyCardsListActions, getCurrentCompanyCardsList, getCompanyCardsForCurrentCompanyCardsList } from '../../../core/company-cards-list';
import { getCompanyCardFilterById } from '../../../core/company-cards-filters';
import { useDispatch, useSelector } from 'react-redux';
import LoadingIndicator from '../loading-indicator';


export const CompanyCardsResults = ({ filters, setFilters }) => {
    const dispatch = useDispatch();

    const currentCompanyCardsList = useSelector(state => getCurrentCompanyCardsList(state))
    const currentCompanyCards = useSelector(state => getCompanyCardsForCurrentCompanyCardsList(state))
    const last_filters = useSelector(state => getCompanyCardFilterById(state, 'last_filters'))

    const { current_page, total_pages, total_items } = currentCompanyCardsList || { current_page: 0, total_pages: 0, total_items: 0 }
    const isPending = !currentCompanyCards || currentCompanyCards.isPending || !currentCompanyCardsList || currentCompanyCardsList.isPending

    const goToPage = (page) => {
        dispatch(companyCardsListActions.searchCompanyCards(last_filters ? last_filters : {}, page))
    }

    const calculatePageNumber = (position) => {
        if (current_page) {
            let page = 1;
            switch (position) {
                case 1: current_page <= 3 ? page = position : current_page + 2 >= total_pages ? page = total_pages - 4 : page = current_page - 2; break;
                case 2: current_page <= 3 ? page = position : current_page + 2 >= total_pages ? page = total_pages - 3 : page = current_page - 1; break;
                case 3: current_page <= 3 ? page = position : current_page + 2 >= total_pages ? page = total_pages - 2 : page = current_page; break;
                case 4: current_page <= 3 ? page = position : current_page + 2 >= total_pages ? page = total_pages - 1 : page = current_page + 1; break;
                case 5: current_page <= 3 ? page = position : current_page + 2 >= total_pages ? page = total_pages - 0 : page = current_page + 2; break;
            }
            return total_pages > 5 ? page : position;
        }
    }

    const getCompanyByFilters = () => {
        let formatFilters = {}
        formatFilters['name'] = last_filters && last_filters.name
        filters.forEach(filter => {
            if (formatFilters[filter.type])
                formatFilters[filter.type].push(filter && { id: filter.id })
            else {
                formatFilters[filter.type] = []
                formatFilters[filter.type].push(filter && { id: filter.id })
            }
        });
        dispatch(companyCardsListActions.searchCompanyCards(formatFilters, 1))
    }

    const deleteFilter = (id, type) => {
        let filter = filters
        filter.splice(filter.map(function (item) {
            if(item.type === type) return item.id
        }).indexOf(id), 1)
        setFilters(filter)
        getCompanyByFilters()
    }

    return (
        <>
            <div className="ml-lg-5 d-flex flex-wrap align-items-center">
                {total_items > 0 && <p className="mb-0 mr-3 text-nowrap"><strong>{total_items}</strong> results</p>}
                <div className="d-flex flex-wrap results-items">
                    {filters && filters.map((filter, index) => {
                        return <Button key={index} onClick={() => deleteFilter(filter.id, filter.type)} size="sm" color="yellow" className="rounded-pill px-2 pr-4 text-left mr-3 my-2">{filter.name}</Button>
                    })
                    }
                </div>
            </div>

            <div className="p-3 bg-celeste border-top border-bottom d-none d-md-block">
                <Row>
                    <Col md="3"><h6 className="text-uppercase text-petrol font-weight-semibold small mb-0">Info Companies</h6></Col>
                </Row>
            </div>

            <hr className="border-top d-block d-md-none w-100 my-sm-2 my-md-0" />

            { !isPending && currentCompanyCards && currentCompanyCards.map((company, index) => {
                    return <ResumeCompany 
                        key={company.get('id')} 
                        {...(company).toJS()}

                    />
                })
            }

            {current_page && total_pages !== null && total_pages > 1 && //current_page && total_pages && search && total_pages > 1 &&
                <Pagination className="mt-auto d-none d-md-block pt-3" cssModule={{ 'pagination': 'pagination justify-content-center align-items-center' }}>
                    {/* <PaginationItem disabled={(current_page === 1)} ><PaginationLink className="text-nowrap" first onClick={() => goToPage(1)} children={[<><FontAwesomeIcon icon={faChevronLeft} /><FontAwesomeIcon icon={faChevronLeft} /></>]} /></PaginationItem> */}
                    <PaginationItem disabled={(current_page === 1)} ><PaginationLink previous onClick={() => goToPage(current_page - 1)} children={[<FontAwesomeIcon icon={faChevronLeft} />]} /></PaginationItem>

                    {current_page > 3 && total_pages > 5 && <>
                        <PaginationItem> <PaginationLink onClick={() => goToPage(1)}> {1} </PaginationLink></PaginationItem>
                        <PaginationItem> <PaginationLink > ... </PaginationLink></PaginationItem>
                    </>
                    }

                    {calculatePageNumber(1) <= total_pages && <PaginationItem disabled={calculatePageNumber(1) > total_pages} active={(current_page === calculatePageNumber(1))}>  <PaginationLink onClick={() => goToPage(calculatePageNumber(1))}>   {calculatePageNumber(1)}  </PaginationLink></PaginationItem>}
                    {calculatePageNumber(2) <= total_pages && <PaginationItem disabled={calculatePageNumber(2) > total_pages} active={(current_page === calculatePageNumber(2))}>  <PaginationLink onClick={() => goToPage(calculatePageNumber(2))}>   {calculatePageNumber(2)}  </PaginationLink></PaginationItem>}
                    {calculatePageNumber(3) <= total_pages && <PaginationItem disabled={calculatePageNumber(3) > total_pages} active={(current_page === calculatePageNumber(3))}>  <PaginationLink onClick={() => goToPage(calculatePageNumber(3))}>   {calculatePageNumber(3)}  </PaginationLink></PaginationItem>}
                    {calculatePageNumber(4) <= total_pages && <PaginationItem disabled={calculatePageNumber(4) > total_pages} active={(current_page === calculatePageNumber(4))}>  <PaginationLink onClick={() => goToPage(calculatePageNumber(4))}>   {calculatePageNumber(4)}  </PaginationLink></PaginationItem>}
                    {calculatePageNumber(5) <= total_pages && <PaginationItem disabled={calculatePageNumber(5) > total_pages} active={(current_page === calculatePageNumber(5))}>  <PaginationLink onClick={() => goToPage(calculatePageNumber(5))}>   {calculatePageNumber(5)}  </PaginationLink></PaginationItem>}

                    {calculatePageNumber(5) + 1 <= total_pages && <>
                        <PaginationItem> <PaginationLink > ... </PaginationLink></PaginationItem>
                        <PaginationItem> <PaginationLink onClick={() => goToPage(total_pages)}> {total_pages} </PaginationLink></PaginationItem>
                    </>
                    }

                    <PaginationItem disabled={(current_page === total_pages)} ><PaginationLink next onClick={() => goToPage(current_page + 1)} children={[<FontAwesomeIcon icon={faChevronRight} />]} /></PaginationItem>
                    {/* <PaginationItem disabled={(current_page === total_pages)} ><PaginationLink className="text-nowrap" last onClick={() => goToPage(total_pages)} children={[<><FontAwesomeIcon icon={faChevronRight} /><FontAwesomeIcon icon={faChevronRight} /></>]} /></PaginationItem> */}
                </Pagination>
            }

            {isPending &&
                <LoadingIndicator />
            }

        </>
    )
}