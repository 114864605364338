import React, { useState, useEffect } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Button, Container } from "reactstrap";
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import { Intro, BoxCareerCanvasItem } from "../index";
import { QuestionnaireReview } from "../../../pages/home/questionnaires/QuestionnaireReview";

const animationClass = {
    appearActive: 'animated fadeInUp position-relative zIndex-10',
    enter: 'animated',
    enterActive: 'animated fadeInUp position-relative zIndex-10',
    exit: 'animated',
    exitActive: 'animated fadeOutDown position-absolute w-100 top-0',
}

export const DynamicSection = ({ intro, items, goBack, history }) => {
    const [currentIndexItem, setCurrentIndexItem] = useState(history?.location?.state?.currentIndexReport !== undefined ? history?.location?.state?.currentIndexReport : null)
    const currentItem = items && items[currentIndexItem]
    const next = items.length && items[currentIndexItem + 1]
    const previous = items.length && items[currentIndexItem - 1]
    const propsIntro = (currentIndexItem !== null && items && items[currentIndexItem]?.type !== 'modal') ? items[currentIndexItem] : intro

    const nextItem = () => {
        setCurrentIndexItem(currentIndexItem + 1)
    }
    const previousItem = () => {
        setCurrentIndexItem(currentIndexItem - 1)
    }
    const resetItem = () => {
        if(currentItem?.showQuestionnaireReview !== undefined) {
            window.location.reload()
        } else {
            setCurrentIndexItem(null)
        }
    }
    const openModal = () => {
        !currentItem?.show_btn_unlock && currentItem?.openModal && currentItem.openModal()
    }

    useEffect(() => {
      if(history?.location.state?.currentIndexReport >= 0 && intro?.code == history?.location.state?.currentSection) {
        setCurrentIndexItem(history.location.state.currentIndexReport)
      }
    }, [])

    useEffect(() => {
        let newState = { ...history?.location.state, currentIndexReport: currentIndexItem }
        if(history && history?.location.state?.currentIndexReport !== currentIndexItem && intro?.code == history?.location.state?.currentSection) {
            history.replace('/career-canvas', newState)
        }
    }, [currentIndexItem])

    return (
        <>  
            <Intro {...propsIntro} className={'mb-5'} sectionCode={intro.code} goBack={currentIndexItem === null ? goBack : resetItem} />
            <Container>
            {(currentIndexItem === null || currentItem?.type == 'modal') && (!currentItem?.show_btn_unlock || !currentItem?.questionnaireView) && !currentItem?.showQuestionnaireReview &&
                <div className="d-flex justify-content-center">
                    {items && items.map((item, index) => {
                        return (
                            <BoxCareerCanvasItem {...item} open={() => { setCurrentIndexItem(index); openModal() }} collapsed={false} sectionCode={intro.code} type='report' />
                        )
                    })
                    }
                </div>
            }
            <TransitionGroup className="position-relative">
                <CSSTransition classNames={animationClass} timeout={{ enter: 1000, exit: 1000 }} key={intro.code} appear >
                    <div className="mx-5">
                        {currentItem?.show_btn_unlock && currentItem?.questionnaireView}

                        {currentItem?.showQuestionnaireReview && currentItem?.questionnaireId && <QuestionnaireReview id={currentItem?.questionnaireId} close={() => window.location.reload()} />}

                        {(!currentItem?.show_btn_unlock || !currentItem?.questionnaireView) && !currentItem?.showQuestionnaireReview && <>
                            {currentItem?.type !== 'modal' && currentItem?.report}
                            {currentItem?.type === 'modal' && currentItem.modalIsOpen && currentItem?.modal}
                            
                            {currentItem?.type !== 'modal' && !currentItem?.coming_soon &&
                                <>
                                    <div className="d-flex justify-content-between pb-5">
                                    {previous && !previous?.coming_soon && (previous.type !== 'modal' || !previous.type) && previous.show_btn_view &&
                                        <Button id='btn-previous-step' className="align-self-start rounded-pill px-3 style-3 shadow" onClick={() => previousItem()} ><FontAwesomeIcon icon={faChevronLeft} size="lg" className="mr-3" /> {items[currentIndexItem - 1]?.title}</Button>
                                    }
                                    {next && !next?.coming_soon && currentIndexItem != null && (next.type !== 'modal' || !next.type) && next.show_btn_view &&
                                        <Button id='btn-next-step' className="ml-auto rounded-pill px-3 style-3 shadow" onClick={() => nextItem()} >{items[currentIndexItem + 1]?.title} <FontAwesomeIcon icon={faChevronRight} size="lg" className="ml-3" /></Button>
                                    }
                                    </div>
                                </>
                            }
                        </>}

                    </div>
                </CSSTransition>
            </TransitionGroup>
            </Container>
        </>
    )
}