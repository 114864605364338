import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { PostPreview } from '../../../components/blogs/PostPreview';
import { blogActions } from '../../../../core/blog';

export const BlogHome = () => {
    const dispatch = useDispatch()
    const { blog } = useSelector(state => state)
    useEffect(() => {
        console.log(blog.posts_home)
        if(!blog.posts_home) dispatch(blogActions.loadBlogHome())
    }, [])

    return (
        <>
            <div className="blog-list-home">
                <div className="encabezado">
                    <div className="mb-0 pt-2 pb-1 d-flex align-items-end">
                        <h6 className="mb-0 title-underline pr-5">Content Library</h6>
                    </div>
                    <span className="border border-info d-none d-md-block"></span>
                </div>
                { blog && blog.posts_home &&
                <div className="mt-4 blog-list">
                    {
                        blog && blog.posts_home && blog.posts_home && blog.posts_home.blog_posts.map((post, index) => {
                            return <PostPreview id={post.id} key={index} openable={false} video={{ title: post.featured_video_title, text: post.featured_video_text, code: post.featured_video_code }} videos={[{ title: post.featured_video_title, text: post.featured_video_text, code: post.featured_video_code }, ...post.videos]} image={post.image} title={post.title} subtitle={post.subtitle} categories={post.categories} />
                        })
                    }
                </div>
                }
                <div className="text-center"><Link to="/content-library" className="btn btn-outline-primary text-uppercase rounded-pill d-inline-flex px-md-4 mx-auto">VIEW ALL INFO</Link></div>
            </div>
        </>

    )
}