import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Container, Media, Nav, NavItem, NavLink, TabContent, TabPane, Col, Row } from 'reactstrap';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faArrowLeft, faCircle } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { userActions } from '../../../core/users';
import { UserEditInfo } from '../../components/myProfile/UserEditInfo';
import LoadingIndicator from '../../components/loading-indicator/loading-indicator';

const animationClassTabs = {
    enter: 'animated',
    enterActive: 'animated fadeIn',
    exit: 'animated',
    exitActive: 'animated fadeOut',
}

const MyProfile = ({ user, getProfile }) => {
    let history = useHistory();
    const [activeTab, setActiveTab] = useState('info');
    const [showForm, setShowForm] = useState(false);
    const { profile, systemCheck } = useSelector(state => state);
    const isCollective = systemCheck?.data?.data?.login_version === "collective"

    const toggle = (tab) => {
        activeTab !== tab && setActiveTab(tab);
    }

    const toggleShowForm = () => {
        setShowForm(!showForm);
    }

    return (
     <>
         {user.id &&
             <div className="view overflow-hidden">
                 <div className="content w-100">
                     <Container>
                         {
                           showForm ? <UserEditInfo />
                           :
                           <>
                              <div className="bg-white my-md-4 rounded-lg pb-4 p-md-5 content-rearrenge content-view">
                                 <Row className="px-md-2">
                                    <Col md={{ size: 12 }}>
                                       <div className="position-relative d-flex justify-content-between">
                                         <a href="#" onClick={() => history.goBack()} className="icon-back"><FontAwesomeIcon icon={faArrowLeft} /></a>
                                         <h1 className="mx-4 mx-md-0 mb-md-4 text-center text-md-left">My Profile</h1>
                                       </div>
                                       <div className="d-md-flex justify-content-between mb-3">
                                         <Media className="mb-3">
                                             <Media left middle className="pr-3 cursor-pointer">
                                                 <img
                                                     className="media-object rounded-circle"
                                                     width="48"
                                                     height="48"
                                                     src={user.avatar}
                                                     alt={user.first_name}
                                                 />
                                             </Media>
                                             <Media body>
                                                 <Media heading tag="p" className="mb-0 mt-1">
                                                     {(user.first_name) ? `${profile.data.first_name ? profile.data.first_name : user && user.first_name} ${profile.data.last_name ? profile.data.last_name : user && user.last_name}` : user.name}
                                                 </Media>
                                                 <p className="text-reset line-height-normal mb-2"><span>{user.seniority && user.seniority.name}</span></p>
                                             </Media>
                                         </Media>
                                       </div>

                                       <Nav className="mb-4 text-center text-uppercase nav-xs-justified align-items-end nav-tabs-alt" tabs>
                                         <NavItem>
                                             <NavLink
                                                 className={classNames({ active: activeTab === 'info', 'px-md-5': true })}
                                                 onClick={() => toggle('info')}
                                             >
                                                 Information
                                             </NavLink>
                                         </NavItem>
                                       </Nav>

                                       <div className="content-tabs" style={{ overflow: 'hidden' }}>
                                         <SwitchTransition>
                                             <CSSTransition classNames={animationClassTabs} timeout={{ enter: 100, exit: 100 }} key={activeTab} >
                                                 <TabContent activeTab={activeTab}>
                                                     <TabPane tabId="info">
                                                         <Row className="px-md-5">
                                                            <Col md={{ size: 6 }}>
                                                              <div className="py-2 px-3 px-md-2">
                                                                  <p className="mb-0 text-truncate">
                                                                      <a href={'mailto:' + user.email} target="_blank" className="text-reset">
                                                                          <i className="mr-3 text-primary"><FontAwesomeIcon icon={faEnvelope} /></i>
                                                                          {user.email}
                                                                      </a>
                                                                  </p>
                                                              </div>
                                                            </Col>
                                                            <Col md={{ size: 6 }}>
                                                               <div className="py-2 px-3 px-md-2">
                                                                  <p className="mb-0 text-truncate">
                                                                      <i className="mr-3 text-primary">
                                                                          <FontAwesomeIcon icon={faUser} />
                                                                      </i>
                                                                      <span className="font-weight-light mr-1">First Name</span>
                                                                      {profile.data.first_name ? profile.data.first_name : user.first_name}
                                                                  </p>
                                                                  <p className="mb-0 text-truncate">
                                                                      <i className="mr-3 text-primary invisible">
                                                                          <FontAwesomeIcon icon={faUser} />
                                                                      </i>
                                                                      <span className="font-weight-light mr-1">Last Name</span>
                                                                      {profile.data.last_name ?
                                                                          profile.data.last_name :
                                                                          user.last_name
                                                                      }
                                                                  </p>
                                                               </div>
                                                            </Col>
                                                         </Row>

                                                         {!isCollective && <>
                                                            <Row className="px-md-5">
                                                                <Col md={{ size: 6 }}>
                                                                <div className="pt-2 px-3 px-md-2 border-top">
                                                                    <FontAwesomeIcon icon={faCircle} size="xs" color="#78AFBA" className="mr-3 mt-1 align-top" />
                                                                    <p className="mb-0 text-truncate d-inline-block">
                                                                        <span className="font-weight-light">Seniority</span><br/>
                                                                        {user?.seniority?.name}
                                                                    </p>
                                                                </div>
                                                                </Col>
                                                            </Row>
                                                         </>}

                                                         {isCollective && <>
                                                         <Row className="px-md-5">
                                                            <Col md={{ size: 6 }}>
                                                              <div className="pt-2 px-3 px-md-2 border-top">
                                                                  <FontAwesomeIcon icon={faCircle} size="xs" color="#78AFBA" className="mr-3 mt-1 align-top" />
                                                                  <p className="mb-0 text-truncate d-inline-block">
                                                                      <span className="font-weight-light">You started your professional career in</span><br/>
                                                                      {profile?.data?.career_start_year || user?.career_start_year}
                                                                  </p>
                                                              </div>
                                                            </Col>
                                                            <Col md={{ size: 6 }}>
                                                               <div className="pt-2 px-3 px-md-2 border-top">
                                                                  <FontAwesomeIcon icon={faCircle} size="xs" color="#78AFBA" className="mr-3 mt-1 align-top" />
                                                                  <p className="mb-0 text-truncate d-inline-block">
                                                                      <span className="font-weight-light">Your current career level is</span><br/>
                                                                      {profile.data.career_level?.name || user.career_level?.name}
                                                                  </p>
                                                               </div>
                                                            </Col>
                                                         </Row>
                                                         <Row className="px-md-5">
                                                            <Col md={{ size: 6 }}>
                                                              <div className="pt-2 px-3 px-md-2 border-top">
                                                              <FontAwesomeIcon icon={faCircle} size="xs" color="#78AFBA" className="mr-3 mt-1 align-top" />
                                                               <p className="mb-0 text-truncate d-inline-block">
                                                                   <span className="font-weight-light">Professional Role</span><br/>
                                                                   {profile?.data?.professional_role?.name || user?.professional_role?.name}
                                                               </p>
                                                              </div>
                                                            </Col>
                                                            <Col md={{ size: 6 }}>
                                                               <div className="pt-2 px-3 px-md-2 border-top">
                                                               <FontAwesomeIcon icon={faCircle} size="xs" color="#78AFBA" className="mr-3 mt-1 align-top" />
                                                                <p className="mb-0 text-truncate d-inline-block">
                                                                    <span className="font-weight-light">Your reason for joining the collective</span><br/>
                                                                    {profile?.data?.reason_joining?.name || user?.reason_joining?.name}
                                                                </p>
                                                               </div>
                                                            </Col>
                                                         </Row>
                                                         <Row className="px-md-5">
                                                            <Col md={{ size: 6 }}>
                                                              <div className="pt-2 px-3 px-md-2 border-top">
                                                              <FontAwesomeIcon icon={faCircle} size="xs" color="#78AFBA" className="mr-3 mt-1 align-top" />
                                                               <p className="mb-0 text-truncate d-inline-block">
                                                                   <span className="font-weight-light">Do you identify as BIPOC?</span><br/>
                                                                   {profile?.data?.identify_bipoc?.identify_bipoc_desc || user?.identify_bipoc?.identify_bipoc_desc}
                                                               </p>
                                                              </div>
                                                            </Col>
                                                            <Col md={{ size: 6 }}>
                                                               <div className="pt-2 px-3 px-md-2 border-top">
                                                               <FontAwesomeIcon icon={faCircle} size="xs" color="#78AFBA" className="mr-3 mt-1 align-top" />
                                                                <p className="mb-0 text-truncate d-inline-block">
                                                                    <span className="font-weight-light">How did you hear about us?</span><br/>
                                                                    {profile?.data?.hear_about?.name || user?.hear_about?.name}
                                                                </p>
                                                               </div>
                                                            </Col>
                                                         </Row>
                                                         <Row className="px-md-5">
                                                            <Col md={{ size: 6 }}>
                                                              <div className="pt-2 px-3 px-md-2 border-top">
                                                              <FontAwesomeIcon icon={faCircle} size="xs" color="#78AFBA" className="mr-3 mt-1 align-top" />
                                                               <p className="mb-0 text-truncate d-inline-block">
                                                                   <span className="font-weight-light">Country</span><br/>
                                                                   {profile?.data?.country?.name || user?.country?.name}
                                                               </p>
                                                              </div>
                                                            </Col>
                                                            <Col md={{ size: 6 }}>
                                                               <div className="pt-2 px-3 px-md-2 border-top">
                                                               <FontAwesomeIcon icon={faCircle} size="xs" color="#78AFBA" className="mr-3 mt-1 align-top" />
                                                                <p className="mb-0 text-truncate d-inline-block">
                                                                    <span className="font-weight-light">State</span><br/>
                                                                    {profile?.data?.state?.name || user?.state?.name || profile?.data?.state || user?.state}
                                                                </p>
                                                               </div>
                                                            </Col>
                                                         </Row>
                                                         </>
                                                         }


                                                         <hr className="d-md-none mt-0 mb-3" />
                                                         <div className='d-flex justify-content-center'>
                                                             <button
                                                                 className='btn btn-primary text-uppercase rounded-pill px-md-5 mt-3 btn-inline-block'
                                                                 onClick={toggleShowForm}
                                                             >
                                                                EDIT MY PROFILE
                                                             </button>
                                                         </div>
                                                     </TabPane>

                                                 </TabContent>
                                             </CSSTransition>
                                         </SwitchTransition>
                                       </div>

                                     </Col>
                                   </Row>
                               </div>
                           </>
                         }
                     </Container>
                 </div>
             </div>}
         {
             (user.isPending || profile.isPending) &&
             <LoadingIndicator />
         }
     </>
    )
}

const mapStateToProps = (state, props) => {
    const { user } = state;
    return {
        user
    }
};

const mapDispatchToProps = {
    getProfile: userActions.GET_PROFILE
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MyProfile);
