
export const jobsOutstandingActions = {

    FETCH_JOBS_OUTSTANDING_FAILED: 'FETCH_JOBS_OUTSTANDING_FAILED',
    FETCH_JOBS_OUTSTANDING_SUCCESS:'FETCH_JOBS_OUTSTANDING_SUCCESS',
    FETCH_JOBS_OUTSTANDING_PENDING:'FETCH_JOBS_OUTSTANDING_PENDING',
    LOAD_JOBS_OUTSTANDING: 'LOAD_JOBS_OUTSTANDING',
  
    fetchJobsOutstandingFailed : error => ({
      type: jobsOutstandingActions.FETCH_JOBS_OUTSTANDING_FAILED,
      payload: error
    }),
  
    fetchJobsOutstandingSuccess: (id, data) => ({
      type: jobsOutstandingActions.FETCH_JOBS_OUTSTANDING_SUCCESS,
      payload: {
        data,
        id
      }
    }),
  
    fetchJobsOutstandingPending: id => ({
      type: jobsOutstandingActions.FETCH_JOBS_OUTSTANDING_PENDING,
      payload: {
        id
      }
    }),
  
    loadJobsOutstanding: () => ({
      type: jobsOutstandingActions.LOAD_JOBS_OUTSTANDING
    }),
  };
  
  export const jobsOutstandingRequestActions = {
    failed: jobsOutstandingActions.fetchJobsOutstandingFailed,
    success: jobsOutstandingActions.fetchJobsOutstandingSuccess,
    pending: jobsOutstandingActions.fetchJobsOutstandingPending
  };