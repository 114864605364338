
export const eventOutstandingActions = {

    FETCH_EVENT_OUTSTANDING_FAILED: 'FETCH_EVENT_OUTSTANDING_FAILED',
    FETCH_EVENT_OUTSTANDING_SUCCESS:'FETCH_EVENT_OUTSTANDING_SUCCESS',
    FETCH_EVENT_OUTSTANDING_PENDING:'FETCH_EVENT_OUTSTANDING_PENDING',
    LOAD_EVENT_OUTSTANDING: 'LOAD_EVENT_OUTSTANDING',
  
    fetchEventOutstandingFailed : error => ({
      type: eventOutstandingActions.FETCH_EVENT_OUTSTANDING_FAILED,
      payload: error
    }),
  
    fetchEventOutstandingSuccess: (id, data) => ({
      type: eventOutstandingActions.FETCH_EVENT_OUTSTANDING_SUCCESS,
      payload: {
        data,
        id
      }
    }),
  
    fetchEventOutstandingPending: id => ({
      type: eventOutstandingActions.FETCH_EVENT_OUTSTANDING_PENDING,
      payload: {
        id
      }
    }),
  
    loadEventOutstanding: () => ({
      type: eventOutstandingActions.LOAD_EVENT_OUTSTANDING
    }),
  };
  
  export const eventOutstandingRequestActions = {
    failed: eventOutstandingActions.fetchEventOutstandingFailed,
    success: eventOutstandingActions.fetchEventOutstandingSuccess,
    pending: eventOutstandingActions.fetchEventOutstandingPending
  };