import { List, Record, Map } from 'immutable';

const stages = new List([
    { name: 'Pre-Series A', id: 'Pre-Series A' },
    { name: 'Series A', id: 'Series A' },
    { name: 'Series B', id: 'Series B' },
    { name: 'Series C', id: 'Series C' },
    { name: 'Late Stage (Public)', id: 'Late Stage (Public)' },
    { name: 'Late Stage (Private)', id: 'Late Stage (Private)' },
])

export const companyCardsFilters = new Map({
  id: null,
  isNew: true,
  isPending: false,
  certifications: new List(),
  industries: new List(),
  stages: stages,
  last_filters: null,
  error: null
});
