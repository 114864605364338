import React, { useState } from 'react';
import { Container, Row, Col, Media, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import LoginForm from './login-form';
import collaborative from '../../../assets/img/collaborative.svg';
import { HomeAuthFooter } from './HomeAuthFooter';
import classnames from 'classnames';
import check_primary from '../../../assets/img/blue-dot.svg'
import { useSelector } from 'react-redux';
import { Videos } from '../home/Videos';


export const HomeAuthPage = () => {
    const history = useHistory();
    const { systemCheck } = useSelector(state => state);

    const [activeTab, setActiveTab] = useState('1');


    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const { videos } = useSelector(state => state);


    return (
        <div className="view overflow-hidden bg-white content-view-full w-100">
            <Container fluid={true}>
                <Row className="d-md-none">
                    <Col>
                        <div>
                            <Nav tabs>
                                <NavItem className="flex-fill text-center">
                                    <NavLink
                                        className={classnames({ active: activeTab === '1' }, 'h6 mb-0')}
                                        onClick={() => { toggle('1'); }}
                                    >
                                        ORIENTATION
                                    </NavLink>
                                </NavItem>
                                <NavItem className="flex-fill text-center">
                                    <NavLink
                                        className={classnames({ active: activeTab === '2' }, 'h6 mb-0')}
                                        onClick={() => { toggle('2'); }}
                                    >
                                        GET STARTED
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1" className="pt-3">
                                    {systemCheck.data.data && systemCheck.data.data.login_version === "traditional" &&
                                        <Row className="traditional-version">
                                            <Col sm="12">
                                                <div>
                                                    <div className="d-flex align-items-center mb-3">
                                                        <img src={collaborative} width="90" className="mr-3" alt="collaborative" />
                                                        <h2 className="pt-2 rubik">Welcome</h2>
                                                    </div>
                                                    <p><b>An interactive tool for thinking well with those who think differently.</b></p>
                                                    <p>The Collaborative Intelligence (CQ) Exploration Tool is a 20-minute discovery for improved communication, innovation, problem-solving and team dynamics.</p>
                                                </div>
                                                <Videos />
                                                <div>
                                                    <p className="pt-2"><b>The initial assessment will help you discover:</b></p>
                                                    <Media>
                                                        <Media left className="mr-2">
                                                            <Media object src={check_primary} width="20" />
                                                        </Media>
                                                        <Media body>
                                                            <p><b>Thinking Talents</b><br />The ways you think that make you unique.</p>
                                                        </Media>
                                                    </Media>
                                                    <Media>
                                                        <Media left className="mr-2">
                                                            <Media object src={check_primary} width="20" />
                                                        </Media>
                                                        <Media body>
                                                            <p><b>Mind Patterns</b><br />What your brain needs to excel at learning.</p>
                                                        </Media>
                                                    </Media>
                                                </div>
                                            </Col>
                                        </Row>
                                    }

                                    {systemCheck.data.data && systemCheck.data.data.login_version === "collective" &&
                                        <Row className="collective-version">
                                            <Col sm="12">
                                                <div>
                                                    <div className="d-flex align-items-center mb-3">
                                                        <img src={collaborative} width="90" className="mr-3" alt="collaborative" />
                                                        <h2 className="pt-2 rubik">Welcome</h2>
                                                    </div>
                                                    <p><b>Thanks so much for your interest in joining the True Advance Collective - True Advance’s dynamic social platform for professionals in pursuit of meaningful careers. Our goal is to put you in the driver’s seat of your career. We do that by giving you access to resources that unlock your growth potential and introducing you to opportunities to advance your career to the next stage.</b></p>
                                                    <p>This site hosts Collective member resources, including details on upcoming events, access to powerful self-discovery tools, and a few short videos that help you better understand how to get the most out of your membership.</p>
                                                </div>
                                                <Videos />
                                                <div>
                                                    <div className="encabezado mb-3">
                                                        <div className="mb-3 mb-md-0 pt-md-2 pb-md-1 d-flex align-items-center">
                                                            <h6 className="font-weight-normal mb-0 title-underline">True Advance Code of Conduct</h6>
                                                        </div>
                                                        <span className="border border-info d-none d-sm-none d-md-block"></span>
                                                    </div>
                                                    <p>True Advance is an actively anti-racist, public benefit corporation with a mandate to put underrepresented talent in the driver’s seat of their careers. For the True Advance Collective to successfully operate as a diverse and supportive community - powered by generosity and the spirit of collaboration - it is essential that all members operate with integrity. During your participation in the True Advance Collective, you agree not to engage in any activities of a questionable moral character that may adversely impact the experience of other Members or the reputation of True Advance. True Advance reserves the right and shall have sole discretion to terminate any Member’s participation in the True Advance Collective due to any violation of this rule.</p>
                                                    <p>We appreciate your interest in trusting True Advance to support your pursuit of an extraordinary life’s work through a True Advance Collective Membership.</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    }
                                </TabPane>
                                <TabPane tabId="2" className="pt-4">
                                    <Row>
                                        <Col sm="12">
                                            <div className="bg-white rounded pt-4 pb-1">
                                                <LoginForm history={history} />
                                            </div>
                                            <HomeAuthFooter />
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </div>
                    </Col>
                </Row>


                <Row className="d-none d-md-flex align-items-stretch">
                    <Col xs="7" className="bg-welcome-dk d-flex align-items-center">

                        {systemCheck.data.data && systemCheck.data.data.login_version === "traditional" &&
                            <div className="traditional-version mt-5 pt-3 col-welcome-dk pl-xl-5 ml-auto">
                                <h3 className="mb-3 rubik">Collaborative<br />Intelligence (CQ) Inventory</h3>
                                <div className="d-flex align-items-start mb-3">
                                    <img src={collaborative} width="100" className="mb-2 mr-3 mt-2" alt="Collaborative" />
                                    <div className="mt-2">
                                        <p><b>An interactive tool for thinking well with those who think differently.</b><br />The Collaborative Intelligence (CQ) Exploration Tool is a 20-minute discovery for improved communication, innovation, problem-solving and team dynamics.</p>
                                    </div>
                                </div>
                                {videos && videos.data && videos.data.length > 0 &&
                                    <>
                                        <div className="encabezado mb-3">
                                            <div className="mb-3 mb-md-0 pt-md-2 pb-md-1 d-flex align-items-center">
                                                <h6 className="font-weight-normal d-none d-sm-none d-md-block mb-0 title-underline">Welcome Videos</h6>
                                            </div>
                                            <span className="border border-info d-none d-sm-none d-md-block"></span>
                                        </div>
                                        <Videos />
                                    </>
                                }
                                <div className="encabezado mb-3">
                                    <div className="mb-3 mb-md-0 pt-md-2 pb-md-1 d-flex align-items-center">
                                        <h6 className="font-weight-normal d-none d-sm-none d-md-block mb-0 title-underline">The initial assessment will help you discover:</h6>
                                    </div>
                                    <span className="border border-info d-none d-sm-none d-md-block"></span>
                                </div>
                                <Row>
                                    <Col xs="12">
                                        <Media>
                                            <Media left className="mr-2">
                                                <Media object src={check_primary} width="20" />
                                            </Media>
                                            <Media body>
                                                <p><b>Thinking Talents</b><br />The ways you think that make you unique.</p>
                                            </Media>
                                        </Media>
                                    </Col>
                                    <Col xs="12">
                                        <Media>
                                            <Media left className="mr-2">
                                                <Media object src={check_primary} width="20" />
                                            </Media>
                                            <Media body>
                                                <p><b>Mind Patterns</b><br />What your brain needs to excel at learning.</p>
                                            </Media>
                                        </Media>
                                    </Col>
                                </Row>
                            </div>

                        }

                        {systemCheck.data.data && systemCheck.data.data.login_version === "collective" &&
                            <>
                                <div className="collective-version mt-5 pt-3 col-welcome-dk pl-xl-5 ml-auto">
                                    <h3 className="mb-3 rubik">Welcome</h3>
                                    <div className="d-flex align-items-start mb-3">
                                        <img src={collaborative} width="100" className="mb-2 mr-3 mt-2" alt="collaborative" />
                                        <div>
                                            <p><b>Thanks so much for your interest in joining the True Advance Collective - True Advance’s dynamic social platform for professionals in pursuit of meaningful careers. Our goal is to put you in the driver’s seat of your career. We do that by giving you access to resources that unlock your growth potential and introducing you to opportunities to advance your career to the next stage.</b></p>
                                            <p>This site hosts Collective member resources, including details on upcoming events, access to powerful self-discovery tools, and a few short videos that help you better understand how to get the most out of your membership.</p>
                                        </div>
                                    </div>
                                    {videos && videos.data && videos.data.length > 0 &&
                                        <div className="videos-list-welcome">
                                            <div className="encabezado mb-3">
                                                <div className="mb-3 mb-md-0 pt-md-2 pb-md-1 d-flex align-items-center">
                                                    <h6 className="font-weight-normal d-none d-sm-none d-md-block mb-0 title-underline">Welcome Videos</h6>
                                                </div>
                                                <span className="border border-info d-none d-sm-none d-md-block"></span>
                                            </div>
                                            <Videos />
                                        </div>
                                    }

                                    <div>
                                        <div className="encabezado mb-3">
                                            <div className="mb-3 mb-md-0 pt-md-2 pb-md-1 d-flex align-items-center">
                                                <h6 className="font-weight-normal d-none d-sm-none d-md-block mb-0 title-underline">True Advance Code of Conduct</h6>
                                            </div>
                                            <span className="border border-info d-none d-sm-none d-md-block"></span>
                                        </div>
                                        <p>True Advance is an actively anti-racist, public benefit corporation with a mandate to put underrepresented talent in the driver’s seat of their careers. For the True Advance Collective to successfully operate as a diverse and supportive community - powered by generosity and the spirit of collaboration - it is essential that all members operate with integrity. During your participation in the True Advance Collective, you agree not to engage in any activities of a questionable moral character that may adversely impact the experience of other Members or the reputation of True Advance. True Advance reserves the right and shall have sole discretion to terminate any Member’s participation in the True Advance Collective due to any violation of this rule.</p>
                                        <p>We appreciate your interest in trusting True Advance to support your pursuit of an extraordinary life’s work through a True Advance Collective Membership.</p>
                                    </div>
                                </div>
                            </>
                        }
                    </Col>

                    <Col xs="5" className="bg-white col-login-form d-flex justify-content-start justify-content-xl-center flex-column">
                        <div className="mt-5 mx-auto pt-3 w-100">
                            <LoginForm history={history} />
                        </div>

                        <HomeAuthFooter />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
