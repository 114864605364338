import { call, fork, takeEvery } from 'redux-saga/effects';
import { fetchJobs } from '../api';
import { jobsActions } from './actions';

export function* jobsLoad() {
    yield call(fetchJobs);
}

//Watchers
export function* watchJobsLoad() {
    yield takeEvery(jobsActions.LOAD_JOBS, jobsLoad)
}

//Root
export const jobsSagas = [
    fork(watchJobsLoad),
]
