import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Card, Container } from "reactstrap"
import { faArrowLeft, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertToSlug } from "../../../core/helper";
import iconoThinkingTalent from "../../../assets/img/icon-thinking-talent.svg";
import iconoMindPattern from "../../../assets/img/icono-mind-pattern.svg";

export const Intro = ({ title, text, items, goBack, sectionCode, image, className }) => {
    var eFocus = null
    useEffect(() => {
        !window.location.pathname.includes('/career-canvas-report/') && eFocus && eFocus.scrollIntoView(true);
    })

    return (
        <>
        <div ref={(el) => { eFocus = el; }}></div>
        <div id={`intro-section-${sectionCode}`} className={`bg-section py-4 shadow ${className} intro-section-${sectionCode}`}>
            <Container>
                {goBack &&
                    <a href="" className="text-gray-dark" onClick={(e) => { e.preventDefault(); goBack() }} style={{fontSize: '23px'}}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </a>
                }
                <Row className={`align-items-center px-3`}>
                    {title &&
                    <Col md="12" className="mb-3 intro-titulo">
                        <Row>
                            <Col md={3}>
                            <h5 className="text-uppercase text-center mx-4">{title}</h5>
                            </Col>
                        </Row>
                    </Col>
                    }
                    <Col md={3}>
                        <div className={`box-passport-item-${title && convertToSlug(title)} p-3 mx-auto text-center`}>
                            <img src={image} className="img-fluid" />
                        </div>
                    </Col>
                    <Col md={8}>
                        <div className="rounded-lg card-celeste p-3">
                            {items && <ul className={`${text ? '' : 'mb-0'} pl-3`}>
                                { items && items.map(item => {
                                    return ( <li>
                                        {item?.leadership_output ? <p className="mb-0" dangerouslySetInnerHTML={{ __html: item.leadership_output}}></p> : item?.title}
                                    </li> )
                                }) }
                            </ul>}
                            {text && <p className="mb-0" dangerouslySetInnerHTML={{ __html: text }}></p> }
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        </>
    )
}