
export const blogActions = {

  FETCH_BLOG_FAILED: 'FETCH_BLOG_FAILED',
  FETCH_BLOG_SUCCESS: 'FETCH_BLOG_SUCCESS',
  FETCH_BLOG_PENDING: 'FETCH_BLOG_PENDING',
  LOAD_BLOG_HOME: 'LOAD_BLOG_HOME',
  SEARCH_POSTS: 'SEARCH_POSTS',
  FETCH_BLOG_POST: 'FETCH_BLOG_POST',
  FETCH_POSTS_BY_CATEGORY_SLUG: 'FETCH_POSTS_BY_CATEGORY_SLUG',

  fetchBlogFailed: error => ({
    type: blogActions.FETCH_BLOG_FAILED,
    payload: error
  }),

  fetchBlogSuccess: (id, data) => ({
    type: blogActions.FETCH_BLOG_SUCCESS,
    payload: {
      data,
      id
    }
  }),

  fetchBlogPending: () => ({
    type: blogActions.FETCH_BLOG_PENDING,
  }),

  loadBlogHome: () => ({
    type: blogActions.LOAD_BLOG_HOME
  }),

  searchPosts: (filters = null, page = 1) => ({
    type: blogActions.SEARCH_POSTS,
    payload: {
        categories: filters ? filters.category : [],
        content_type: filters ? filters.content_type : [],
        search: filters ? filters.search : '',
        page: page
    }
  }),

  fetchPost: (id) => ({
      type: blogActions.FETCH_BLOG_POST,
      payload: {
          id
      }
  }),

  fetchPostsByCategorySlug: (slug) => ({
    type: blogActions.FETCH_POSTS_BY_CATEGORY_SLUG,
    payload: {
        slug
    }
  })

};


export const blogRequestActions = {
  failed: blogActions.fetchBlogFailed,
  success: blogActions.fetchBlogSuccess,
  pending: blogActions.fetchBlogPending
};
