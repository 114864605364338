import React from 'react';
import { Row, Col, Badge, Media } from 'reactstrap';
import { ThumbnailVideo } from '../videos/ThumbnailVideo';
import ReactPlayer from 'react-player/youtube'

export const PostPreview = (props) => {
    const { id, video, title, subtitle, image, videos, categories, openable, last } = props

    return (
        <>
            <Media href={`/front/content-library/post/${id}`} className="text-reset text-decoration-none">
                {(video.code || image) &&
                    <Media left className="mr-2">
                        {video.code && video.code !== '' &&
                            <div className="embed-responsive embed-responsive-16by9 rounded-lg">
                            <ReactPlayer className="embed-responsive-item" url={`http://www.youtube.com/embed/${video.code}`}
                                width='100%'
                                height='100%'
                                playing={false}
                                light
                                playIcon=''
                                muted
                                config={{
                                    youtube: {
                                        playerVars: { showinfo: 0, embedOptions: 0, disablekb: 1, frameborder: 0, rel: 0 }
                                    },
                                }} />
                            </div>
                        }
                        {(!video.code || video.code === '') && image &&
                            <img src={image} alt={'image - ' + title} className="w-100 rounded-lg" />
                        }
                    </Media>
                }
                <Media body className="pl-2">
                    {categories && <div className="d-flex align-items-center flex-wrap mb-1">
                        {categories.map((category, index) => {
                            return (
                                <>
                                    <Badge onClick={(e) => {e.preventDefault(); window.location.href = `/front/content-library/${category.slug}`}} color="primary" className="text-capitalize px-2 py-1 rounded-pill mb-1" key={category.id}>{category.name}</Badge>
                                    {index < categories.length - 1 && <span className="p-1"> / </span>}
                                </>
                            )
                        })}
                    </div>
                    }
                    <div>
                        <Media heading tag="h2">{title}</Media>
                        {subtitle && <p className="mb-0" dangerouslySetInnerHTML={{ __html: subtitle}}></p>}
                    </div>
                </Media>
            </Media>

            { !last && <div className="border border-muted d-block my-3"></div>}
        </>
    )
}