import { call, fork, takeLatest, take } from 'redux-saga/effects';

import session from '../session'

import { fetchLogin, fetchUser, fetchLogout, fetchCurrentUser, setAcceptTerms, checkPasswordAcceptedByPolitics, refer, fetchAssociatedAccounts } from '../api';
import { userActions } from './actions';


export function* loadUser({ payload }) {
  const { userId, data } = payload;
  yield call(fetchLogin, userId, data);
  data.history.push('/home');
}

export function* currentUser() {
  yield call(fetchCurrentUser);
}

export function* getProfile({ payload }) {
  if (session.getAuthenticated()) {
    yield call(fetchUser);
  }
}

export function* logout({ payload }) {
  const { data } = payload;
  yield call(fetchLogout);
  yield call(session.deleteSession);
  data.history.push('/welcome');
  // window.location.href = "/front/welcome";
}

export function* acceptTerms() {
    yield call(setAcceptTerms);
}

export function* checkPassword({ payload }) {
    const { password } = payload;
    yield call(checkPasswordAcceptedByPolitics, password);
}

export function* referByEmail({ payload }) {
    const { email } = payload;
    yield call(refer, email);
}

export function* getAssociatedAccounts({ payload }) {console.log(payload)
    const { email } = payload;
    yield call(fetchAssociatedAccounts, email);
}


//=====================================
//  WATCHERS
//-------------------------------------

export function* watchLoadUser() {
  yield takeLatest(userActions.LOAD_USER, loadUser);
}

export function* watchGetProfiler() {
  yield takeLatest(userActions.GET_PROFILE, getProfile);
}

export function* watchCurrentUser() {
  yield takeLatest(userActions.CURRENT_USER, currentUser);
}


export function* watchFetchUserSucces() {
  while (true) {
    const { payload } = yield take(userActions.FETCH_USER_SUCCESS);
    if(payload.data.email) {
        session.setAuthenticated(1);
    }
  }
}

export function* watchFetchUserFailed() {
  while (true) {

    const { payload } = yield take(userActions.FETCH_USER_FAILED);

    if (!payload.success && payload.message === "Unauthorized") {
      session.deleteSession();
    }
  }
}


export function* watchFetchUserLogout() {
  yield takeLatest(userActions.LOGOUT, logout);
}

export function* watchAcceptTerms() {
    yield takeLatest(userActions.ACCEPT_TERMS, acceptTerms);
}

export function* watchCheckPasswordAcceptedByPolitics() {
    yield takeLatest(userActions.CHECK_PASSWORD_ACCEPTED_BY_POLITICS, checkPassword);
}

export function* watchRefer() {
    yield takeLatest(userActions.REFER, referByEmail);
}

export function* watchFetchAssociatedAccounts() {
    yield takeLatest(userActions.FETCH_ASSOCIATED_ACCOUNTS, getAssociatedAccounts);
}

//=====================================
//  ROOT
//-------------------------------------

export const userSagas = [
  fork(watchLoadUser),
  fork(watchFetchUserSucces),
  fork(watchFetchUserFailed),
  fork(watchGetProfiler),
  fork(watchFetchUserLogout),
  fork(watchCurrentUser),
  fork(watchAcceptTerms),
  fork(watchCheckPasswordAcceptedByPolitics),
  fork(watchRefer),
  fork(watchFetchAssociatedAccounts),
];
