import { leadershipCanvasReportActions } from './actions';
import { LeadershipCanvasReport } from './leadershipCanvasReport';

export const LeadershipCanvasReportReducer = (state = LeadershipCanvasReport, { payload, type }) => {

    switch (type) {
        case leadershipCanvasReportActions.FETCH_LEADERSHIP_CANVAS_REPORT_FAILED:
            return {
                ...state,
                isPending: false,
                error: payload.error.data
            };

        case leadershipCanvasReportActions.FETCH_LEADERSHIP_CANVAS_REPORT_SUCCESS:
            const { data } = payload;
            const { id, ...questionnaire } = data.questionnaire;

            return {
                ...state,
                isPending: false,
                user: data.user,
                mind_pattern: data.mind_pattern,
                thinking_talents: data.thinking_talents,
                mind_pattern_done: data.mind_pattern_done,
                thinking_talents_done: data.thinking_talents_done,
                leadership_discovery_done: data.leadership_discovery_done,
                questionnaire_id: id,
                questionnaire: questionnaire
            };

        case leadershipCanvasReportActions.FETCH_LEADERSHIP_CANVAS_REPORT_PENDING:
            return {
                ...state,
                isPending: true
            }

        default:
            return state;
    }
}
