import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, FormGroup, Input, Label, FormText, Col } from "reactstrap";

import { userActions } from "../../../core/users";
import history from "../../../core/history";

const emailRegex = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

export const Referral = () => {
    const dispatch = useDispatch()
    const currentUser = useSelector(state => state.user) 
    const [email, setEmail] = useState()
    const [error, setError] = useState()

    useEffect(() => {
        if (emailRegex.test(email) || !email) {
            setError('')
        } else {
            setError('Invalid email address')
        }
    }, [email])

    useEffect(() => {
      if(!currentUser.isPending && currentUser.referal_link) {console.log(currentUser.referal_link)
        if(!history.back()) {
            history.push('/front/home')
            history.go(0)
        }
      }
    }, [currentUser])
    

    const onSubmitRefer = (e) => {
        e.preventDefault()
        dispatch(userActions.refer(email))
    }

    return (
        <div className="d-flex align-items-center justify-content-center w-100" style={{ height: '80vh' }}>
            <Form onSubmit={onSubmitRefer} className="mx-5">
                <FormGroup>
                    <Label> Please enter the email of the person you want to refer: </Label>
                    <Input type="email" placeholder="Email" name="email" id="email" className="rounded-pill" invalid={error} onChange={({ target }) => { setEmail(target.value) }} />
                    <FormText color="danger">{error}</FormText>
                </FormGroup>
                <div className="text-center">
                    <button disabled={error || !email} type="submit" className="btn text-uppercase rounded-pill btn-outline-primary px-md-5 mb-3">Send Invitation</button>
                </div>
            </Form>
        </div>
    )
}