import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { career_canvas_questionnaire } from "../../../../core/constants";
import { DynamicSection } from "./DynamicSection";
import { getLeadershipCanvasQuestionByCode, getStringFromAnswers } from "../../../../core/leadership-canvas";

//icons
import iconProexp from "../../../../assets/img/icon-proexp.svg"
import iconProfessional from "../../../../assets/img/icon-professional.svg"
import iconCapability from "../../../../assets/img/icon-capability.svg";
import iconIndustry from "../../../../assets/img/icon-industrys.svg"
import iconProduct from "../../../../assets/img/icon-product.svg"
import iconRegional from "../../../../assets/img/icon-regional.svg"

//reports
import { GenericModalReport } from "../index"

//questionnaireView
import { QuestionnairePage } from "../../../pages/home/questionnaires/QuestionnairePage";

export const ProfessionalExperienceSection = ({ goBack, sectionCode }) => {
    const { user, leadershipCanvas } = useSelector(state => state)
    const item_CS = useSelector(state => getLeadershipCanvasQuestionByCode(state, career_canvas_questionnaire.STRENGTH.questions.capability_strengths.code))
    const item_PS = useSelector(state => getLeadershipCanvasQuestionByCode(state, career_canvas_questionnaire.STRENGTH.questions.professional_strengths.code))
    const item_IS = useSelector(state => getLeadershipCanvasQuestionByCode(state, career_canvas_questionnaire.STRENGTH.questions.industry_strengths.code))
    const item_ProdS = useSelector(state => getLeadershipCanvasQuestionByCode(state, career_canvas_questionnaire.STRENGTH.questions.product_strengths.code))
    const item_RS = useSelector(state => getLeadershipCanvasQuestionByCode(state, career_canvas_questionnaire.STRENGTH.questions.regional_strengths.code))
    const [ isOpenModal, setIsOpenModal ] = useState(true)
    const [ showQuestionnaireReview, setShowQuestionnaireReview ] = useState(false)
    const toggle = () => {
        setIsOpenModal(!isOpenModal)
    }
    const open = () => {
        setIsOpenModal(true)
    }
    const intro = {
        code: sectionCode,
        text: `Every professional has a wholly unique set of strengths that are defined by their past personal and professional experiences. Throughout your career, you accumulate these strengths through every job, project, task, and interaction. In these sections, we help you self-identify your strengths across these six areas: categories, capabilities, professions, industries, products, and regions. At True Advance, we encourage folks to consider an area a strength if they feel reasonably qualified to offer advice in that area to someone their junior.`,
        image: iconProexp
    }
    const items = [
        {
            type: 'modal',
            title: 'Capability Strengths',
            show_btn_view: leadershipCanvas.leadership_discovery_done,
            show_btn_unlock: !leadershipCanvas.leadership_discovery_done,
            unlock_url: `/front/questionnaire/${leadershipCanvas?.questionnaire_id}`,
            bottom_text: getStringFromAnswers(item_CS?.answers),
            image: iconCapability,
            modal: <GenericModalReport open={true} toggle={toggle} sectionCode={intro.code} title={'Capability Strengths'} items={item_CS?.answers} text={ <span> Click <a onClick={ (e)=> {e.preventDefault(); setShowQuestionnaireReview(true)}} href={`/front/questionnaire-review/${leadershipCanvas?.questionnaire_id}`}> here </a> to update your answers </span>} />,
            modalIsOpen: isOpenModal,
            openModal: open,
            questionnaireView: <QuestionnairePage id={leadershipCanvas?.questionnaire_id} animation={false} />,
            questionnaireId: leadershipCanvas?.questionnaire_id,
            showQuestionnaireReview,
        },
        {
            type: 'modal',
            title: 'Professional Strengths',
            show_btn_view: leadershipCanvas.leadership_discovery_done,
            show_btn_unlock: !leadershipCanvas.leadership_discovery_done,
            unlock_url: `/front/questionnaire/${leadershipCanvas?.questionnaire_id}`,
            bottom_text: getStringFromAnswers(item_PS?.answers),
            image: iconProfessional,
            modal: <GenericModalReport open={true} toggle={toggle} sectionCode={intro.code} title={item_PS?.title} items={item_PS?.answers} text={ <span> Click <a onClick={ (e)=> {e.preventDefault(); setShowQuestionnaireReview(true)}} href={`/front/questionnaire-review/${leadershipCanvas?.questionnaire_id}`}> here </a> to update your answers </span>} />,
            modalIsOpen: isOpenModal,
            openModal: open,
            questionnaireView: <QuestionnairePage id={leadershipCanvas?.questionnaire_id} animation={false} />,
            questionnaireId: leadershipCanvas?.questionnaire_id,
            showQuestionnaireReview,
        },
        {
            type: 'modal',
            title: 'Industry Strengths',
            show_btn_view: leadershipCanvas.leadership_discovery_done,
            show_btn_unlock: !leadershipCanvas.leadership_discovery_done,
            unlock_url: `/front/questionnaire/${leadershipCanvas?.questionnaire_id}`,
            bottom_text: getStringFromAnswers(item_IS?.answers),
            image: iconIndustry,
            modal: <GenericModalReport open={true} toggle={toggle} sectionCode={intro.code} title={item_IS?.title} items={item_IS?.answers} text={ <span> Click <a onClick={ (e)=> {e.preventDefault(); setShowQuestionnaireReview(true)}} href={`/front/questionnaire-review/${leadershipCanvas?.questionnaire_id}`}> here </a> to update your answers </span>} />,
            modalIsOpen: isOpenModal,
            openModal: open,
            questionnaireView: <QuestionnairePage id={leadershipCanvas?.questionnaire_id} animation={false} />,
            questionnaireId: leadershipCanvas?.questionnaire_id,
            showQuestionnaireReview,
        },
        {
            type: 'modal',
            title: 'Product Strengths',
            show_btn_view: leadershipCanvas.leadership_discovery_done,
            show_btn_unlock: !leadershipCanvas.leadership_discovery_done,
            unlock_url: `/front/questionnaire/${leadershipCanvas?.questionnaire_id}`,
            bottom_text: getStringFromAnswers(item_ProdS?.answers),
            image: iconProduct,
            modal: <GenericModalReport open={true} toggle={toggle} sectionCode={intro.code} title={item_ProdS?.title} items={item_ProdS?.answers} text={ <span> Click <a onClick={ (e)=> {e.preventDefault(); setShowQuestionnaireReview(true)}} href={`/front/questionnaire-review/${leadershipCanvas?.questionnaire_id}`}> here </a> to update your answers </span>} />,
            modalIsOpen: isOpenModal,
            openModal: open,
            questionnaireView: <QuestionnairePage id={leadershipCanvas?.questionnaire_id} animation={false} />,
            questionnaireId: leadershipCanvas?.questionnaire_id,
            showQuestionnaireReview,
        },
        {
            type: 'modal',
            title: 'Regional Strengths',
            show_btn_view: leadershipCanvas.leadership_discovery_done,
            show_btn_unlock: !leadershipCanvas.leadership_discovery_done,
            unlock_url: `/front/questionnaire/${leadershipCanvas?.questionnaire_id}`,
            bottom_text: getStringFromAnswers(item_RS?.answers),
            image: iconRegional,
            modal: <GenericModalReport open={true} toggle={toggle} sectionCode={intro.code} title={item_RS?.title} items={item_RS?.answers} text={ <span> Click <a onClick={ (e)=> {e.preventDefault(); setShowQuestionnaireReview(true)}} href={`/front/questionnaire-review/${leadershipCanvas?.questionnaire_id}`}> here </a> to update your answers </span>} />,
            modalIsOpen: isOpenModal,
            openModal: open,
            questionnaireView: <QuestionnairePage id={leadershipCanvas?.questionnaire_id} animation={false} />,
            questionnaireId: leadershipCanvas?.questionnaire_id,
            showQuestionnaireReview,
        },
    ]

    return (
        <DynamicSection intro={intro} items={items} goBack={goBack} />
    )
}