import { call, fork, takeEvery } from 'redux-saga/effects';
import { fetchJobsOutstanding } from '../api';
import { jobsOutstandingActions } from './actions';

export function* jobsOutstandingLoad() {
    yield call(fetchJobsOutstanding);
}

//Watchers
export function* watchJobsOutstandingLoad() {
    yield takeEvery(jobsOutstandingActions.LOAD_JOBS_OUTSTANDING, jobsOutstandingLoad)
}

//Root
export const jobsOutstandingSagas = [
    fork(watchJobsOutstandingLoad),
]
