import React from "react";
import { useSelector } from "react-redux";

import history from "../../../../core/history";
import MindPatternPage from "../../../pages/mind-pattern-page";
import ThinkingTalentsPage from "../../../pages/thinking-talents-page";
import { Intro } from "../../leadershipCanvasReport";

//icons
import iconThinkingLearning from "../../../../assets/img/icon-thinking-learning.svg"
import iconThinkingTalents from "../../../../assets/img/icon-thinking-talents-report.svg";
import iconMindPatterns from "../../../../assets/img/icon-mind-pattern-report.svg"

export const ThinkingAndLearningSection = ({ sectionCode }) => {
    const { leadership_canvas_report } = useSelector(state => state)

    return (
        <>
            <Intro title={'Thinking & Learning'} className={`intro-1 ` + sectionCode} image={iconThinkingLearning} sectionCode={sectionCode} text={`${leadership_canvas_report?.user?.first_name}'s Thinking Talents and Mind Patterns represent what work gives ${leadership_canvas_report?.user?.first_name} energy and shows how ${leadership_canvas_report?.user?.first_name} learns new information. Combined with Mindset, this is a comprehensive view of how ${leadership_canvas_report?.user?.first_name} works best and adapts to changes.`} />
            <Intro title={'Thinking Talents'} className={`intro-2 ` + sectionCode} image={iconThinkingTalents} sectionCode={sectionCode} text={`${leadership_canvas_report?.user?.first_name}'s Thinking Talents are their innate ways of thinking. Thinking Talents are the specific ways of approaching challenges that energize their brain and come naturally to them. These are different from the skills they've developed through education and training. There are thirty-five. Thinking Talents in all, and most adults naturally use 5-8. Not only does ${leadership_canvas_report?.user?.first_name} lean into these talents, but when ${leadership_canvas_report?.user?.first_name} thinks this way ${leadership_canvas_report?.user?.first_name} feels energized.`} />
            {leadership_canvas_report?.thinking_talents_done && <div className="reporte-1 reporteThinkingTalents"><ThinkingTalentsPage section={'report'} user={leadership_canvas_report?.user} history={history} /></div>}
            <Intro title={'Mind Patterns'} className={`intro-2 ` + sectionCode} image={iconMindPatterns} sectionCode={sectionCode} text={`${leadership_canvas_report?.user?.first_name}'s Mind Pattern is the unique way that ${leadership_canvas_report?.user?.first_name} processes and responds to information. Mind Patterns can also help them intentionally shift from focused to generative thinking, help them design a working environment and develop new habits that evoke their best thinking. When working with others, understanding ${leadership_canvas_report?.user?.first_name}'s Mind Pattern can help us dissolve some of the immediate ways we might misread and therefore misjudge or miscommunicate with each other.`} />
            {leadership_canvas_report?.mind_pattern_done && <div className="reporte-2 reporteMindPattern"><MindPatternPage fixSection={true} section={'report'} user={leadership_canvas_report?.user} history={history} /></div>}
        </>
    )
}