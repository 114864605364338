import { Record, List } from 'immutable';

export const Member = new Record({
  id: null,
  isNew: true,
  isPending: false,
  username: null,
  email: null,
  avatar: null,
  first_name: null,
  last_name: null,
  seniority: new Record({}),
  country: new Record({}),
  is_manager: 0,
  mind_pattern: null,
  talents: new List(),
  mind_pattern_info: null
});

export function createMember(data) {
  return new Member({
    id: data.id,
    username: data.username,
    email: data.email,
    avatar: data.avatar,
    first_name: data.first_name,
    last_name: data.last_name,
    seniority: data.senioriy,
    country: data.country,
    is_manager: data.is_manager,
    mind_pattern: data.mind_pattern,
    talents: data.talents ? new List(data.talents) : new List(),
    mind_pattern_info: data.mind_pattern_info,
  });
}
