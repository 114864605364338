import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Fade, Row } from 'reactstrap';

import { pdfsActions } from "../../../core/pdfs";

export const BtnsReport = ({ user, allAssessmentsCompleted = false, className = '' }) => {
    const dispatch = useDispatch()
    const [btnShareClicked, setBtnShareClicked] = useState(false)
    const [btnDownloadClicked, setDownloadClicked] = useState(false)
    const [currentShareMsj, setCurrentShareMsj] = useState('')
    const [currentDownloadMsj, setCurrentDownloadMsj] = useState('')

    const tryCopyURLCareerCanvasReport = () => {
        setBtnShareClicked(true)
        if(allAssessmentsCompleted) {
            let url = `${window.location.origin + '/front/career-canvas-report/' + user?.token_id}`
            navigator.clipboard.writeText(url)
            setCurrentShareMsj('Career Canvas URL copied to clipboard.')
        } else {
            setCurrentShareMsj('Your Career Canvas is incomplete. Please ensure you have completed all sections before sharing it with others!.')
        }
    }

    const tryDownloadCareerCanvasReportPDF = () => {
        setDownloadClicked(true)
        if(allAssessmentsCompleted) {
            dispatch(pdfsActions.loadCareerCanvasReportPDF())
        } else {
            setCurrentDownloadMsj('Your Career Canvas is incomplete. Please ensure you have completed all sections before downloading the pdf file.')
        }
    }

    useEffect(() => {
        if (btnShareClicked) {
            setTimeout(() => {
                setBtnShareClicked(false)
            }, 3000);
        }
    }, [btnShareClicked])

    useEffect(() => {
        if (btnDownloadClicked) {
            setTimeout(() => {
                setDownloadClicked(false)
            }, 3000);
        }
    }, [btnDownloadClicked])

    return (
        <Row className={`w-100 ${className}`}>
            <div className="w-100">
                <a href="#" className={`btn btn-sm btn-view btn-section px-3 border-0 rounded-pill float-right mr-md-1 mb-0`} onClick={(e) => { e.preventDefault(); tryCopyURLCareerCanvasReport() }} >
                    Share
                </a>
                <button className={`btn btn-sm btn-view btn-section px-3 border-0 rounded-pill float-right mr-md-1 mb-0`} onClick={(e) => { e.preventDefault(); tryDownloadCareerCanvasReportPDF() }} >
                    Download PDF
                </button>
            </div>
            <div className="w-100">
                <Fade in={btnShareClicked} tag="span" className="text-gray-dark float-right mr-md-1 mt-1" timeout={300} unmountOnExit={true}>
                    {currentShareMsj}
                </Fade>
                <Fade in={btnDownloadClicked} tag="span" className="text-gray-dark float-right mr-md-1 mt-1" timeout={300} unmountOnExit={true}>
                    {currentDownloadMsj}
                </Fade>
            </div>
        </Row>
    )
}