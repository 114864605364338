import { DEFAULT_SESSION_ID } from "../constants";

export const forgotPasswordActions = {

    FETCH_FORGOTPWD_FAILED: 'FETCH_FORGOTPWD_FAILED',
    FETCH_FORGOTPWD_SUCCESS: 'FETCH_FORGOTPWD_SUCCESS',
    FETCH_FORGOTPWD_PENDING: 'FETCH_FORGOTPWD_PENDING',
    FORGOT_PASSWORD: 'FORGOT_PASSWORD',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',

    fetchForgotPasswordFailed: error => ({
        type: forgotPasswordActions.FETCH_FORGOTPWD_FAILED,
        payload: { error }
    }),

    fetchForgotPasswordSuccess: (userId, data) => ({
        type: forgotPasswordActions.FETCH_FORGOTPWD_SUCCESS,
        payload: {
            data,
            userId
        }
    }),

    fetchForgotPasswordPending: userId => ({
        type: forgotPasswordActions.FETCH_FORGOTPWD_PENDING,
        payload: {
            userId
        }
    }),


    forgotResetPassword: data => ({
        type: forgotPasswordActions.FORGOT_PASSWORD,
        payload: {
            data,
            userId: DEFAULT_SESSION_ID
        }
    }),

    changePassword: data => ({
        type: forgotPasswordActions.CHANGE_PASSWORD,
        payload: {
            data,
            userId: DEFAULT_SESSION_ID
        }
    }),
};


export const forgotPasswordRequestActions = {
    failed: forgotPasswordActions.fetchForgotPasswordFailed,
    success: forgotPasswordActions.fetchForgotPasswordSuccess,
    pending: forgotPasswordActions.fetchForgotPasswordPending
};
