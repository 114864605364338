import React, { useEffect, useState, useRef, Fragment } from 'react';
import { Button, Container, Form, FormGroup, Input, Label, FormText, Row, Col, CustomInput, FormFeedback } from 'reactstrap';
import { ErrorPasswordPolitics } from '../../../components/common/ErrorPasswordPolitics';
import { useSelector } from 'react-redux';

import { generateArrayOfYears, generateArrayOfMonth, getArrayOfDays, passwordStrengthChecker } from '../../../../core/helper';

export const FormCollectiveRegister = ({ inputValues, handleInputChange, lists, onSubmitRegister, formErrors, formValid, statesIsPending }) => {
    const { register } = useSelector(state => state);
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        setDisabled(!formValid());
    }, [inputValues])

    const parseError = (error) => {
        const errorEmail = error?.data?.email?.length && error?.data?.email[0]
        const errorMessage = errorEmail || error?.message

        switch(errorMessage) {
            case 'The email already exists in MyTrueAdvance. Please contact us here or write us here.':
                return <span>
                        This email is connected to an existing account. Please 
                        <a target="_blank" href="/front/welcome"> login </a>
                        or email
                        <a href={'mailto:info@mytrueadvance.com'} target="_blank"> info@mytrueadvance.com </a> if you have any issues. 
                    </span>
            default:
                return <span> {errorMessage} </span>;
        }
    }

    return (
        <>
            <Form onSubmit={onSubmitRegister} className="form-login p-3 p-md-0">
                <Row>
                    <Col md={6}>
                        <FormGroup className='required'>
                            <Label className='control-label' for="first_name">First Name</Label>
                            <Input
                                type="text"
                                name="firstName"
                                id="first_name"
                                value={inputValues.firstName}
                                onChange={handleInputChange}
                                valid={(formErrors.firstName !== '' || inputValues.firstName === '') ? null : true}
                                invalid={formErrors.firstName === '' ? null : true}
                                className="rounded-lg"
                            />
                            <FormFeedback>{formErrors.firstName}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup className='required'>
                            <Label className='control-label' for="last_name">Last Name</Label>
                            <Input
                                type="text"
                                name="lastName"
                                id="last_name"
                                value={inputValues.lastName}
                                onChange={handleInputChange}
                                valid={(formErrors.lastName !== '' || inputValues.lastName === '') ? null : true}
                                invalid={formErrors.lastName === '' ? null : true}
                                className="rounded-lg"
                            />
                            <FormFeedback>{formErrors.lastName}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <FormGroup className='required'>
                            <Label className='control-label' for="email">Personal Email</Label>
                            <Input
                                type="email"
                                name="email"
                                id="email"
                                value={inputValues.email}
                                onChange={handleInputChange}
                                className="rounded-lg"
                                valid={(formErrors.email !== '' || inputValues.email === '') ? null : true}
                                invalid={formErrors.email === '' ? null : true}
                            />
                            <FormFeedback>{formErrors.email}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup className='required'>
                            <Label className='control-label' for="confirm-email">Confirm Personal Email</Label>
                            <Input
                                type="email"
                                name="confirmEmail"
                                id="confirm-email"
                                value={inputValues.confirmEmail}
                                onChange={handleInputChange}
                                valid={(formErrors.confirmEmail !== '' || inputValues.confirmEmail === '') ? null : true}
                                invalid={formErrors.confirmEmail === '' ? null : true}
                                className="rounded-lg"
                            />
                            <FormFeedback>{formErrors.confirmEmail}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row> 

                <Row>
                    <Col md={6}>
                        <FormGroup className='required'>
                            <Label className='control-label' for="password">Password</Label>
                            <Input
                                type="password"
                                name="password"
                                id="password"
                                value={inputValues.password}
                                onChange={handleInputChange}
                                valid={(inputValues.password === '') || (formErrors.password !== '') ? null : true}
                                //valid={(inputValues.password && !formErrors.password) || (inputValues.password === '') || (formErrors.password !== '') ? null : true}
                                invalid={(formErrors.password !== '') || (inputValues.password && !formErrors.password) ? true : null}
                                className="rounded-lg"
                            />
                            <FormFeedback>{formErrors.password}</FormFeedback>
                            {inputValues.password && !formErrors.password &&
                                <FormText className='ml-3'>{passwordStrengthChecker(inputValues.password)}</FormText>
                            }
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup className='required'>
                            <Label className='control-label' for="confirm-pass">Confirm Password</Label>
                            <Input
                                type="password"
                                name="confirmPassword"
                                id="confirm-pass"
                                value={inputValues.confirmPassword}
                                onChange={handleInputChange}
                                valid={(formErrors.confirmPassword !== '' || inputValues.confirmPassword === '') ? null : true}
                                invalid={formErrors.confirmPassword === '' ? null : true}
                                className="rounded-lg"
                            />
                            <FormFeedback>{formErrors.confirmPassword}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <Row>
                            <Col md={12}>
                                <FormGroup className='required'>
                                <Label className='control-label' for="select">Which year did you start your professional career?</Label>
                                    <Input 
                                        type="select" 
                                        className="form-control rounded-lg" 
                                        onChange={handleInputChange} 
                                        name="careerStartYear" 
                                        invalid={formErrors.careerStartYear === '' ? null : true}
                                    >
                                        <option value="">Select your option</option>
                                        {generateArrayOfYears().map(year =>
                                            <option
                                                key={year}
                                                value={year}>
                                                {year}
                                            </option>
                                        )
                                        }
                                    </Input>
                                    <FormFeedback>{formErrors.careerStartYear}</FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>

                    <Col md={6}>
                        <FormGroup className='required'>
                            <Label className='control-label' for="select">What is your current career level?</Label>
                            <Input 
                                type="select" 
                                className="form-control rounded-lg" 
                                onChange={handleInputChange} 
                                name="currentCareerLevel" 
                                invalid={formErrors.currentCareerLevel === '' ? null : true}
                            >
                                <option value="">Select your option</option>
                                {
                                    lists?.careerLevels &&
                                    lists?.careerLevels.map(item =>
                                        <option
                                            key={item.id}
                                            value={item.id}>
                                            {item.name}
                                        </option>
                                    )
                                }
                            </Input>
                            <FormFeedback>{formErrors.currentCareerLevel}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <FormGroup className='required'>
                            <Label className='control-label' for="select">Professional Role</Label>
                            <Input 
                                type="select" 
                                className="form-control rounded-lg" 
                                onChange={handleInputChange} 
                                name="professionalRole" 
                                invalid={formErrors.professionalRole === '' ? null : true}
                            >
                                <option value="">Select your option</option>
                                {
                                    lists?.professionalRole &&
                                    lists?.professionalRole.map(item =>
                                        <option
                                            key={item.id}
                                            value={item.id}>
                                            {item.name}
                                        </option>
                                    )
                                }
                            </Input>
                            <FormFeedback>{formErrors.professionalRole}</FormFeedback>
                        </FormGroup>

                    </Col>
                    <Col md={6}>
                        <FormGroup className='required'>
                            <Label className='control-label' for="select">Which best describes your reason for joining the collective? Are you looking for: </Label>
                            <div className="d-flex justify-content-between flex-column">
                                {
                                    lists?.reasonsJoining &&
                                    lists?.reasonsJoining.map(item =>
                                        <CustomInput
                                            type="radio"
                                            name="reasonJoiningCollective"
                                            className="pl-0 styled-checkbox d-flex align-items-center text-primary line-height-normal"
                                            id={item.id}
                                            onChange={handleInputChange}
                                            value={item.id}
                                            label={item.name}
                                            cssModule={{ 'custom-control-label': 'custom-control-label pl-30' }}
                                        />
                                    )
                                }
                            </div>
                            <FormFeedback>{formErrors.reasonJoiningCollective}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <FormGroup className='required'>
                            <Label className='control-label' for="select">Do you identify as BIPOC (Black, Indigenous, Person of Color)?</Label>
                            <Input 
                                type="select" 
                                className="form-control rounded-lg" 
                                onChange={handleInputChange} 
                                name="BIPOC" 
                                invalid={formErrors.BIPOC === '' ? null : true}
                            >
                                <option value="">Select your option</option>
                                <option
                                    key={1}
                                    value={1}>
                                    Yes
                                </option>
                                <option
                                    key={0}
                                    value={0}>
                                    No
                                </option>
                                <option
                                    key={2}
                                    value={2}>
                                    Prefer not to disclose
                                </option>
                            </Input>
                            <FormFeedback>{formErrors.BIPOC}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup className='required'>
                            <Label className='control-label' for="select">How did you hear about us?</Label>
                            <Input 
                                type="select" 
                                className="form-control rounded-lg" 
                                onChange={handleInputChange} 
                                name="hearAboutUs" 
                                invalid={formErrors.hearAboutUs === '' ? null : true}
                            >
                                <option value=''>Select your option</option>
                                {
                                    lists?.hearAbout &&
                                    lists?.hearAbout.map(item =>
                                        <option
                                            key={item.id}
                                            value={item.id}>
                                            {item.name}
                                        </option>
                                    )
                                }
                            </Input>
                            <FormFeedback>{formErrors.hearAboutUs}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <FormGroup className='required'>
                            <Label className='control-label' for="select">Country</Label>
                            <Input 
                                type="select" 
                                className="form-control rounded-lg" 
                                onChange={handleInputChange} 
                                name="country" 
                                invalid={formErrors.country === '' ? null : true}
                            >
                                <option value=''>Select your option</option>
                                {
                                    lists?.countries &&
                                    lists?.countries.map(item =>
                                        <option
                                            key={item.id}
                                            value={item.id}>
                                            {item.name}
                                        </option>
                                    )
                                }
                            </Input>
                            <FormFeedback>{formErrors.country}</FormFeedback>
                        </FormGroup>
                    </Col>

                    {inputValues.country && !statesIsPending &&
                        <Fragment>
                            {lists?.states?.size > 0 &&
                                <Col md={6}>
                                    <FormGroup className='required'>
                                        <Label className='control-label' for="select">State</Label>
                                        <Input 
                                            type="select" 
                                            className="form-control rounded-lg" 
                                            onChange={handleInputChange} 
                                            name="state" 
                                            invalid={formErrors.state === '' ? null : true}
                                        >
                                            <option value=''>Select your option</option>
                                            {
                                                lists?.states &&
                                                lists?.states.map(item =>
                                                    <option
                                                        key={item.id}
                                                        value={item.id}>
                                                        {item.name}
                                                    </option>
                                                )
                                            }
                                        </Input>
                                        <FormFeedback>{formErrors.state}</FormFeedback>
                                    </FormGroup>
                                </Col>

                            }
                            {!lists?.states?.size > 0 &&
                                <Col md={6}>
                                    <FormGroup className='required'>
                                        <Label className='control-label' for="confirm-pass">State</Label>
                                        <Input
                                            type="text"
                                            name="state"
                                            id="state"
                                            value={inputValues.state}
                                            onChange={handleInputChange}
                                            valid={(formErrors.state !== '' || inputValues.state === '') ? null : true}
                                            invalid={formErrors.state === '' ? null : true}
                                            className="rounded-lg"
                                        />
                                        <FormFeedback>{formErrors.state}</FormFeedback>
                                    </FormGroup>
                                </Col>
                            }
                        </Fragment>
                    }
                </Row>

                <Row>
                    <Col md={6}>
                        <FormGroup className='required'>
                            <Label></Label>
                            <CustomInput className="styled-checkbox pl-0 mt-1" type="checkbox" id="termsAndPrivacy" name="termsAndPrivacy" onChange={handleInputChange} label={<Label className='control-label' for="termsAndPrivacy" >I agree to True Advance's <a href="https://www.trueadvance.com/terms-of-use" target='_blank'>Terms of Use</a> and <a href="https://www.trueadvance.com/privacy-policy" target="_blank">Privacy Policy</a></Label>} />
                        </FormGroup>
                    </Col>
                </Row>

                {register.error !== 0 && register.error?.description?.password_politics && <ErrorPasswordPolitics errorPasswordPolitics={register.error.description.password_politics} />}
                
                <FormGroup>
                    <Input
                        invalid={parseError(register?.error) ? true : false}
                        style={{ display: 'none' }}
                    />
                    <FormFeedback>{register?.error ? parseError(register?.error) : ''}</FormFeedback>
                </FormGroup>

                <Button color="primary" type="submit" disabled={disabled} className="text-uppercase rounded-lg px-md-5 btn-block">Register</Button>
            </Form>
        </>
    )
}
