import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Container, Row } from 'reactstrap';
import { eventOutstandingActions } from '../../../core/eventOutstanding/actions';
import { eventsActions } from '../../../core/events/actions';
import { FooterHomePage } from '../../components/common/FooterHomePage';
import menu from '../../../assets/img/menu.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import ReactGA from 'react-ga';
import { trackingActions } from '../../../core/tracking';
import session from '../../../core/session';

const animationClass = {
    appearActive: 'animated slideInRight',
    enter: 'animated',
    enterActive: 'animated slideInRight',
    exit: 'animated',
    exitActive: 'animated slideOutLeft',
}

export const EventsPage = () => {
    const dispatch = useDispatch();
    const { events, event_outstanding } = useSelector(state => state);
    const [currentPage, setCurrentPage] = useState(1);


    useEffect(() => {
        if(session.getAuthenticated()) {
            dispatch(eventOutstandingActions.loadEventOutstanding());
            dispatch(eventsActions.loadEvents(currentPage));
        }

        return () => {
            dispatch(eventsActions.loadEvents(currentPage));
        }
    }, []);


    const viewMoreEvents = () => {
        setCurrentPage(events.data.current_page + 1);
        dispatch(eventsActions.loadEvents(currentPage + 1));
    }

    const eventGA = (title) => {
        ReactGA.event({
            category: 'Events',
            action: `Click on event: ${title}`
        })
    }

    const ourTracking = (title) => {
        dispatch(trackingActions.saveTrackingEvent({ category: 'Events', action: `Click on event: ${title}` }))
    }

    return (
        <>
            <div className="view overflow-hidden">
                <TransitionGroup className="content">
                    <CSSTransition classNames={animationClass} timeout={{ enter: 1000, exit: 1000 }} appear >
                        <div className="content">
                            <div className="w-100">
                                <Container>
                                    <div className="card bg-white my-md-4 p-4 pb-md-5 px-md-4 pt-md-2 content-report content-view">
                                        <div className="px-md-5">
                                            <div className="encabezado">
                                                <div className="mb-0 pt-2 pb-1 d-flex align-items-end">
                                                    <h6 className="mb-0 title-underline pr-5">Upcoming Events</h6>
                                                </div>
                                                <span className="border border-info d-none d-md-block"></span>
                                            </div>

                                            <Row className="mt-5 row-cols-1 events-list">
                                                {event_outstanding?.data && <Col className="mb-3">
                                                    <a href={event_outstanding.data.registration_url} onClick={() => { eventGA(event_outstanding.data.title); ourTracking(event_outstanding.data.title) }} target="_blank" className="text-reset text-decoration-none d-block pl-3" >
                                                        <div className="d-flex">
                                                            <div className="thumb-event rounded col-md-4 p-0 w-auto">
                                                                <img src={event_outstanding.data.image} alt={event_outstanding.data.title} className="rounded w-md-100" />
                                                            </div>

                                                            <div className="ml-md-5 ml-3 flex-fill">
                                                                <h4 className="pb-2">{event_outstanding.data.title}</h4>
                                                                <div className="bg-celeste px-3 ml-n3 py-1 rounded mb-2"><p className="mb-0">{event_outstanding.data.date}</p></div>
                                                                <div dangerouslySetInnerHTML={{ __html: event_outstanding.data.text }}></div>
                                                                <span href="#" target="_blank" className="btn btn-outline-primary text-uppercase rounded-pill d-inline-flex px-md-4 mx-auto mt-3">REGISTER</span>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </Col>}
                                            </Row>

                                            <span className="border border-info d-block mt-2"></span>

                                            <Row className="mt-4 row-cols-1 events-list align-content-stretch">

                                                {
                                                    events.events && events.events.map((e, i) => {
                                                        return <><Col className="mb-4" key={e.id}>
                                                            <a href={e.registration_url} onClick={() => { eventGA(e.title); ourTracking(e.title) }} target="_blank" className="text-decoration-none d-block text-reset">
                                                                <div className="d-flex pl-3">
                                                                    <div className="thumb-event rounded w-auto col-md-3 px-0">
                                                                        <img src={e.image} alt={e.title} className="rounded w-md-100" />
                                                                    </div>

                                                                    <div className="ml-5 flex-fill">
                                                                        <h5 className="pb-2">{e.title}</h5>
                                                                        <div className="bg-celeste px-3 ml-n3 py-1 rounded mb-2">
                                                                            <p className="mb-0">{e.date}</p></div>
                                                                        <div dangerouslySetInnerHTML={{ __html: e.text }}></div>
                                                                        <span href="#" target="_blank" className="btn btn-outline-primary text-uppercase rounded-pill d-inline-flex px-md-4 mx-auto">REGISTER</span>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            {i + 1 != events.events.length && <span className="border border-muted d-none d-md-block mt-4"></span>}
                                                        </Col>
                                                        </>
                                                    })
                                                }
                                            </Row>

                                            <div className="text-center">
                                                {events.data.current_page < events.data.total_pages && <a className="btn btn-outline-primary text-uppercase rounded-pill d-inline-flex px-md-4 mx-auto mt-3" onClick={viewMoreEvents}>VIEW MORE</a>}
                                            </div>

                                        </div>

                                    </div>
                                </Container>
                                <Container className="d-none d-sm-none d-md-block mt-auto">
                                    <Row>
                                        <Col md={{ size: 12, offset: 0 }} lg={{ size: 10, offset: 1 }}>
                                            <FooterHomePage />
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </div>
        </>
    )
}
