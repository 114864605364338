import { call, fork, takeEvery } from 'redux-saga/effects';
import { fetchEventOutstanding } from '../api';
import { eventOutstandingActions } from './actions';

export function* eventOutstandingLoad() {
    yield call(fetchEventOutstanding);
}

//Watchers
export function* watchEventOutstandingLoad() {
    yield takeEvery(eventOutstandingActions.LOAD_EVENT_OUTSTANDING, eventOutstandingLoad)
}

//Root
export const eventOutstandingSaga = [
    fork(watchEventOutstandingLoad),
]
