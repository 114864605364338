import React from "react";
import { Redirect } from "react-router-dom";

// Route Views
import { HomePage } from './views/pages/home-page';
import ThinkingTalentsPage from './views/pages/thinking-talents-page';
import MindPatternPage from './views/pages/mind-pattern-page';

import { TeamsAdminPage, TeamsEditPage, TeamsAddMemberPage, TeamDetailPage } from './views/pages/teams';
import NotFoundPage from './views/pages/not-found-page';
import LoginPage from './views/pages/auth/login-page';
import RegisterPage from "./views/pages/auth/register/register-page";
import { CollectiveRegister } from "./views/pages/auth/register/collective-register";
import confirmRegister from "./views/pages/auth/register/confirm-register";
import { HomeAuthPage } from "./views/pages/auth/homeAuth-page";
import MyProfile from "./views/pages/myProfile/MyProfile";
import ForgotPassword from "./views/pages/auth/forgotPassword/ForgotPassword";
import ResetPassword from "./views/pages/auth/forgotPassword/ResetPassword";
import ContactUs from "./views/pages/contactUs/ContactUs";
import PrivacyPage from "./views/pages/privacy/PrivacyPage";
import { QuestionnairePage } from "./views/pages/home/questionnaires/QuestionnairePage";
import { QuestionnaireReview } from "./views/pages/home/questionnaires/QuestionnaireReview";
import { EventsPage } from "./views/pages/events/EventsPage";
import { ResetPasswordDone } from "./views/pages/auth/forgotPassword/ResetPasswordDone";
import { Jobs } from "./views/pages/jobs/Jobs";
import { Community } from "./views/pages/community/Community";
import { BlogPage } from "./views/pages/blogs/BlogPage";
import { PostContent } from "./views/pages/blogs/PostContent";
import { MyDocuments } from "./views/pages/myDocuments/MyDocuments";
import { CompanyCards } from "./views/pages/companyCards/CompanyCards";
import { LeadershipCanvas } from "./views/pages/leadershipCanvas/LeadershipCanvas";
import { Referral } from "./views/pages/referral/Referral";
import { LeadershipCanvasReport } from "./views/pages/leadershipCanvas/LeadershipCanvasReport";
import { AccountInformationSent } from "./views/pages/accountInformation/AccountInformationSent";

export default [
  {
    name: "index",
    path: "/",
    exact: true,
    component: () => <Redirect to="/home" />
  },
  {
    name: 'home',
    path: "/home",
    //exact: true,
    component: HomePage
  },
  {
    name: 'welcome',
    path: "/welcome",
    component: HomeAuthPage
  },
  {
    name: 'questionnaire',
    path: '/questionnaire/:id',
    component: QuestionnairePage
  },
  {
    name: 'questionnaire-review',
    path: '/questionnaire-review/:id',
    component: QuestionnaireReview
  },
  {
    name: 'events',
    path: '/events',
    component: EventsPage
  },
  {
    name: 'jobs',
    path: '/jobs/:id?/:open?',
    component: Jobs
  },
  {
    name: 'login',
    path: "/login",
    component: LoginPage
  },
  {
    name: 'signup',
    path: "/signup",
    component: RegisterPage
  },
  {
    name: 'signup-collective',
    path: '/signup-collective:reffering?',
    component: CollectiveRegister
  },
  {
    name: 'referral',
    path: '/referral',
    component: Referral
  },
  {
    name: 'confirmRegister',
    path: "/confirm-register",
    component: confirmRegister
  },
  {
    name: 'confirmRegister',
    path: "/forgot-password",
    component: ForgotPassword
  },
  {
    name: 'confirmRegister',
    path: "/reset-password",
    component: ResetPassword
  },
  {
    name: 'confirmRegister',
    path: "/reset-password-done",
    component: ResetPasswordDone
  },
  {
    name: 'thinking',
    path: "/thinking-talents",
    component: ThinkingTalentsPage
  },
  {
    name: 'mind_pattern',
    path: "/mind-patterns",
    component: MindPatternPage
  },
  {
    name: 'teams_add',
    path: "/teams/create",
    component: TeamsEditPage
  },
  {
    name: 'teams_edit',
    path: "/teams/:id/edit",
    component: TeamsEditPage
  },
  {
    name: 'teams_add_member',
    path: "/teams/:id/add-member",
    component: TeamsAddMemberPage
  },
  {
    name: 'teams_edit',
    path: "/teams/:id",
    component: TeamDetailPage
  },
  {
    name: 'teams',
    path: "/teams",
    component: TeamsAdminPage
  },
  {
    name: 'contactUs',
    path: "/contact-us",
    component: ContactUs
  },
  {
    name: 'profile',
    path: "/profile",
    component: MyProfile
  },
  {
    name: 'privacy',
    path: "/privacy",
    component: PrivacyPage
  },
  {
    name: 'community',
    path: "/community",
    component: Community
  },
  {
    name: 'content-library-post',
    path: '/content-library/post/:id',
    component: PostContent
  },
  {
    name: 'content-library',
    path: '/content-library/:filter?',
    component: BlogPage
  },
  {
    name: 'my-documents',
    path: '/my-documents',
    component: MyDocuments
  },
  {
    name: 'company-cards',
    path: '/company-cards',
    component: CompanyCards
  },
  {
    name: 'career-canvas-report',
    path: '/career-canvas-report/:token_id',
    component: LeadershipCanvasReport
  },
  {
    name: 'career-canvas',
    path: '/career-canvas',
    component: LeadershipCanvas
  },
  {
    name: 'account-information-sent',
    path: '/account-information-sent',
    component: AccountInformationSent
  },
  {
    name: 'not-found',
    path: "*",
    component: NotFoundPage
  },
];
