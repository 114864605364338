export const companyCardsFiltersActions = {

  FETCH_COMPANY_CARDS_FILTERS_FAILED: 'FETCH_COMPANY_CARDS_FILTERS_FAILED',
  FETCH_COMPANY_CARDS_FILTERS_SUCCESS: 'FETCH_COMPANY_CARDS_FILTERS_SUCCESS',
  FETCH_COMPANY_CARDS_FILTERS_PENDING: 'FETCH_COMPANY_CARDS_FILTERS_PENDING',
  FETCH_COMPANY_CARDS_FILTERS_INDUSTRIES: 'FETCH_COMPANY_CARDS_FILTERS_INDUSTRIES',
  FETCH_COMPANY_CARDS_FILTERS_CERTIFICATIONS: 'FETCH_COMPANY_CARDS_FILTERS_CERTIFICATIONS',
  UPDATE_LAST_FILTERS: 'UPDATE_LAST_FILTERS',

  fetchCompanyCardsFiltersFailed: error => ({
    type: companyCardsFiltersActions.FETCH_COMPANY_CARDS_FILTERS_FAILED,
    payload: error
  }),

  fetchCompanyCardsFiltersSuccess: (companyCardsFiltersId, data) => ({
    type: companyCardsFiltersActions.FETCH_COMPANY_CARDS_FILTERS_SUCCESS,
    payload: {
      data,
      companyCardsFiltersId
    }
  }),

  fetchCompanyCardsFiltersPending: companyCardsFiltersId => ({
    type: companyCardsFiltersActions.FETCH_COMPANY_CARDS_FILTERS_PENDING,
    payload: {
      companyCardsFiltersId
    }
  }),

  fetchCompanyCardsIndustries: () => ({
    type: companyCardsFiltersActions.FETCH_COMPANY_CARDS_FILTERS_INDUSTRIES,
    payload: {
        companyCardsFiltersId: 'industries'
    }
  }),

  fetchCompanyCardsCertifications: () => ({
    type: companyCardsFiltersActions.FETCH_COMPANY_CARDS_FILTERS_CERTIFICATIONS,
    payload: {
        companyCardsFiltersId: 'certifications'
    }
  }),

  updateLastFilters: (filters) => ({
    type: companyCardsFiltersActions.UPDATE_LAST_FILTERS,
    payload: {
        filters
    }
  })

};


export const companyCardsFiltersRequestActions = {
  failed: companyCardsFiltersActions.fetchCompanyCardsFiltersFailed,
  success: companyCardsFiltersActions.fetchCompanyCardsFiltersSuccess,
  pending: companyCardsFiltersActions.fetchCompanyCardsFiltersPending
};
