import React from "react";
import { useSelector } from "react-redux";
import { Container, Col, Row } from 'reactstrap';

import { career_canvas_questionnaire } from "../../../../core/constants";
import { Intro } from "../../leadershipCanvasReport";
import { getLeadershipCanvasQuestionByCode } from "../../../../core/leadership-canvas-report";
import { LeadershipApproachReport } from "../../leadershipCanvasReport";

//icons
import iconLeadership from "../../../../assets/img/icon-leadership.svg";
import iconLeadershipStyle from "../../../../assets/img/icon-leadershipstyle-report.svg";
import iconImpact from "../../../../assets/img/icon-impact-report.svg";
import iconInfluence from "../../../../assets/img/icon-influence-report.svg";

export const LeadershipSection = ({ sectionCode }) => {
    const { leadership_canvas_report } = useSelector(state => state)
    const item_LA = useSelector(state => getLeadershipCanvasQuestionByCode(state, career_canvas_questionnaire.LEADERSHIP_APPROACH.questions.leadership_approach.code))
    const item_INS = useSelector(state => getLeadershipCanvasQuestionByCode(state, career_canvas_questionnaire.STRENGTH.questions.influence_strengths.code))
    const item_IMS = useSelector(state => getLeadershipCanvasQuestionByCode(state, career_canvas_questionnaire.STRENGTH.questions.impact_strengths.code))

    return (
        <>
            <Intro title={'Professional Experience'} className={`intro-1 ` + sectionCode} image={iconLeadership} sectionCode={sectionCode} text={`Leadership skills are developed through practice, and every leader has different strenghs and styles. These impact the right role and situation that ${leadership_canvas_report?.user?.first_name} should be in to drive the highest impact.`} />
            <Intro title={'Influence Strengths'} className={`intro-2 ` + sectionCode} image={iconLeadershipStyle} sectionCode={sectionCode} items={item_INS?.answers} />
            <Intro title={'Impact Strengths'} className={`intro-2 ` + sectionCode} image={iconImpact} sectionCode={sectionCode} items={item_IMS?.answers} />
            <Intro title={'Leadership Style'} className={`intro-2 ` + sectionCode} image={iconInfluence} sectionCode={sectionCode} text={`This is a visualization of the intersection of ${leadership_canvas_report?.user?.first_name}'s unique Leadership Styles to illustrate how they can make the most meaningful impact.`} />
            { leadership_canvas_report?.leadership_discovery_done &&
                <>
                <div className="bg-section py-4 shadow intro-2 working-style">
                <Container>
                    <div className="align-items-center px-3 row">
                        <Col>
                            <LeadershipApproachReport leadershipApproach={item_LA} firstName={leadership_canvas_report?.user?.first_name} />
                        </Col>
                    </div>
                </Container>
                </div>
                </>
            }
        </>
    )
}