import React from "react";
import { Col, Row, Card, CardBody, CardHeader } from 'reactstrap';

export const LeadershipApproachReport = ({ title, leadershipApproach, close, firstName, toggleShowReview }) => {
    return (
        <>
            <Row className="mb-5">
                <Col>
                    <div className="canvas-mindset-style">
                        <p className="mb-0 font-weight-bold rubik text-uppercase">{firstName ? firstName + "'s" : 'Your'} Leadership Style</p>
                    </div>
                    {toggleShowReview &&
                        <span className="text-reset mb-0 small"> Click <a href="#" onClick={(e) => { e.preventDefault(); toggleShowReview() }}> here </a> to update your answers </span>
                    }
                </Col>
            </Row>

            {leadershipApproach?.answers?.length > 0 &&
                <div className="d-flex justify-content-center align-items-stretch mx-n2 leadership-approach-report mx-auto">
                    <div className="rounded-circle style-xy text-center leadership-approach-item d-flex flex-column align-items-center p-3">
                        <h2 className="rubik text-uppercase mt-5 mb-3 text-muted">{leadershipApproach.answers[0]?.title}</h2>
                        <div className="pr-5 mr-0"><p className="text-muted mb-0" dangerouslySetInnerHTML={{ __html: leadershipApproach.answers[0]?.leadership_output }}></p></div>
                    </div>
                    <p className="intersection mx-n5 my-auto rubik text-white font-weight-bold" style={{ fontSize: '20px' }}>YOU</p>
                    <div className="rounded-circle style-ab text-center leadership-approach-item d-flex flex-column align-items-center p-3">
                        <h2 className="rubik text-uppercase mt-5 mb-3 text-muted">{leadershipApproach.answers[1]?.title}</h2>
                        <div className="pl-5 ml-0">
                            <p className="text-muted mb-0" dangerouslySetInnerHTML={{ __html: leadershipApproach.answers[1]?.leadership_output }}></p>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}