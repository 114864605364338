import React from "react";
import { Col, Row, Card, CardBody, CardHeader } from 'reactstrap';
import { getContentTag } from '../../../../core/helper';

export const ChallengeApproachReport = ({ title, challengeApproach, close, firstName, toggleShowReview }) => {
    return (
        <>
            <Row className="mb-5">
                <Col>
                    <div className="canvas-mindset-style">
                        <p className="mb-0 font-weight-bold rubik text-uppercase">{firstName ? firstName + "'s" : 'Your'} Approach to Challenges</p>
                    </div>
                    {toggleShowReview &&
                        <span className="text-reset mb-0 small"> Click <a href="#" onClick={(e)=>{e.preventDefault(); toggleShowReview()}}> here </a> to update your answers </span>
                    }
                </Col>
            </Row>

            <Row>
                <Col md={{ size: 7 }} className="mx-auto">
                    <div className="d-flex justify-content-between flex-wrap approachChallenge">
                        {challengeApproach?.answers?.map((answer, index) => {
                            return (
                                <div className="circle-content ml-0">
                                    <div className="mx-0 mb-3 text-center d-flex flex-column align-items-center justify-content-center p-3 circle-items" style={{ borderColor: index % 3 === 0 ? '#ED8F8F' : '#E3BFF0', backgroundColor: index % 3 === 0 ? '#ED8F8F' : '#E3BFF0' }}>
                                        {answer?.leadership_output && <h5 className="mb-0 mx-3 text-white text-uppercase" dangerouslySetInnerHTML={{ __html: getContentTag(answer?.leadership_output, 'approaching-challenges-circle') }}></h5>}
                                    </div>
                                    {answer?.leadership_output && <p className="text-center" dangerouslySetInnerHTML={{ __html: getContentTag(answer?.leadership_output, 'below-circle') }}></p>}
                                </div>
                            )
                        })
                        }
                    </div>
                </Col>
            </Row>
        </>
    )
}