import { Map } from 'immutable';

import { CompanyCard } from './company-card';
import { companyCardsListActions } from '../company-cards-list';

export function companyCardsReducer(state = new Map(), action) {
  const { payload } = action;

  switch (action.type) {
    case companyCardsListActions.FETCH_COMPANY_CARDS_SUCCESS:      
      return state.withMutations( companyCards => {
        payload.data.items.forEach( companyCardData => {
          companyCards.set(`${companyCardData.id}`, CompanyCard(companyCardData));
        });
      });
    default:
      return state;
  }
}
