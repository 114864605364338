const SPACE_SPLITTER = /\s+/;

export function companyCardsListIdForFind(query) {
  query = (!query) ? '' : query.trim().split(SPACE_SPLITTER).join(' ');
  return `s${query}`.toLowerCase();
}

export function companyCardsListIdForSearch(filters) {
	let query = "";
    Object.keys(filters).map(function(key) {
        query+= "_" + key;
        if(typeof filters[key] !== 'object') {
            query+= "_" + filters[key]
        } else {
            filters[key].forEach(filter => {
                query+= "_" + filter?.id
            });
        }
		return key;
	});  
	return `s${query}`.toLowerCase();
}
