import React, { useEffect, useState } from 'react';
import { Container, Col, Row, Card } from 'reactstrap';
import { Link } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { blogActions } from '../../../core/blog';
import { BlogFilters } from '../../components/blogs/BlogFilters';
import { BlogResults } from '../../components/blogs/BlogResults';
import LoadingIndicator from '../../components/loading-indicator/loading-indicator';

import icon_info from '../../../assets/img/info.svg'

const animationClass = {
    appearActive: 'animated slideInRight',
    enter: 'animated',
    enterActive: 'animated slideInRight',
    exit: 'animated',
    exitActive: 'animated slideOutLeft',
}

export const BlogPage = () => {
    const dispatch = useDispatch()
    const { filter } = useParams()
    const { user } = useSelector(state => state)
    const { account } = useSelector(state => state.user && state.user)
    const { blog } = useSelector(state => state)

    const [filters, setFilters] = useState([])
    const [refresh, setRefresh] = useState()
    const [search, setSearch] = useState('')

    const toggle = () => setRefresh(!refresh)

    useEffect(() => {
        if (filter && account.categories) {
            let category = account.categories.find(category => category.slug === filter)
            setFilters([{ id: category.id, name: category.name, type: 'category', slug: category.slug }])

            dispatch(blogActions.searchPosts({category: [category.slug]}))
        }
    }, [])

    useEffect(() => {
        if (!blog.posts && filters.length === 0 && !filter) dispatch(blogActions.searchPosts())
    }, [])

    useEffect(() => {
        if((!filter && !account.categories && !blog.posts) || blog.posts) applyFilters();
    }, [refresh, setFilters])

    const applyFilters = (page) => {

        var formatFilters = {}
        filters && filters.forEach(filter => {
            if (formatFilters[filter.type])
                formatFilters[filter.type].push(filter.slug ? filter.slug : filter.name)
            else {
                formatFilters[filter.type] = []
                formatFilters[filter.type].push(filter.slug ? filter.slug : filter.name)
            }
        });
        formatFilters.search = search

        dispatch(blogActions.searchPosts(formatFilters, page))
    }

    const __html_setMB0 = (string) => {
        return string && string.replace("<p>", `<p class="mb-0">`);
    }

    return (
        <div className='view overflow-hidden'>
            <TransitionGroup className="content">
                <CSSTransition classNames={animationClass} timeout={{ enter: 1000, exit: 1000 }} appear >
                    <div className="w-100">
                        <Container>
                            <div className="bg-white content-view my-md-4 shadow-md mx-n3 mx-md-auto rounded-lg">
                                <div className="bg-white rounded-lg-top p-4 border-md-bottom">
                                    <div className="d-flex justify-content-center align-items-md-center align-items-start px-md-5">
                                        <div className="mr-md-5 mr-3"><img src={icon_info} width="46" height="auto" style={{ maxHeight: '46px' }} /></div>
                                        <div dangerouslySetInnerHTML={{ __html: __html_setMB0(account.posts_introduction_text) }}></div>
                                    </div>
                                </div>
                                <Row className="mx-0 align-items-stretch content-view">
                                    <Col md={{ size: 3 }} className="bg-md-light pb-md-1 pt-md-3 px-4" style={{ borderBottomLeftRadius: '10px' }}>
                                        <BlogFilters filters={filters} setFilters={setFilters} setSearch={setSearch} refresh={toggle} />
                                    </Col>
                                    <Col md={{ size: 9 }} className="px-4 pb-4 pb-md-1 pt-md-2 px-md-0 d-flex flex-column">
                                        <BlogResults posts={blog.posts && blog.posts.blog_posts} current_page={blog.posts && blog.posts.current_page} total_pages={blog.posts && blog.posts.total_pages} goToPage={applyFilters} filters={filters} setFilters={setFilters} refresh={toggle} />
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                </CSSTransition>
            </TransitionGroup>
            {((blog && blog.isPending) || user.isPending) && <LoadingIndicator />}
        </div>
    )
}