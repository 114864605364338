import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import finish from '../../../assets/img/finish-new-member.svg';
import { career_canvas_questionnaire } from "../../../core/constants";

export const FinishSection = ({ questionnaireCode, questionnaireName }) => {
    const leadership_canvas_on = useSelector(state => state?.systemCheck?.data?.data?.leadership_canvas_on)

    return (
        <>
            <div className="bg-white my-5 rounded-lg pb-md-4 px-md-5 pb-md-5 pt-md-2 content-ready content-view">
                <div className="px-md-4">
                    <div className="d-none d-sm-none d-md-block mb-md-5 encabezado">
                        <div className="mb-3 mb-md-0 pt-md-2 pb-md-1 d-flex align-items-center">
                            <h6 className="d-none d-sm-none d-md-block mb-0 title-underline">{questionnaireName}</h6>
                        </div>
                        <span className="border border-info d-block"></span>
                    </div>

                    <Row className="text-center">
                        <Col md={{ size: 12 }}>
                            <h2 className="text-reset position-relative" style={{ zIndex: '10' }}>Thank you for completing this Discovery.</h2>
                            <div className="px-2 position-relative mt-md-n5 mb-n2"><img src={finish} alt="new_member_discovery" className="img-fluid mx-auto" style={{ zIndex: '1', maxWidth: '380px' }} /></div>
                            <p className="position-relative">Your answers will help us provide you with tools and resources to help you on your career journey.<br />
                                As you grow and unlock your potential, be sure to come back and update your answers!</p>
                            {questionnaireCode === career_canvas_questionnaire.QUESTIONNAIRE_CODE && leadership_canvas_on ?
                                <NavLink to="/career-canvas" className="btn btn-primary text-uppercase px-4 rounded-pill mt-3">BACK</NavLink>
                                :
                                <NavLink to="/home" className="btn btn-primary text-uppercase px-4 rounded-pill mt-3">BACK TO HOME</NavLink>
                            }
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}
