import { DEFAULT_SESSION_ID } from '../constants';

import { companyCardsListIdForFind, companyCardsListIdForSearch } from './utils';

export const companyCardsListActions = {

  FETCH_COMPANY_CARDS_FAILED: 'FETCH_COMPANY_CARDS_FAILED',
  FETCH_COMPANY_CARDS_SUCCESS: 'FETCH_COMPANY_CARDS_SUCCESS',
  FETCH_COMPANY_CARDS_PENDING: 'FETCH_COMPANY_CARDS_PENDING',
  SEARCH_COMPANY_CARDS: 'SEARCH_COMPANY_CARDS',

  fetchCompanyCardsFailed: error => ({
    type: companyCardsListActions.FETCH_COMPANY_CARDS_FAILED,
    payload: error
  }),

  fetchCompanyCardsSuccess: (companyCardsListId, data) => ({
    type: companyCardsListActions.FETCH_COMPANY_CARDS_SUCCESS,
    payload: {
      data,
      companyCardsListId
    }
  }),

  fetchCompanyCardsPending: companyCardsListId => ({
    type: companyCardsListActions.FETCH_COMPANY_CARDS_PENDING,
    payload: {
      companyCardsListId
    }
  }),

  searchCompanyCards: (filters, page) => ({
    type: companyCardsListActions.SEARCH_COMPANY_CARDS,
    payload: {
      companyCardsListId: companyCardsListIdForSearch(filters),
      filters,
      page
    }
  }),

};


export const companyCardsListRequestActions = {
  failed: companyCardsListActions.fetchCompanyCardsFailed,
  success: companyCardsListActions.fetchCompanyCardsSuccess,
  pending: companyCardsListActions.fetchCompanyCardsPending
};
