import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row, InputGroup, Input, Form, FormGroup, CustomInput, FormText } from 'reactstrap';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { profileActions } from "../../../core/profile/actions";
import LoadingIndicator from "../loading-indicator";
import session from '../../../core/session';
import history from "../../../core/history";
import { userActions } from "../../../core/users";

export const StartYourProfile = ({ nextStep }) => {
    const dispatch = useDispatch()
    const { user, profile } = useSelector(state => state);
    const [disabled, setDisabled] = useState(false);
    const [submited, setSubmited] = useState(false);

    const [inputValues, setInputValues] = useState({
        story: '',
        storyLenght: 0,
        picture: null
    })

    const [formErrors, setFormErrors] = useState({
        story: '',
        picture: '',
    });

    useEffect(() => {
        setDisabled(!formValid());
    }, [inputValues])

    useEffect(() => {
        if(!profile.isPending && submited) {
            dispatch(userActions.getProfile())
            if(!user.isPending) {
                skip()
            }
        }
    }, [profile, user])

    const formValid = () => {
        let valid = true;

        Object.values(formErrors).forEach(val => {
            val.length > 0 && (valid = false);
        });

        return valid;
    };

    const handleInputChange = async ({ target }) => {
        const { name, value } = target
        let allInputValues = inputValues
        let newValue = value

        switch (name) {
            case 'story':
                break;

            case 'picture':
                if (value) {
                    if (validatePicture(target))
                        newValue = target.files[0];
                } else {
                    newValue = null
                    setFormErrors({
                        ...formErrors,
                        ['picture']: "",
                    })
                }
                break;

            default:
                break;
        }

        setInputValues({
            ...allInputValues,
            [name]: newValue
        });
    }

    const validatePicture = (target) => {
        const [{ size, type }] = target.files; console.log(type)
        //type
        let validTypes = ['image/gif', 'image/jpeg', 'image/png']
        if (validTypes.includes(type)) {
            setFormErrors({
                ...formErrors,
                ['picture']: "",
            })
        } else {
            setFormErrors({
                ...formErrors,
                ['picture']: "Type invalid (png, jpg, gif)",
            })
            return false
        }

        //size
        if (size / 1024 > 5120) {
            setFormErrors({
                ...formErrors,
                ['picture']: "Max: 5MB",
            })
            return false
        } else {
            setFormErrors({
                ...formErrors,
                ['picture']: "",
            })
            return true
        }
    }

    const submitStartProfile = (e) => {
        e.preventDefault()
        setSubmited(true)

        if (formValid) {
            if(inputValues.picture) dispatch(profileActions.updatePicture(inputValues.picture))
            if(inputValues.story?.length) dispatch(profileActions.updateStory(inputValues.story))
            if(!inputValues.picture && !inputValues.story?.length) skip()
        }

    }

    const skip = () => {
        session.updateLastAccessToLeadershipCanvasProfile()
        nextStep && nextStep()
        if(inputValues.picture) {
            history.go(0)
        }
    }

    return (
        <div className="pt-md-5 mx-n3 mx-md-auto">
         <Form onSubmit={(e) => { e.preventDefault(); submitStartProfile(e) }}>
             <Row className="ml-0">
                 <Col xs="12"><h3 className='mb-2 font-weight-normal display-6 rubik'>Let's Start Your Profile</h3></Col>
                 <Col md='3' className="mt-4 text-center">
                     <div className="upload-avatar position-relative d-inline-block mx-auto">
                         <div className="profile-avatar profile-avatar-big d-inline-block">
                             <div><img src={(!formErrors.picture && inputValues.picture) ? URL.createObjectURL(inputValues.picture) : user?.avatar} width="140" height="140" alt={user.first_name + ' ' + user.last_name} /></div>
                         </div>
                         <CustomInput name="picture" id="inputPicture" type="file" label=" " onChange={(e) => handleInputChange(e)} accept=".jpg,.png,.gif" className="upload-picture" />
                         <FormText color="danger" className="position-absolute">{formErrors.picture}</FormText>
                     </div>
                 </Col>
                 <Col md="9" className='my-4'>
                     <h2> What's your story? What's your secret sauce or unfair advantage? </h2>
                     <p className='text-muted'>
                        What's the elevator pitch you'd give if you had only a few minutes with a potential employer?
                     </p>
                     <FormGroup className="mb-0">
                         <InputGroup>
                             <Input
                                 rows='5'
                                 type='textarea'
                                 placeholder='Type Here'
                                 id='inputStory'
                                 name='story'
                                 onChange={(e) => { handleInputChange(e); }}
                                 defaultValue={user?.profile_story}
                                 maxLength='400'
                                 minLength='10'
                                 onInvalid={(e) => { e.target.setCustomValidity('Minimum: 10 characters.') }}
                                 onInput={(e) => { e.target.setCustomValidity('') }}
                             />
                         </InputGroup>
                         {
                             <p className="text-primary mb-0" align="right">
                                 <small>{`${inputValues?.story?.length || user?.profile_story?.length || 0 }  / 400 characters`}</small>
                             </p>
                         }
                         <div className="my-2">
                             <button type="submit" disabled={disabled} className="btn btn-primary text-uppercase rounded-pill px-5"> Continue </button>
                             <button className="btn btn-outline-primary text-uppercase rounded-pill px-5 ml-3" onClick={() => skip()}> Skip </button>
                         </div>
                     </FormGroup>
                 </Col>
             </Row>
         </Form>
         { (profile?.isPending || user?.isPending) && <LoadingIndicator /> }
        </div>
    )
}