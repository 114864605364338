import { createSelector } from 'reselect';
import { getCompanyCards } from '../company-cards/selectors';

export function getCompanyCardsList(state) {
  return state.company_cards_lists;
}

export function getCompanyCardsListById(state, companyCardsListId) {
  return getCompanyCardsList(state).get(companyCardsListId);
}

//=====================================
//  MEMOIZED SELECTORS
//-------------------------------------

export const getCurrentCompanyCardsList = createSelector(
  getCompanyCardsList,
  company_cards_lists => company_cards_lists.get(company_cards_lists.get('currentCompanyCardsListId'))
);

export const getCompanyCardsForCurrentCompanyCardsList = createSelector(
  getCurrentCompanyCardsList,
  getCompanyCards,
  ( company_cards_lists, CompanyCards) => {
    return company_cards_lists?.itemsId.map( id => CompanyCards.get(`${id}`));
  }
);
