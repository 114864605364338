import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, Input, Form, FormGroup, Container, FormText, CustomInput } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import thanks_icon from '../../../assets/img/thanks-popup.svg'


export const ThankModal = ({ open, toggle }) => {

    return (
        <Modal isOpen={open} toggle={toggle} className="modal-dialog-summary float-right">
            <ModalHeader toggle={toggle}></ModalHeader>
            <ModalBody>               
                <Row className="justify-content-md-center text-center pt-3">
                    <Col md="8">
                        <h2>Thanks! Your information<br/>has been successfully received.</h2>
                        <img src={thanks_icon} className="img-fluid mx-auto my-3" width="80"/>
                        <button type="button" onClick={() => toggle()} className="btn text-uppercase rounded-pill btn-outline-primary px-md-5 mt-2 mx-auto d-block">Close</button>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
}