import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Col, Row  } from 'reactstrap';
import { useDispatch } from 'react-redux';
import ReactPlayer from 'react-player/youtube';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

import { trackingActions } from '../../../core/tracking';

export const ModalVideo = ({ toggle, open, video, videos, index }) => {
    const dispatch = useDispatch()

    useEffect(() => {
        setCurrentIndex(index)
    }, [toggle])

    const [currentIndex, setCurrentIndex] = useState(index)

    const goNext = () => {
        if(videos && videos.length -1 >= currentIndex) {
            setCurrentIndex(currentIndex+1);
        }
    }

    const goPrevious = () => {
        if(videos && videos.length > 0) {
            setCurrentIndex(currentIndex+-1);
        }
    }

    const onStartVideo = () => {
        dispatch(trackingActions.saveTrackingEvent({category: 'Content Library', action: 'Start Video'}))
    }

    return (

        <Modal isOpen={open} toggle={toggle} size="lg">
            <ModalHeader className="pt-4 px-4 pb-0" tag="div" toggle={toggle} style={{borderBottom:'0'}} >
                {videos[currentIndex] && videos[currentIndex].title && <h5><b>{ videos[currentIndex].title }</b></h5>}
                {videos[currentIndex] && videos[currentIndex].text && <div className="h5" dangerouslySetInnerHTML={{ __html: videos[currentIndex].text }}></div>}
            </ModalHeader>
            <ModalBody className="p-4">
                <div>
                    <div className="embed-responsive embed-responsive-16by9 mb-4">
                    <ReactPlayer className="embed-responsive-item" url={`http://www.youtube.com/embed/${videos[currentIndex] && videos[currentIndex].code}`}
                        playing={false}
                        width='100%'
                        height='100%'
                        config={{
                            youtube: {
                                playerVars: { showinfo: 0, embedOptions: 0, rel: 0 }
                            },
                        }}
                        onStart={() => { onStartVideo() }}
                    />
                    </div>

                    {videos.length > 1 &&
                    <Row>
                        <Col sm={{ size: '4', offset: 4 }}>
                            <div className="paginador d-flex justify-content-between">
                                <Button color="link" className="text-decoration-none" disabled={currentIndex === 0} onClick={() => goPrevious() }><i className="mr-2 h3 align-middle"><FontAwesomeIcon icon={faAngleLeft} /></i><span className="text-black font-weight-bold" style={{letterSpacing: 'normal'}}>PREVIUS</span></Button>
                                <Button color="link" className="text-decoration-none" disabled={videos.length -1 == currentIndex} onClick={() => goNext() }><span className="text-black font-weight-bold" style={{letterSpacing: 'normal'}}>NEXT</span><i className="ml-2 h3 align-middle"><FontAwesomeIcon icon={faAngleRight} /></i></Button>
                            </div>
                        </Col>
                    </Row>
                    }
                </div>
            </ModalBody>
        </Modal>

    )
}