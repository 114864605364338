import { call, fork, takeEvery } from 'redux-saga/effects';
import { fetchEvents } from '../api';
import { eventsActions } from './actions';

export function* eventsLoad({ payload }) {
    const { currentPage } = payload;
    yield call(fetchEvents, currentPage);
}

//Watchers
export function* watchEventsLoad() {
    yield takeEvery(eventsActions.LOAD_EVENTS, eventsLoad)
}

export function* watchMoreEventsLoad() {
    yield takeEvery(eventsActions.MORE_EVENTS, eventsLoad)

}


//Root
export const eventsSaga = [
    fork(watchEventsLoad),
    fork(watchMoreEventsLoad)
]
