import { call, fork, select, takeLatest } from 'redux-saga/effects';

import { fetchCompanyCardsIndustries, fetchCompanyCardsCertifications } from '../api';

import { companyCardsFiltersActions } from './actions';

export function* fetchCompanyCardsFiltersIndustries({ payload }) {
    const { companyCardsFiltersId } = payload;

    yield call(fetchCompanyCardsIndustries, companyCardsFiltersId);
}

export function* fetchCompanyCardsFiltersCertifications({ payload }) {
    const { companyCardsFiltersId } = payload;

    yield call(fetchCompanyCardsCertifications, companyCardsFiltersId);
}

// //=====================================
// //  WATCHERS
// //-------------------------------------

export function* watchFetchCompanyCardsIndustries() {
    yield takeLatest(companyCardsFiltersActions.FETCH_COMPANY_CARDS_FILTERS_INDUSTRIES, fetchCompanyCardsFiltersIndustries)
}

export function* watchFetchCompanyCardsCertifications() {
    yield takeLatest(companyCardsFiltersActions.FETCH_COMPANY_CARDS_FILTERS_CERTIFICATIONS, fetchCompanyCardsFiltersCertifications)
}

//=====================================
//  ROOT
//-------------------------------------

export const companyCardsFiltersSagas = [
    fork(watchFetchCompanyCardsIndustries),
    fork(watchFetchCompanyCardsCertifications),
];
