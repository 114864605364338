import { jobsOutstandingActions } from './actions';
import { JobsOutstanding } from './jobsOutstanding';


export const jobsOutstandingReducer = (state = new JobsOutstanding(), { payload, type }) => {

    switch (type) {
        case jobsOutstandingActions.FETCH_JOBS_OUTSTANDING_FAILED:
            return state.merge({
                isPending: false,
                error: payload
            });

        case jobsOutstandingActions. FETCH_JOBS_OUTSTANDING_SUCCESS:
            return state.merge({
                data: payload.data,
                error: null,
                isPending: false,
            });

        case jobsOutstandingActions.FETCH_JOBS_OUTSTANDING_PENDING:
            return state.set('isPending', true);
        default:
            return state;
    }
}
