import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";

import { convertToSlug } from "../../../core/helper";

import { leadershipCanvasReportActions, getLeadershipCanvasReport } from "../../../core/leadership-canvas-report";

import LoadingIndicator from "../../components/loading-indicator/loading-indicator";
import { Header } from "../../components/leadershipCanvasReport";
import { AboutYou } from "../../components/leadershipCanvasReport";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { Container, Col, Row } from 'reactstrap';

//sections
import { ThinkingAndLearningSection, ProfessionalExperienceSection, WorkPreferencesSection, WorkingStyleSection, LeadershipSection } from "../../components/leadershipCanvasReport";

const animationClass = {
    appearActive: 'animated slideInRight',
    enter: 'animated',
    enterActive: 'animated slideInRight',
    exit: 'animated',
    exitActive: 'animated slideOutLeft',
}

export const LeadershipCanvasReport = () => {
    const dispatch = useDispatch()
    const { token_id } = useParams()
    const carrerCanvasReport = useSelector(state => getLeadershipCanvasReport(state))

    useEffect(() => {
        dispatch(leadershipCanvasReportActions.loadLeadershipCanvasReport(token_id))
    }, [])

    return (
        <>
            <Header />
            {carrerCanvasReport && !carrerCanvasReport.isPending && carrerCanvasReport.user &&
                <TransitionGroup className='view overflow-hidden position-relative'>
                    <CSSTransition classNames={animationClass} timeout={{ enter: 1000, exit: 1000 }} appear >
                        <>
                            <button className="btn-top rounded-circle bg-white btn shadow" onClick={() => { window.scroll({ top: 0, left: 0, behavior: 'smooth' }); }}><FontAwesomeIcon size="2x" icon={faArrowUp} /></button>
                            {carrerCanvasReport.user && <AboutYou user={carrerCanvasReport.user} isCurrentUser={false} />}
                            <div className="leadership-reports bg-white">
                                <section id="thinking-learning">
                                    <ThinkingAndLearningSection sectionCode={convertToSlug('Thinking & Learning')} />
                                </section>
                                <section id="professional-experience">
                                    <ProfessionalExperienceSection sectionCode={convertToSlug('Professional Experience')} />
                                </section>
                                <section id="work-preferences">
                                    <WorkPreferencesSection sectionCode={convertToSlug('Work Preferences')} />
                                </section>
                                <section id="working-style">
                                    <WorkingStyleSection sectionCode={convertToSlug('Working Style')} />
                                </section>
                                <section id="leadership">
                                    <LeadershipSection sectionCode={convertToSlug('Leadership')} />
                                </section>
                            </div>
                        </>
                    </CSSTransition>
                </TransitionGroup>
            }
            
            <footer className="text-muted d-none d-sm-none d-md-flex my-3 justify-content-center text-center text-md-left">
                <a href="https://collective.mytrueadvance.com/front/welcome" className="text-muted welcome-link mr-md-5 d-block d-md-inline">Would you like your own Career Canvas? Click here to login to My True Advance and get started!</a>
            </footer>

            {carrerCanvasReport.isPending &&
                <LoadingIndicator />
            }
        </>
    )
}