import { call, fork, takeEvery } from 'redux-saga/effects';
import { fetchPosts, fetchBlogHome, fetchPost, fetchPostsByCategorySlug } from '../api';
import { blogActions } from './actions';

export function* searchPosts({ payload }) {
    console.log(payload)
    yield call(fetchPosts, payload);
}

export function* blogHomeLoad() {
    yield call(fetchBlogHome);
}

export function* fetchPostById({payload}) {
    const { id } = payload
    yield call(fetchPost, id);
}

export function* fetchByCategorySlug({payload}) {
    const { slug } = payload
    yield call(fetchPostsByCategorySlug, slug);
}

//Watchers
export function* watchSearchPosts() {
    yield takeEvery(blogActions.SEARCH_POSTS, searchPosts)
}

export function* watchBlogHomeLoad() {
    yield takeEvery(blogActions.LOAD_BLOG_HOME, blogHomeLoad)
}

export function* watchFetchPost() {
    yield takeEvery(blogActions.FETCH_BLOG_POST, fetchPostById)
}

export function* watchFetchPostsByCategorySlug() {
    yield takeEvery(blogActions.FETCH_POSTS_BY_CATEGORY_SLUG, fetchByCategorySlug)
}

//Root
export const blogSaga = [
    fork(watchSearchPosts),
    fork(watchBlogHomeLoad),
    fork(watchFetchPost),
    fork(watchFetchPostsByCategorySlug)
]
