import React from "react";
import { Col, Row } from 'reactstrap';
import session from '../../../core/session';

export const LeadershipWelcome = ({ nextStep }) => {

    return (
        <div className="rounded-lg content-view mx-n3 mx-md-auto">
        <Row className="justify-content-center p-4 m-0">
            <Col md="9" className="my-4">
                <h3 className="mb-3 font-weight-normal display-6 rubik">Welcome to your Career Canvas</h3>
                <p>
                    We’re so excited that you’ve decided to embark on this journey of self-discovery in pursuit of career advancement!
                </p>
                <p className="text-muted">
                    Through the next few modules, we’ll begin asking you questions that will help us (and you!) understand what makes you special. We’ll explore your leadership approach, how you approach challenges, the work environments that bring the best out of you and more. At the end of this journey, you’ll know exactly what it is that makes you unique as a professional. And as soon as you begin sharing your Career Canvas with hiring managers, collaborators, or your current manager, they will too! 
                </p>
                <div className="text-center">
                    <button className="btn btn-primary text-uppercase rounded-pill px-5" onClick={() => { session.updateLastAccessToLeadershipCanvasProfile(); nextStep && nextStep() }} >Let's Go</button>
                </div>
            </Col>
        </Row>
        </div>
    )
}