import React, { useState } from "react";
import { useSelector } from "react-redux";

import { career_canvas_questionnaire } from "../../../../core/constants";
import { getLeadershipCanvasQuestionByCode } from "../../../../core/leadership-canvas";
import { DynamicSection } from "./DynamicSection";

//reports
import { ChallengeApproachReport  } from "../index"

//icons
import iconWorkingStyle from "../../../../assets/img/icon-working-style.svg"
import iconApproaching from "../../../../assets/img/icon-approaching.svg"

//questionnaireView
import { QuestionnairePage } from "../../../pages/home/questionnaires/QuestionnairePage";

export const WorkingStyleSection = ({ goBack, sectionCode }) => {
    const { user, leadershipCanvas } = useSelector(state => state)
    const item_AC = useSelector(state => getLeadershipCanvasQuestionByCode(state, career_canvas_questionnaire.APPROACHING_CHALLENGES.questions.approaching_challenges.code))
    const [ showQuestionnaireReview, setShowQuestionnaireReview ] = useState(false)
    const intro = {
        code: sectionCode,
        text: `This section highlights the interpersonal components of how you operate. Explore the area below to figure out which working style best describes you. And remember, there is no right way and there is no wrong way - there’s only your way.`,
        image: iconWorkingStyle
    }
    const items = [
        {
            title: 'Approaching Challenges',
            text: "When it comes to approaching challenges, there is no right way and no wrong way. There's only your way. Every professional approaches challenges in their own unique way. Your challenge approach might be informed by your past experiences and successes in overcoming challenges and they might be shaped the types of actions that most energize you. Identify your challenge approach below and we'll help you understand how best to put your approach into action.",
            show_btn_view: leadershipCanvas.leadership_discovery_done,
            show_btn_unlock: !leadershipCanvas.leadership_discovery_done,
            unlock_url: `/front/questionnaire/${leadershipCanvas?.questionnaire_id}`,
            bottom_text: 'How new challenges are approached and resolved',
            image: iconApproaching,
            report: <ChallengeApproachReport challengeApproach={item_AC} toggleShowReview={() => {setShowQuestionnaireReview(true)}} />,
            questionnaireView: <QuestionnairePage id={leadershipCanvas?.questionnaire_id} animation={false} />,
            questionnaireId: leadershipCanvas?.questionnaire_id,
            showQuestionnaireReview,
        },
    ]

    return (
        <DynamicSection intro={intro} items={items} goBack={goBack} />
    )
}