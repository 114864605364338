import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import LoadingIndicator from '../components/loading-indicator';
import { HomeOneColumnPage } from './HomeOneColumnPage';
import { HomeTwoColumnsPage } from './HomeTwoColumnsPage';
import { FooterHomePage } from '../components/common/FooterHomePage';
import { videosActions } from '../../core/videos';
import { PopupHome } from '../components/popups/popupHome';
import { userActions } from '../../core/users';


export const HomePage = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state);
  //const { videos } = useSelector(state => state);
  const { pdf } = useSelector(state => state);
  const nextPopup = () => dispatch(userActions.nextPopupIndex());

  useEffect(() => {
    //dispatch(videosActions.loadVideos())
  }, [])

  const layout_twocolumns = ((user.account && (user.account.events_count > 0 || user.account.posts_count > 0 ))); // || videos.data.length > 0

  let layout;
  let oneColumn;
  if (layout_twocolumns) {
    layout = "two-columns";
    oneColumn = false
  } else {
    layout = "one-column";
    oneColumn = true
  }


  return (
    <>
      <div className="view overflow-hidden content-view-full w-100 d-md-flex flex-md-column">
        <Container className={`py-0 pb-md-0 ${layout_twocolumns ? "HomeTwoColumnPage" : "HomeOneColumnPage"}`} >

          {layout_twocolumns ? <HomeTwoColumnsPage layoutformat={layout} oneColumn={oneColumn} /> : <HomeOneColumnPage layoutformat={oneColumn} oneColumn={true}/>}

            {
                user.accept_terms && !user.must_change_pwd && user.account && user.account.popups && user.account.popups.map((popup, index) => {
                    return <PopupHome key={popup.id} isOpen={ user.last_popup_index === index } toggle={nextPopup} text={popup.text} show_command_button={popup.show_command_button} command_button_text={popup.command_button_text} command_button_link={popup.command_button_link} new_tab={popup.open_link_In_a_new_tab} />
                })
            }

          {
            user.isPending &&
            <LoadingIndicator />
          }
          {
            pdf && pdf.isPending &&
            <LoadingIndicator />
          }

        </Container>

        <Container className="mt-auto">
          <Row>
            <Col md={{ size: 12, offset: 0 }} lg={{ size: 10, offset: 1 }}>
              <FooterHomePage />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}



