import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Media, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom'
import { TransitionGroup, SwitchTransition, CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faGlobe, faEnvelope, faStar, faPlus, faEllipsisV, faPencilAlt, faTimesCircle, faUser, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import classNames from 'classnames';
import { List } from 'immutable';

import { QUADRANTS_ANALYTIC, QUADRANTS_INNOVATIVE, QUADRANTS_RELATIONAL, QUADRANTS_PROCEDURAL, QUADRANTS_ALL, DEFAULT_SESSION_ID } from '../../../core/constants'
import { teamActions } from '../../../core/teams'

import graphic_report from '../../../assets/img/graphic-report.jpg'
import graphic_report_mask from '../../../assets/img/graphic-report-mask.png'
import { teamsReportListActions } from '../../../core/teams-report';
import { teamsMembersListActions } from '../../../core/teams-members-list';

class MemberDetail extends Component {

  constructor(props) {
    super(props)

    this.state = {
      activeTab: 'talents',
      animationClassTabs: {
        enter: 'animated',
        enterActive: 'animated fadeIn',
        exit: 'animated',
        exitActive: 'animated fadeOut',
      },
      paddingTop: 0,
      paddingBottom: 0,
      show_modal: false
    }

    this.toggle = this.toggle.bind(this);
    this.updateSize = this.updateSize.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.toggleTalents = this.toggleTalents.bind(this);


  }

  componentDidMount() {
    window.addEventListener('resize', this.updateSize);
    window.addEventListener('load', this.updateSize)
    setTimeout(() => {
      this.updateSize();
    }, 500)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateSize);
    window.removeEventListener('load', this.updateSize);
  }
  handleModal = (e, confirm) => {
    const { show_modal } = this.state;
    const { member, loadTeamsReport, team_id, loadTeamMembers } = this.props;
    if (confirm && show_modal) {
      this.handleActionMember({ section: 'remove-member', member_id: member.id })
      loadTeamsReport(team_id);
      loadTeamMembers(team_id)

    }

    this.setState({ show_modal: !show_modal })
  }

  handleActionMember({ section = 'members-list', member_id = null }) {

    const { hanndleNextSection, setLeaderTeamMember, removeTeamMember, team } = this.props
    if (section === 'set-member-leader') {
      setLeaderTeamMember(team.id, member_id)
    }

    if (section === 'remove-member') {
      removeTeamMember(team.id, member_id)
    }

    hanndleNextSection({ section, member_id })

  }

  toggle = (tab) => {
    const { activeTab } = this.state
    if (activeTab !== tab)
      this.setState({ activeTab: tab })
  }

  toggleTalents = (quadrant_name) => {
    const { member, hanndleNextSection } = this.props;

    hanndleNextSection({ section: 'detail-member-talens', member_id: member.id, quadrant_name })
  }


  updateSize() {
    const { paddingBottom, paddingTop } = this.state
    //const top = document.getElementById('lists-top').clientHeight
    //const bottom = document.getElementById('lists-top').clientHeight
    const media = this.image_bg.clientHeight / 4;
    let new_paddingTop = 0;
    let new_paddingBottom = 0;

    if (media <= this.div_lists_top.clientHeight) {
      if (this.image_bg.clientWidth < 400)
        new_paddingTop = media + (this.div_lists_top.clientHeight / 2);
      else
        new_paddingTop = media - (this.div_lists_top.clientHeight / 2);
    }

    if (this.image_bg.clientWidth < 400)
      new_paddingBottom = media + (this.div_lists_bottom.clientHeight / 2);
    else
      new_paddingBottom = media - (this.div_lists_bottom.clientHeight / 2);

    this.setState({ paddingBottom: new_paddingBottom, paddingTop: new_paddingTop })
  }


  render() {
    const { animationClassTabs, activeTab, paddingBottom, paddingTop, show_modal } = this.state
    const { hanndleNextSection, member, quadrants, isMember, isLeader, team, enable_edit } = this.props

    const mind_patterns = member.mind_pattern && member.mind_pattern.split("").map((mp, k) => <div key={k} className={`rounded-circle mx-1 mind-pattern mind-pattern-${mp.toLowerCase()}`}><span>{mp}</span></div>);

    let list_items_analytic = []
    let list_items_innovative = []
    let list_items_relational = []
    let list_items_procedural = []
    let list_items_all = []
    if (member.talents) {
      member.talents.map(s_item => {
        const item = quadrants.find(quadrant => quadrant.id === s_item.quadrant_id);
        if (item && item.name === QUADRANTS_ANALYTIC) {
          list_items_analytic.push(<h6 key={'qitem_' + s_item.item_id} className="text-reset text-capitalize mb-0 d-flex align-items-center flex-md-row-reverse">
            <i className="icon mr-1 mr-md-0 ml-md-1 mt-1"><svg width="10" height="10" className="mb-1"><circle cx="5" cy="5" r="5" fill={item.color} /></svg></i>
            <span className="text-nowrap" style={{ lineHeight: '22px'}}>{s_item.item_name}</span>
          </h6>
          )
        }
        if (item && item.name === QUADRANTS_INNOVATIVE) {
          list_items_innovative.push(<h6 key={'qitem_' + s_item.item_id} className="text-reset text-capitalize mb-0 d-flex align-items-center flex-row-reverse flex-md-row">
            <i className="icon float-right ml-1 ml-md-0 mr-md-1 mt-1"><svg width="10" height="10" className="mb-1"><circle cx="5" cy="5" r="5" fill={item.color} /></svg></i>
            <span className="text-nowrap" style={{ lineHeight: '22px'}}>{s_item.item_name}</span>
          </h6>)
        }
        if (item && item.name === QUADRANTS_PROCEDURAL) {
          list_items_procedural.push(<h6 key={'qitem_' + s_item.item_id} className="text-reset text-capitalize mb-0 d-flex align-items-center flex-md-row-reverse">
            <i className="icon mr-1 mr-md-0 ml-md-1 mt-1"><svg width="10" height="10" className="mb-1"><circle cx="5" cy="5" r="5" fill={item.color} /></svg></i>
            <span className="text-nowrap" style={{ lineHeight: '22px'}}>{s_item.item_name}</span>
          </h6>)
        }
        if (item && item.name === QUADRANTS_RELATIONAL) {
          list_items_relational.push(<h6 key={'qitem_' + s_item.item_id} className="text-reset text-capitalize mb-0 d-flex align-items-center flex-row-reverse flex-md-row">
            <i className="icon float-right ml-1 ml-md-0 mr-md-1 mt-1"><svg width="10" height="10" className="mb-1"><circle cx="5" cy="5" r="5" fill={item.color} /></svg></i>
            <span className="text-nowrap" style={{ lineHeight: '22px'}}>{s_item.item_name}</span>
          </h6>)
        }
        if (item && item.name === QUADRANTS_ALL) {
          list_items_all.push(<h6 key={'qitem_' + s_item.item_id} className="text-reset text-capitalize mb-0 text-nowrap">
            <i className="icon mr-1 mr-md-0 ml-md-1 mt-1"><svg width="10" height="10" className="mb-1"><circle cx="5" cy="5" r="5" fill={item.color} /></svg></i>
            <span className="text-nowrap" style={{ lineHeight: '22px'}}>{s_item.item_name}</span>
          </h6>)
        }
      })
    }

    let button_leader = null
    let button_remove_member = null

    if (isMember && enable_edit) {
      button_leader = <button onClick={(e) => { e.preventDefault(); this.handleActionMember({ section: 'set-member-leader', member_id: member.id }) }}
        className="btn text-uppercase rounded-pill btn-outline-primary mx-auto px-md-4 ml-md-3 mb-3 mb-md-0"><i className="mr-3 h5 text-reset align-middle"><FontAwesomeIcon icon={faStar} /></i>Set as Team Leader</button>
      if (isLeader)
        button_leader = <button onClick={(e) => { e.preventDefault(); this.handleActionMember({ section: 'set-member-leader', member_id: member.id }) }}
          className="btn text-uppercase rounded-pill btn-outline-primary mx-auto px-md-4 ml-md-3 mb-3 mb-md-0"><i className="mr-3 h5 text-reset align-middle"><FontAwesomeIcon icon={faTimesCircle} /></i>Remove as Team Leader</button>
    }

    if (isMember && enable_edit) {
      button_remove_member = <button onClick={(e) => { e.preventDefault(); this.handleModal() }}
        className="btn text-uppercase rounded-pill btn-outline-primary mx-auto px-md-4 mb-3 mb-md-0">
        <i className="mr-3 h5 text-reset align-middle"><FontAwesomeIcon icon={faTimesCircle} /></i>Remove From Team</button>
    }

    return (
      <>
        <div className="d-md-flex justify-content-between mb-3 align-items-center">
          <Media className="align-items-center mt-2">
            <Media left className="pr-3 cursor-pointer">
              <img className="media-object" width="40" height="40" src={member.avatar} alt={member.first_name} />
            </Media>
            <Media body>
              <Media heading tag="p" className="mb-n1 mt-1">{(member.first_name) ? `${member.first_name} ${member.last_name}` : member.username}</Media>
              <p className="text-reset line-height-normal mb-2"><small>{member.seniority ? member.seniority.name : ''}</small></p>
            </Media>
          </Media>

          <div>
            {!isMember && <button onClick={(e) => { e.preventDefault(); hanndleNextSection({ section: 'add-member', member_id: member.id }) }}
              className="btn text-uppercase rounded-pill btn-outline-primary mx-auto px-md-4 mb-3">
              <i className="circle-icon mr-3 text-reset align-middle"><FontAwesomeIcon icon={faPlus} /></i>Add To Team</button>}

            {button_remove_member}

            {button_leader}
          </div>

        </div>

        <Nav className="mb-3 mb-md-4 text-center text-uppercase nav-xs-justified align-items-end nav-tabs-alt" tabs>
          <NavItem>
            <NavLink className={classNames({ active: activeTab === 'talents', 'px-md-4': true })} onClick={() => { this.toggle('talents'); }}>
              Smart Profile
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classNames({ active: activeTab === 'info', 'px-md-4': true })} onClick={() => { this.toggle('info'); }}>
              Information
            </NavLink>
          </NavItem>
        </Nav>

        <div className="content-tabs" style={{ overflow: 'hidden' }}>
          <SwitchTransition>
            <CSSTransition classNames={animationClassTabs} timeout={{ enter: 100, exit: 100 }} key={activeTab} >
              <TabContent activeTab={activeTab}>
                <TabPane tabId="talents">

                  <div className="graphic-map graphic-map-sm mx-auto mb-3" style={{ maxWidth: '550px'}}>
                    <div className="graphic-map-list overflow-hidden mx-auto">
                      <Row id="lists-top" className="d-flex list-top justify-content-between align-items-end position-relative">
                        <div onClick={() => this.toggleTalents(QUADRANTS_ANALYTIC)} ref={e => this.div_lists_top = e} className="col-6 cursor-pointer align-items-md-end justify-content-md-start d-md-flex flex-md-column">{list_items_analytic}</div>
                        <Col onClick={() => this.toggleTalents(QUADRANTS_INNOVATIVE)} xs={{ size: 6 }} className="cursor-pointer align-items-md-start justify-content-md-start d-md-flex flex-md-column">{list_items_innovative}</Col>
                      </Row>

                      <div className="bg-map-dk mx-auto">
                      <div className="graphic-map-img mx-auto" style={{ width: '190px'}}>
                        <div className="mx-auto text-center graphic-list-all-talent">{list_items_all}</div>
                        <img ref={e => this.image_bg = e} src={graphic_report} useMap="#image_map" className="img-fluid" style={{ opacity: 0, minHeight: '100%' }} />
                        <map name="image_map">
                          <area onClick={() => this.toggleTalents(QUADRANTS_ANALYTIC)} alt="Analytic" title="Analytic" shape="polygon" coords="1,2,96,0,95,22,57,33,29,62,20,94,0,94" />
                          <area onClick={() => this.toggleTalents(QUADRANTS_INNOVATIVE)} alt="Innovative" title="Innovative" shape="polygon" coords="97,21,97,0,188,0,190,95,169,96,161,57,131,31" />
                          <area onClick={() => this.toggleTalents(QUADRANTS_RELATIONAL)} alt="Relational" title="Relational" shape="polygon" coords="98,167,131,161,158,132,169,98,189,97,187,189,96,188" />
                          <area onClick={() => this.toggleTalents(QUADRANTS_PROCEDURAL)} alt="Procedural" title="Procedural" shape="polygon" coords="1,95,20,94,29,128,61,162,96,168,95,189,0,189" />
                          <area onClick={() => this.toggleTalents(QUADRANTS_ALL)} alt="All" title="All" shape="circle" coords="95,95,70" />
                        </map>
                      </div>
                      </div>
                      <Row id="lists-bottom" className="d-flex list-bottom justify-content-between align-items-start position-relative">
                        <div onClick={() => this.toggleTalents(QUADRANTS_PROCEDURAL)} ref={e => this.div_lists_bottom = e} className="col-6 cursor-pointer align-items-md-end justify-content-md-end d-md-flex flex-md-column">{list_items_procedural}</div>
                        <Col onClick={() => this.toggleTalents(QUADRANTS_RELATIONAL)} xs={{ size: 6 }} className="cursor-pointer align-items-md-start justify-content-md-end d-md-flex flex-md-column">{list_items_relational}</Col>
                      </Row>
                    </div>

                    <hr/>

                    <div className="mind-patterns-content mb-3">
                      <div className="d-flex justify-content-center cursor-pointer" onClick={() => hanndleNextSection({ section: 'detail-member-vaks', member_id: member.id })}>
                        {mind_patterns}
                      </div>
                    </div>
                  </div>

                </TabPane>
                <TabPane tabId="info">
                  <Row className="px-md-4">
                    <Col md={{ size: 12 }}>
                      <div className="py-3 px-3 px-md-0">
                        <p className="mb-0 text-truncate"><a href={'mailto:' + member.email} target="_blank" className="text-reset"><i className="mr-3 text-primary"><FontAwesomeIcon icon={faEnvelope} /></i>{member.email}</a></p>
                      </div>
                      <hr className="my-0" />
                    </Col>
                    <Col md={{ size: 12 }}>
                      <div className="py-3 px-3 px-md-0">
                        <p className="mb-0 text-truncate d-md-inline-block mr-md-5"><i className="mr-3 text-primary"><FontAwesomeIcon icon={faUser} /></i><span className="font-weight-light mr-1">First Name</span>{member.first_name}</p>
                        <p className="mb-0 text-truncate d-md-inline-block"><i className="mr-3 text-primary invisible d-md-none"><FontAwesomeIcon icon={faUser} /></i><span className="font-weight-light mr-1">Last Name</span>{member.last_name}</p>
                      </div>
                      <hr className="my-0" />
                    </Col>
                    <Col md={{ size: 12 }}>
                      <div className="py-3 px-3 px-md-0">
                        <p className="mb-0 text-truncate"><i className="mr-3 text-primary"><FontAwesomeIcon icon={faGlobe} /></i><span className="font-weight-light mr-1">Country</span>{member.country ? member.country.name : ''}</p>
                      </div>
                    </Col>
                  </Row>

                </TabPane>

              </TabContent>
            </CSSTransition>
          </SwitchTransition>
          {isMember &&
            <Modal isOpen={show_modal} toggle={this.handleModal} className="pop-up-confirm">
              <ModalBody className="text-center p-4 p-md-5">
                <p>Are you sure you want to REMOVE {(member.first_name) ? `${member.first_name} ${member.last_name}` : member.username} from {team.name}?</p>
                <div className="d-flex justify-content-center">
                  <Button color="btn text-uppercase rounded-pill btn-outline-primary mr-2 px-4" onClick={this.handleModal}><i className="mr-2 h5 text-reset align-middle"><FontAwesomeIcon icon={faTimesCircle} /></i><span className="h6 font-weight-normal text-reset align-middle">No</span></Button>{' '}
                  <Button color="btn text-uppercase rounded-pill btn-outline-primary ml-2 px-4" onClick={(e) => this.handleModal(e, true)}><i className="mr-2 h5 text-reset align-middle"><FontAwesomeIcon icon={faCheckCircle} /></i><span className="h6 font-weight-normal text-reset align-middle">Yes</span></Button>
                </div>
              </ModalBody>

            </Modal>}
        </div>

      </>
    )
  }
}

const mapStateToProps = (state, props) => {
  const team_id = props.params ? props.params.id : DEFAULT_SESSION_ID;

  return {
    team_id
  }
};

const mapDispatchToProps = {
  removeTeamMember: teamActions.removeTeamMember,
  setLeaderTeamMember: teamActions.setLeaderTeamMember,
  loadTeamsReport: teamsReportListActions.loadTeamsReport,
  loadTeamMembers: teamsMembersListActions.loadTeamMembers,
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberDetail);
