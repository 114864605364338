import React, { useState } from "react";
import { useSelector } from "react-redux";

import { career_canvas_questionnaire } from "../../../../core/constants";
import { DynamicSection } from "./DynamicSection";
import { getLeadershipCanvasQuestionByCode, getStringFromAnswers } from "../../../../core/leadership-canvas";

//reports
import { GenericModalReport } from "../index"

//icons
import iconWorkpre from "../../../../assets/img/icon-workpre.svg"
import iconWorkenvir from "../../../../assets/img/icon-workenvir.svg"
import iconCareerdriver from "../../../../assets/img/icon-career-driver.svg"

//questionnaireView
import { QuestionnairePage } from "../../../pages/home/questionnaires/QuestionnairePage";

export const WorkPreferencesSection = ({ goBack, sectionCode }) => {
    const { user, leadershipCanvas } = useSelector(state => state)
    const [ isOpenModal, setIsOpenModal ] = useState(true)
    const item_WE = useSelector(state => getLeadershipCanvasQuestionByCode(state, career_canvas_questionnaire.WORK_ENVIRONMENT.questions.work_environment.code))
    const [ showQuestionnaireReview, setShowQuestionnaireReview ] = useState(false)
    const toggle = () => {
        setIsOpenModal(!isOpenModal)
    }
    const open = () => {
        setIsOpenModal(true)
    }
    const intro = {
        code: sectionCode,
        text: `This section will help you make transparent which cadence of meetings/planning/decisions/ problem solving is closer to optimal, and what causes stress for you. 
        <br/>
        Clarifying this is especially important in partnerships where we have different cadences that need to work together, somewhere between fast and slow.`,
        image: iconWorkpre
    }
    const items = [
        {
            type: 'modal',
            title: 'Career Drivers',
            show_btn_view: leadershipCanvas.leadership_discovery_done,
            show_btn_unlock: !leadershipCanvas.leadership_discovery_done,
            unlock_url: `/front/questionnaire/${leadershipCanvas?.questionnaire_id}`,
            // bottom_text: getStringFromAnswers(item_CD?.answers),
            image: iconCareerdriver,
            // modal: <GenericModalReport open={true} toggle={toggle} sectionCode={intro.code} title={item_CD?.title} items={item_CD?.answers} text={ <span> Click <a onClick={ (e)=> {e.preventDefault(); setShowQuestionnaireReview(true)}} href={`/front/questionnaire-review/${leadershipCanvas?.questionnaire_id}`}> here </a> to update your answers </span>} />,
            modalIsOpen: isOpenModal,
            openModal: open,
            coming_soon: true
        },
        {
            type: 'modal',
            title: 'Work Environment',
            show_btn_view: leadershipCanvas.leadership_discovery_done,
            show_btn_unlock: !leadershipCanvas.leadership_discovery_done,
            unlock_url: `/front/questionnaire/${leadershipCanvas?.questionnaire_id}`,
            bottom_text: getStringFromAnswers(item_WE?.answers),
            image: iconWorkenvir,
            modal: <GenericModalReport open={true} toggle={toggle} sectionCode={intro.code} title={item_WE?.title} items={item_WE?.answers} text={ <span> Click <a onClick={ (e)=> {e.preventDefault(); setShowQuestionnaireReview(true)}} href={`/front/questionnaire-review/${leadershipCanvas?.questionnaire_id}`}> here </a> to update your answers </span>} />,
            modalIsOpen: isOpenModal,
            openModal: open,
            questionnaireView: <QuestionnairePage id={leadershipCanvas?.questionnaire_id} animation={false} />,
            questionnaireId: leadershipCanvas?.questionnaire_id,
            showQuestionnaireReview,
        },
    ]

    return (
        <DynamicSection intro={intro} items={items} goBack={goBack} />
    )
}