import { Record } from 'immutable';

export const events = {
    id: null,
    isPending: false,
    error: null,
    data: {},
    events: []
};

