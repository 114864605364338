
export const eventsActions = {

  FETCH_EVENTS_FAILED: 'FETCH_EVENTS_FAILED',
  FETCH_EVENTS_SUCCESS: 'FETCH_EVENTS_SUCCESS',
  FETCH_EVENTS_PENDING: 'FETCH_EVENTS_PENDING',
  LOAD_EVENTS: 'LOAD_EVENTS',
  MORE_EVENTS: 'MORE_EVENTS',


  fetchEventsFailed: error => ({
    type: eventsActions.FETCH_EVENTS_FAILED,
    payload: error
  }),

  fetchEventsSuccess: (id, data) => ({
    type: eventsActions.FETCH_EVENTS_SUCCESS,
    payload: {
      data,
      id
    }
  }),

  fetchEventsPending: () => ({
    type: eventsActions.FETCH_EVENTS_PENDING,
  }),

  loadEvents: (currentPage) => ({
    type: eventsActions.LOAD_EVENTS,
    payload: {
      currentPage
    }
  }),

  moreEvents: () => ({
    type: eventsActions.MORE_EVENTS,
  })
};


export const eventsRequestActions = {
  failed: eventsActions.fetchEventsFailed,
  success: eventsActions.fetchEventsSuccess,
  pending: eventsActions.fetchEventsPending
};
