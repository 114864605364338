import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';
import { eventsActions } from '../../../core/events/actions';
import ReactGA from 'react-ga';
import { trackingActions } from '../../../core/tracking';
import session from '../../../core/session';

export const EventsHome = () => {
    const dispatch = useDispatch();
    const { events } = useSelector(state => state);


    useEffect(() => {
        if(session.getAuthenticated()) {
            dispatch(eventsActions.loadEvents(1));
        }
    }, []);

    const eventGA = (title) => {
        ReactGA.event({
            category: 'Events',
            action: `Click on envent: ${title}`
        })
    }

    const ourTracking = (title) => {
        dispatch(trackingActions.saveTrackingEvent({category: 'Events',action: `Click on event: ${title}`}))
    }

    return (
        <>
            <div className="encabezado">
                <div className="mb-0 pt-2 pb-1 d-flex align-items-end">
                    <h6 className="mb-0 title-underline pr-5">Events</h6>
                </div>
                <span className="border border-info d-none d-md-block"></span>
            </div>

            <Row className="mt-4 events-list">
                {
                    events.data.events && events.data.events.map((e, i) => {
                        return <Col xs='12' className="mb-3" key={e.id}>
                            <a href={`${e.registration_url}`} onClick={() => {eventGA(e.title); ourTracking(e.title)}} target="_blank" className="pl-3 d-block text-reset text-decoration-none" >
                                <h5 className="px-3 pb-2">{e.title}</h5>
                                <div className="bg-celeste px-3 py-1 rounded mb-3"><p className="mb-0">{e.date}</p></div>
                                <div className="d-flex">
                                    <div className="thumb-event rounded">
                                        <img src={e.image} alt={e.title} className="rounded" />
                                    </div>

                                    <div className="ml-3">
                                        <div dangerouslySetInnerHTML={{ __html: e.text }}></div>
                                        <span href='#' target="_blank" className="btn btn-outline-primary text-uppercase rounded-pill d-inline-flex px-md-4 mx-auto">REGISTER</span>
                                    </div>
                                </div>
                            </a>
                            <span className="border border-muted d-none d-md-block mt-3"></span>
                        </Col>

                    })
                }
            </Row>
            <div className="text-center"><Link to="/events" className="btn btn-outline-primary text-uppercase rounded-pill d-inline-flex px-md-4 mx-auto mt-3">VIEW ALL EVENTS</Link></div>
        </>
    )
}
