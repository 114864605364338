import React, { Component, useState } from 'react';
import { NavLink as Link } from 'react-router-dom';
import {
    Navbar,
    NavbarBrand,
    NavbarToggler,
    Collapse,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu, DropdownItem
} from 'reactstrap';

import trueLogoText from '../../../assets/img/true.logo.text.webp'

import UserMenu from './UserMenu'

import iconTT from '../../../assets/img/icon-menu-tt.svg';
import iconMP from '../../../assets/img/icon-menu-mp.svg';
import iconTeam from '../../../assets/img/icon-menu-team.svg';
import iconBleeker from '../../../assets/img/icon-menu-bleeker.svg';
import iconContact from '../../../assets/img/icon-menu-contact.svg';
import iconPrivacy from '../../../assets/img/icon-menu-privacy.svg';


class Header extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isOpen: false,
        }

        this.handleNav = this.handleNav.bind(this)
    }

    toggleMenu = () => {
        const { isOpen } = this.state;
        this.setState({ isOpen: !isOpen });
    }

    handleNav = (section) => {
        if (section === 'teams') {
            this.props.history.push('/teams');
        }
    }

    globalToggleMenu = () => {
        const { isOpen } = this.state;
        if (isOpen) {
            this.setState({ isOpen: false });
        }
    }

    render() {
        const { user, history, currentUser, showLeadershipCanvas, showThinkingTalents, showMindPatterns, showTeams } = this.props;
        const { isOpen } = this.state;

        return (
            <header className="fixed-top bg-white">
                <Navbar expand="md" className="px-4 px-md-3">
                    <div className="container d-flex justify-content-between">
                        {
                            user.id &&
                            <NavbarToggler onClick={this.toggleMenu} className="p-0" />
                        }

                        <div className={!user.id ? 'mx-auto ml-md-0' : ''} onClick={() => currentUser()}>
                            <Link className="mr-0 navbar-brand" to={user.id ? "/home" : "/welcome"}>
                                <img src={trueLogoText} alt="True Advance" className="ml-2 d-none d-md-inline" />
                            </Link>
                        </div>

                        <Collapse isOpen={isOpen} className="justify-content-between nav-menu mx-md-5" navbar>
                            {showLeadershipCanvas && <Link className="d-none d-md-block nav-link btn btn-outline-primary text-uppercase rounded-pill px-md-3" to="/career-canvas"><span>Career Canvas</span></Link>}
                            <NavbarToggler onClick={() => this.toggleMenu} className="icon-toggler-open p-0 border-0" />
                            <Nav navbar>
                                {showLeadershipCanvas &&
                                    <NavItem className='d-md-none'>
                                        <Link className="nav-link" to="/career-canvas"><span>Career Canvas</span></Link>
                                    </NavItem>
                                }
                                {showThinkingTalents &&
                                    <NavItem>
                                        <Link className="nav-link" to="/thinking-talents"><i className="d-md-none"><img src={iconTT} alt="" /></i><span>Thinking Talents</span></Link>
                                    </NavItem>
                                }
                                {showMindPatterns &&
                                    <NavItem>
                                        <Link className="nav-link" to="/mind-patterns"><i className="d-md-none"><img src={iconMP} alt="" /></i><span>Mind Patterns</span></Link>
                                    </NavItem>
                                }
                                {user.is_manager === 1 && showTeams &&
                                    <NavItem>
                                        <Link className="nav-link" to="/teams"><i className="d-md-none"><img src={iconTeam} alt="" /></i><span>Teams</span></Link>
                                    </NavItem>
                                }
                                {!user.isPending && user.account && user.account.events_count > 0 &&
                                    <NavItem>
                                        <Link className="nav-link" to="/events"><i className="d-md-none"><img src={iconTeam} alt="" /></i><span>Events</span></Link>
                                    </NavItem>
                                }
                                {!user.isPending && user.account && user.account.jobs_count > 0 &&
                                    <NavItem>
                                        <Link className="nav-link" to="/jobs"><i className="d-md-none"><img src="" alt="" /></i><span>Jobs</span></Link>
                                    </NavItem>
                                }
                                <NavItem className="d-md-none">
                                    <a className="nav-link" href="https://trueadvance.com/" target="_blank"><i className="d-md-none"><img src={iconBleeker} alt="" /></i><span>About True Advance</span></a>
                                </NavItem>
                                <NavItem className="d-md-none">
                                    <Link className="nav-link" to="/contact-us"><i className="d-md-none"><img src={iconContact} alt=""
                                    /></i><span>Contact Us</span></Link>
                                </NavItem>
                                <NavItem className="d-md-none">
                                    <a className="nav-link" href="https://www.trueadvance.com/privacy-policy"><i className="d-md-none"><img src={iconPrivacy} alt="" /></i><span>Privacy Policy</span></a>
                                </NavItem>
                                <UncontrolledDropdown nav inNavbar className="d-none d-md-block">
                                    <DropdownToggle nav caret>
                                        TRUE ADVANCE
                                    </DropdownToggle>
                                    <DropdownMenu right className="p-0">
                                        <div className="misc"></div>
                                        <DropdownItem tag="a" href="/front/contact-us">
                                            Contact Us
                                        </DropdownItem>
                                        <DropdownItem tag="a" href="https://trueadvance.com/" target="_blank">
                                            About True Advance
                                        </DropdownItem>
                                        <DropdownItem tag="a" href="https://www.trueadvance.com/privacy-policy" target="_blank">
                                            Privacy Policy
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </Nav>
                        </Collapse>

                        {
                            user.id &&
                            <UserMenu history={history} user={user} />
                        }

                    </div>
                </Navbar>
            </header>
        );
    }
}

export default Header;

