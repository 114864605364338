import React, { useEffect, useState } from 'react';
import { Container, Col, Row, Card, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { NavLink as Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { PostBody } from '../../components/blogs/post-content/PostBody';
import { PostAside } from '../../components/blogs/post-content/PostAside';
import LoadingIndicator from '../../components/loading-indicator/loading-indicator';
import { blogActions } from '../../../core/blog';

const animationClass = {
    appearActive: 'animated slideInRight',
    enter: 'animated',
    enterActive: 'animated slideInRight',
    exit: 'animated',
    exitActive: 'animated slideOutLeft',
}

export const PostContent = (props) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { id } = useParams()
    const { user } = useSelector(state => state)
    const { blog } = useSelector(state => state)

    useEffect(() => {
        dispatch(blogActions.fetchPost(id))
    }, [])

    return (
        <>
            {!blog.isPending && blog.post_selected && <div className='view overflow-hidden'>
                <TransitionGroup className="content">
                    <CSSTransition classNames={animationClass} timeout={{ enter: 1000, exit: 1000 }} appear >
                        <div className="w-100">
                            <Container>
                                <div className="bg-white content-view my-md-4 shadow-md mx-n3 mx-md-auto rounded-lg d-flex flex-column">
                                    <div className="bg-white rounded-lg-top px-md-5 px-4 py-2 border-bottom d-flex align-items-center">
                                        <a style={{ cursor: 'pointer' }} onClick={() => {history.goBack()}} className="ml-2 ml-md-0"><FontAwesomeIcon icon={faArrowLeft} /></a>
                                        <Breadcrumb className="d-none d-md-block" listClassName="mb-0 bg-transparent" >
                                            <BreadcrumbItem><Link to="/home">Dashboard</Link></BreadcrumbItem>
                                            <BreadcrumbItem><Link to="/content-library">Content Library</Link></BreadcrumbItem>
                                            {blog.post_selected.categories && blog.post_selected.categories.length > 0 && <BreadcrumbItem><a href={`/front/content-library/${blog.post_selected.categories[0].slug}`}>{blog.post_selected.categories[0].name}</a></BreadcrumbItem>}
                                            <BreadcrumbItem><a>{blog.post_selected.title}</a></BreadcrumbItem>
                                        </Breadcrumb>
                                    </div>



                                    <Row className="mx-0 align-items-stretch flex-fill flex-md-row-reverse">
                                        <Col md={{ size: 3 }} className="px-0 border-left">
                                            <PostAside showShare={blog.post_selected.shareable} title={blog.post_selected.title} document_presentation_text={blog.post_selected.attached_documents_title} documents={blog.post_selected.documents} video_presentation_text={blog.post_selected.videos_title} videos={blog.post_selected.videos} />
                                        </Col>
                                        <Col md={{ size: 9 }} className="px-4 py-4 pt-md-3">
                                            <PostBody categories={blog.post_selected.categories} title={blog.post_selected.title} subtitle={blog.post_selected.subtitle} text={blog.post_selected.text} image={blog.post_selected.image} featured_video={{ title: blog.post_selected.featured_video_title, text: blog.post_selected.featured_video_text, code: blog.post_selected.featured_video_code }} videos={blog.post_selected.videos} />
                                        </Col>
                                    </Row>

                                </div>
                            </Container>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </div>
            }
            {(blog && blog.isPending) || (user && user.isPending) && <LoadingIndicator />}
        </>
    )
}