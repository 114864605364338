import React from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, FormGroup, CustomInput, Form, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import closemodal from "../../../../assets/img/close-modal.svg";


export const GenericModalReport = ({ open, toggle, title, items, text, sectionCode }) => {
    return (
        <Modal isOpen={open} toggle={toggle} contentClassName={`generic-report-modal section-${sectionCode} rounded-0`}>
            <ModalHeader className='pb-0 pt-3' cssModule={{ 'close': 'close p-2' }} toggle={toggle}></ModalHeader>
            <ModalBody className='px-3 pb-3'>
                <div className='p-3'>
                    <div>
                        <h5 className="text-uppercase text-muted font-weight-semibold">{title}</h5>
                        <div>
                            {items?.map((item, index) => {
                                return (
                                    <div className='mb-3 d-flex align-items-center'>
                                        {item.icon && ' - ' && <img src={item.icon} width="20" className='d-block-inline mr-2'/> }
                                        <p className='small mb-0'>{item.name || item.leadership_output || item.title}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <span className="border border-dashed d-block my-2"></span>
                    {text && <p className='mb-0 small'>{text}</p>}
                </div>
            </ModalBody>
        </Modal>
    )
}