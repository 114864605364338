import React, { useState } from 'react';
import { Collapse, Button, List, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import icon_copylink from '../../../../assets/img/icon_copylink.svg';
import icon_video_2 from '../../../../assets/img/icon-video-2.svg';

import { ModalVideo } from '../../videos/ModalVideo';
import { pdfsActions } from '../../../../core/pdfs/actions';

import {
    LinkedinShareButton,
    WhatsappShareButton,
    FacebookShareButton,
    TwitterShareButton,
    EmailShareButton,
} from "react-share";

import {
    LinkedinIcon,
    WhatsappIcon,
    FacebookIcon,
    TwitterIcon,
    EmailIcon,
} from "react-share";

export const PostAside = (props) => {
    const { showShare, title, document_presentation_text, documents, video_presentation_text, videos } = props
    const dispatch = useDispatch()

    const [share, setShare] = useState(false)
    const [collapsed, setCollapsed] = useState(true);
    const [modalVideo, setModalVideo] = useState(false);
    const [indexVideoClicked, setIndexVideoClicked] = useState(null)

    const toggleShare = () => setShare(!share)
    const toggleDownload = () => setCollapsed(!collapsed);
    const toggleModalVideo = () => setModalVideo(!modalVideo);

    const __html_pToSpan = (text) => {
        let new__html = text.replace("<p>", "<span>");
        return new__html.replace("</p>", "</span>");
    }

    const copyLink = () => {
        const el = document.createElement('input');
        el.value = window.location.href;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    const handlerClickDocument = (id, name, type, link ) => {
        type === 'attached' ?
            dispatch(pdfsActions.downloadPostDocument(id, name))
            :
            window.open(link, "_blank")
    }

    return (
        <>
            {showShare && <div className='px-3'>
                <Button size="lg" color="transparent" block className="my-md-2 d-flex align-items-center" onClick={() => toggleShare()} >
                    <span class="icon-share-alt text-primary mr-2 icon-16"></span>
                    <span>Share</span>
                    <FontAwesomeIcon className="ml-1 float-right text-naranja ml-auto" size="lg" icon={share ? faChevronUp : faChevronDown} />
                </Button>
                <Collapse isOpen={share} className='px-3 text-petrol text-left mb-2'>
                    <List className="pl-md-3 pl-4 social-share" type="unstyled">
                        <li className="py-1"><LinkedinShareButton title={title} url={window.location.href}><LinkedinIcon round={true} size={24} className="mr-2" /><small>Linked In</small></LinkedinShareButton ></li>
                        <li className="py-1"><WhatsappShareButton title={title} url={window.location.href}><WhatsappIcon round={true} size={24} className="mr-2" /><small>WhatsApp</small></WhatsappShareButton ></li>
                        <li className="py-1"><FacebookShareButton title={title} url={window.location.href}><FacebookIcon round={true} size={24} className="mr-2" /><small>Facebook</small></FacebookShareButton ></li>
                        <li className="py-1"><TwitterShareButton title={title} url={window.location.href}><TwitterIcon round={true} size={24} className="mr-2" /><small>Twitter</small></TwitterShareButton ></li>
                        <li className="py-1"><EmailShareButton title={title} url={window.location.href}><EmailIcon round={true} size={24} className="mr-2" /><small>Email</small></EmailShareButton ></li>
                        <li className="py-1" onClick={() => copyLink()}><img style={{cursor: 'pointer'}} src={icon_copylink} width="20" height="auto" className="mr-2" /><small style={{cursor: 'pointer'}}>Copy Link</small></li>
                    </List>
                </Collapse>
            </div>
            }
            {showShare && <hr className="my-0" />}

            {(documents || videos) &&
                <>
                    <div className='px-3 px-md-0'>
                        <Button size="lg" color="transparent" onClick={toggleDownload} block className="d-md-none d-flex align-items-center">
                            <span class="icon-download text-primary mr-2 icon-16"></span><span>Download</span>
                            <FontAwesomeIcon className="ml-1 float-right text-naranja ml-auto" size="lg" icon={!collapsed ? faChevronUp : faChevronDown} />
                        </Button>

                        <Collapse isOpen={!collapsed} className="d-md-flex flex-md-column">

                            {documents && <div className='px-md-3 border-md-bottom'>

                                {documents.length > 0 && document_presentation_text &&
                                    <div className="bg-celeste px-3 py-2 mt-md-3 mb-2 rounded text-petrol">
                                        <p className="mb-0">{document_presentation_text}</p>
                                    </div>
                                }

                                {documents.length > 0 && documents.map((document, index) => {
                                    return (
                                        <>
                                            <Button onClick={() => handlerClickDocument(document.id, document.text, document.attached_document_type, document.document_link)} size="lg" color="transparent" outline block className="text-left my-md-2 d-flex align-items-center" >
                                                <span class="icon-download text-primary mr-2 icon-16"></span>
                                                <span key={document.id}>{document.text}</span>
                                            </Button>
                                            {index < documents.length - 1 && <hr className="my-0" />}
                                        </>
                                    )
                                })
                                }
                            </div>}

                            {videos && <div className='px-md-3 border-md-bottom'>

                                {videos.length > 0 && video_presentation_text &&
                                    <div className="bg-celeste px-3 py-2 mt-md-3 mb-2 rounded text-petrol">
                                        <p className="mb-0">{video_presentation_text}</p>
                                    </div>
                                }

                                {videos.length > 0 && videos.map((video, index) => {
                                    return (
                                        <>
                                            <Button onClick={() => { setIndexVideoClicked(index); toggleModalVideo() }} size="lg" color="transparent" outline block className="text-left my-md-2 d-flex align-items-center" >
                                                {/* <span class="icon-video text-primary mr-2 icon-16"></span>
                                                <div key={index} dangerouslySetInnerHTML={{ __html: __html_pToSpan(video.text) }}></div> */}
                                                <img src={icon_video_2} width="20" height="auto" className="mr-2" />
                                                <span key={index}>{video.text}</span>
                                            </Button>
                                            {index < videos.length - 1 && <hr className="my-0" />}
                                        </>
                                    )
                                })
                                }
                            </div>}

                        </Collapse>
                    </div><hr className="my-0 d-md-none" />
                    <ModalVideo index={indexVideoClicked} videos={videos} toggle={toggleModalVideo} open={modalVideo}></ModalVideo>
                </>
            }

        </>
    )
}