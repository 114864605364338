import { Map } from 'immutable';
import { companyCardsFiltersActions } from './actions';
import { companyCardsFilters } from './company-cards-filters';

export const companyCardsFiltersReducer = (state = companyCardsFilters, { payload, type }) => {

    switch (type) {
        case companyCardsFiltersActions.FETCH_COMPANY_CARDS_FILTERS_FAILED:
            return state.merge({
                isPending: false,
                error: payload.code
            });

        case companyCardsFiltersActions.FETCH_COMPANY_CARDS_FILTERS_SUCCESS:
            return state.withMutations( companyCardsFilters => {
                companyCardsFilters.merge({
                    isPending: false,
                    [payload.companyCardsFiltersId]: payload.data
                });
            });

        case companyCardsFiltersActions.FETCH_COMPANY_CARDS_FILTERS_PENDING:
            return state.set('isPending', true);

        case companyCardsFiltersActions.UPDATE_LAST_FILTERS:
            return state.withMutations( companyCardsFilters => {
                companyCardsFilters.merge({
                    last_filters: payload.filters
                });
            });

        default:
            return state;
    }
}
