import React from "react";
import { useSelector } from "react-redux";

import { career_canvas_questionnaire } from "../../../../core/constants";
import { Intro } from "../../leadershipCanvasReport";
import { getLeadershipCanvasQuestionByCode } from "../../../../core/leadership-canvas-report";

//icons
import iconWorkpre from "../../../../assets/img/icon-workpre.svg"
import iconWorkenvir from "../../../../assets/img/icon-work-report.svg"
import iconCareerdriver from "../../../../assets/img/icon-career-report.svg"

export const WorkPreferencesSection = ({ sectionCode }) => {
    const { leadership_canvas_report } = useSelector(state => state)
    const item_WE = useSelector(state => getLeadershipCanvasQuestionByCode(state, career_canvas_questionnaire.WORK_ENVIRONMENT.questions.work_environment.code))
    return (
        <>
            <Intro title={'Work Preferences'} className={`intro-1 ` + sectionCode} image={iconWorkpre} sectionCode={sectionCode} text={`This section shows what environment ${leadership_canvas_report?.user?.first_name} prefers to work in and what values drive ${leadership_canvas_report?.user?.first_name}’s career decisions.`}
            />
            {/* <Intro title={'Career Drivers'} className={`intro-2 ` + sectionCode} image={iconCareerdriver} sectionCode={sectionCode} text={``} /> */}
            <Intro title={'Work Environment'} className={`intro-2 ` + sectionCode} image={iconWorkenvir} sectionCode={sectionCode} items={item_WE?.answers} />
        </>
    )
}