import { call, put, fork, select, takeLatest } from 'redux-saga/effects';

import { searchCompanyCards } from '../api';

import { companyCardsListActions } from './actions';
import { companyCardsFiltersActions } from '../company-cards-filters';

import { getCurrentCompanyCardsList, getCompanyCardsListById } from './selectors';

export function* searchCompanyCardsList({payload}) {
    const { companyCardsListId, filters, page } = payload;

    yield call(searchCompanyCards, companyCardsListId, {filters, page});
    yield put(companyCardsFiltersActions.updateLastFilters(filters))
}

// //=====================================
// //  WATCHERS
// //-------------------------------------

export function* watchSearchCompanyCardsList() {
    yield takeLatest(companyCardsListActions.SEARCH_COMPANY_CARDS, searchCompanyCardsList)
}

//=====================================
//  ROOT
//-------------------------------------

export const companyCardsListSagas = [
    fork(watchSearchCompanyCardsList)
];
